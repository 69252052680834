<mat-card>
  <mat-card-title>Login</mat-card-title>
  <mat-card-content>
    <form [formGroup]="form" (ngSubmit)="submitLogin(form.value)">
      <p>
        <mat-form-field>
          <input matInput type="text" placeholder="Username" formControlName="userName">
        </mat-form-field>
      </p>
      <p>
        <mat-form-field>
          <input matInput type="password" placeholder="Password" formControlName="password">
        </mat-form-field>
      </p>
      <div class="button">
        <button type="submit" mat-raised-button color="primary">Login</button>
      </div>
    </form>
  </mat-card-content>
</mat-card>

<div>
  <img src="assets/imgs/big_logo.png">
</div>