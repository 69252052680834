import { Injectable } from '@angular/core';
import { Vector as VectorLayer } from 'ol/layer.js';
import { Vector as VectorSource } from 'ol/source.js';
import { Style, Stroke } from 'ol/style.js';
import { MapOperationService } from './map-operation.service';
import {Feature} from 'ol';
import { LayerBase } from './layer-base';
import { MapBubbleService } from './map-bubble-service';
import { WKTGeometryConverter } from './wkt-converter.service';
import { MeasurementService } from './measurement.service';
import { Boundary } from '../../model';
import { DrawingService } from './drawing.service';
import { ColorService } from '../color.service';
import { TranslateService } from '@ngx-translate/core';
import { Draw } from 'ol/interaction';


const projectionCode = 'EPSG:3857';
const LINE_LAYER = 'free-line';
const LINE_LAYER_MAX_RESOLUTION = Infinity;


@Injectable({
  providedIn: 'root'
})
export class FreeLineLayerService extends LayerBase {

  constructor(
    mapOperationsService: MapOperationService,
    mapBubbleService: MapBubbleService,
    private wktGeometryConverter: WKTGeometryConverter,
    private measurementService: MeasurementService,
    private drawingService: DrawingService,
    private colorService: ColorService,
    private translateService: TranslateService
  ) {
    super(mapOperationsService, mapBubbleService);
    this.layerName = LINE_LAYER;
    this.maxResolution = LINE_LAYER_MAX_RESOLUTION;
  }

  private source: VectorSource;
  private drawInteraction = null;

  protected overlayConfig = {
    align: 'center' as 'center',
    class: 'pasture-boundary-popup'
  };

  addFreeLineLayer(visible = true) {
    this.source = new VectorSource({
    });
    this.layer = new VectorLayer({
      source: this.source,
      style: (feature, resolution) => {
        return this.getFeatureStyle(feature);
      },
    });
    this.addLayer();
    this.setLayerVisibility(visible);
  }

  updateLayer = (lines: Boundary[]) => {
    this.source.clear();
    this.wktGeometryConverter.filterByGeometry('LINESTRING', lines).forEach(line => this.addFeature(line));
  }


  show = (show: boolean) => {
    if (show) {
      this.setLayerVisibility(true);
    } else {
      this.deSelectAll();
      this.setLayerVisibility(false);
    }
  }

  addFeature(line: Boundary) {
    const geometry = this.wktGeometryConverter.toGeometry(line.geometry, 'LineString');
    if (geometry) {
      let feature = new Feature();
      feature.setProperties({
        featureData: line
      });
      feature.setGeometry(geometry);
      this.source.addFeature(feature);
    }
  }

  getFeatureStyle(feature, isSelected = false) {
    let fData = (feature.getProperties()).featureData;
    const stroke = (fData && fData.color) ? this.colorService.hex2RGB(fData.color) :
      this.colorService.hex2RGB(this.colorService.DeafultBoundaryColor);

    return new Style({
      stroke: new Stroke({
        color: stroke,
        width: 5
      }),
    });
  }

  startDrawLine(isPortal = false) {
    if (isPortal) {
      let drawInteraction = new Draw({
        type: 'LineString',
        source: this.source,
        style: {
          'stroke-color': this.colorService.hex2RGB(this.colorService.DeafultBoundaryColor),
          'stroke-width': 1.5,
          'fill-color': this.colorService.hex2RGBA(this.colorService.DeafultBoundaryColor, .4),
          'circle-radius': 6,
          'circle-fill-color': this.colorService.hex2RGB(this.colorService.DeafultBoundaryColor),
        },
      });
  
      drawInteraction.on('drawend', (e) => {
        const newWKTPolygon = this.wktGeometryConverter.featureToWKT(e.feature);
        this.mapOperationsService.fireEvent('add-feature', {
          layerName: this.layerName,
          featureData: {newPolygons: [newWKTPolygon]}
        }, null);
        this.mapOperationsService.map.removeInteraction(drawInteraction);
      })
      this.mapOperationsService.map.addInteraction(drawInteraction);
      
    } else {
      this.startDraw();
      this.drawingService.startDrawMultiLine({
          onDrawFinish: (coords) => {
            if (coords) {
              this.mapOperationsService.fireEvent('add-feature', {
                layerName: this.layerName,
                featureData: {
                  newPolygons: [this.wktGeometryConverter.lineStringToWKT(coords)],
                  modifiedPolygons: []
                }
              }, null);
            }
            this.finishDraw();
          },
          lineColor: this.colorService.hex2RGB(this.colorService.DeafultBoundaryColor),
          pointColor: this.colorService.hex2RGB(this.colorService.DeafultBoundaryColor),
          fillColor: this.colorService.hex2RGBA(this.colorService.DeafultBoundaryColor, .4),
          finishButtonTitle: this.translateService.instant('MAPPING.FINISH_LINE'),
          showFinish: true
        }
      );
    }
  }

  public finishDraw() {
    this.drawingService.finishDraw();
    this.mapOperationsService.freezeMap(false);
  }


  private startDraw() {
    this.mapOperationsService.freezeMap(true);
    setTimeout(() => this.mapOperationsService.freezeMap(false), 30000);

  }

  baseStyle = (feature: Feature) => {
    return this.getFeatureStyle(feature, false);
  }
  selectedStyle = (feature: Feature) => {
    return this.getFeatureStyle(feature, true);
  }


  overlayHtml = (feature: Feature) => {
    if (feature) {
      const data: Boundary = (feature.getProperties() as any).featureData;
      if (data) {
        return `<div>
            <div class="highlight upper">${data.title}</div>
            <div>(${this.measurementService.getLength(feature)} mi)</div>
            <div>${data.description}</div>
            <div class="toolbar-row">
            <a class="edit-feature edit">
            ${this.settingsBtn}
            </a>
            </div>
            `;
      }
    }
    return null;
  }


}
