import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Inject, Injectable } from '@angular/core';
import { CommonState } from '../store/reducer'
import { CurrentState } from "../store/current-state";
import { ENVIRONMENT_TOKEN, GOOGLE_ANALYTICS_TOKEN } from "../../environment/token-variables";
import { TaskEntity, TaskRequest, TaskReportRequest, GeneralReportRequest } from "../model";
import { CommonConstants } from "../app.constants";

@Injectable()
export class GeneralReportService {

  readonly path = '/private/tasks/';

  constructor(private currentState: CurrentState<CommonState>,
              @Inject(GOOGLE_ANALYTICS_TOKEN) private readonly ga,
              @Inject(ENVIRONMENT_TOKEN) private readonly environment,
              protected http: HttpClient,
  ) {

  }

  public createGeneralReport(request: GeneralReportRequest) {
    const path = `/private/reports/general/user/${this.currentState.snapshot.user.id}`;
    const token = this.currentState.snapshot.user.token;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Authorization', token);
    const url = this.environment.backend + path;
    const options = {
      headers: headers
    };
    this.ga.trackServiceCall('createTaskReport', CommonConstants.GA.LABEL.TASKS);
    return this.http.post(url, request, options);
  }

  public createTaskReport(request: GeneralReportRequest) {
    const path = `/private/reports/general/task/user/${this.currentState.snapshot.user.id}`;
    const token = this.currentState.snapshot.user.token;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Authorization', token);
    const url = this.environment.backend + path;
    const options = {
      headers: headers
    };
    this.ga.trackServiceCall('createTaskReport', CommonConstants.GA.LABEL.TASKS);
    return this.http.post(url, request, options);
  }
}
