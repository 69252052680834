import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from '@angular/core';
import { ENVIRONMENT_TOKEN, GOOGLE_ANALYTICS_TOKEN } from "../../environment/token-variables";
import { CommonState } from "../store/reducer";
import {
  Acl,
  AffiliateMembership,
  ContactUsRequest,
  PasswordRequest, PrfAssociation,
  TermsAndConditions,
  UserLogin,
  UserRequest
} from "../model";
import { CurrentState } from '../store/current-state';
import { CommonConstants } from "../app.constants";
import { Store } from "@ngrx/store";
import { LoadAffiliates } from "../store/user";


/**
 * Service for user related service calls.
 */
@Injectable()
export class UserService {

  public validationMessages = {
    'oldPassword': [
      {type: 'required', message: 'VALIDATION.OLD_PASSWORD_REQUIRED'}
    ],
    'newPassword': [
      {type: 'required', message: 'VALIDATION.NEW_PASSWORD_REQUIRED'}
    ],
    'newPasswordConfirm': [
      {type: 'required', message: 'VALIDATION.CONFIRM_NEW_PASSWORD_REQUIRED'},
      {type: 'notSame', message: 'VALIDATION.PASSWORD_MISMATCH'}
    ]
  };


  constructor(private currentState: CurrentState<CommonState>,
              @Inject(ENVIRONMENT_TOKEN) private readonly environment,
              @Inject(GOOGLE_ANALYTICS_TOKEN) private readonly ga,
              protected http: HttpClient,
              private store: Store<CommonState>,) {

  }

  private getACLMapFromPermissionEntity(permissions) {
    let aclMapTemp = new Map<string, {
      active,
      actionId,
      enabled
    }[]>();
    let aclMap = new Map<string, {
      active,
      actionId,
      enabled
    }[]>();

    permissions.forEach(acl => {
      const functionId = acl.systemFunctionId;
      const actionId = acl.actionId;
      const active = acl.active;
      const permission = {
        active: active,
        actionId: actionId,
        enabled: false
      };
      if (aclMapTemp.has(functionId)) {
        let soFarActions = aclMapTemp.get(functionId);
        if (active) {
          soFarActions.push(permission);
        }

      } else {
        if (active) {
          aclMapTemp.set(functionId, [permission]);
        }
      }
    });

    aclMapTemp.forEach((value, key) => {
      const actions = this.getSelectableActions(value);
      //no actions yet
      if (!actions) {
        aclMap.set(key, value);
      }
      //merge the actions
      else {
        value.push(...actions);
        aclMap.set(key, value);
      }
    });

    return aclMap;
  }

  public enrichUserEntity(userE) {
    console.info('enrichUserEntity. Original user:');
    console.info(userE);
    let userEntity = {...userE};
    let aclMap = this.getACLMapFromPermissionEntity(userEntity.uniqueACLs);
    userEntity.uniqueACLs = aclMap;
    const roles = userEntity.roles;
    userEntity.roles = this.mapACLsOfRoles(roles)
    // console.info('enrichUserEntity. Enriched user:');
    // console.info(userEntity);
    return userEntity;
  }

  public mapACLsOfRoles(roles) {
    return roles.map(r => {
      let role = {...r};
      const aclsOfRole = role.acls;
      const mapOfAclOfRoles = this.getACLMapFromPermissionEntity(aclsOfRole);
      role.acls = mapOfAclOfRoles;
      return role;
    });
  }

  public registerUser(userData) {
    const path = '/public/users';
    let headers = new HttpHeaders();

    headers = headers.append('Content-Type', 'application/json');

    const url = this.environment.backend + path;
    const options = {
      headers: headers
    };
    this.ga.trackServiceCall('registerUser', CommonConstants.GA.LABEL.USER_CRUD);
    return this.http.post(url, userData, options);
  }

  public increaseParcelUsage() {
    const path = '/private/users/';
    const userId = this.currentState.snapshot.user.userDetails.userId;
    const token = this.currentState.snapshot.user.token;
    const url = this.environment.backend + path + userId + '/parcel/increase';

    this.ga.trackServiceCall('increaseParcelUsage', CommonConstants.GA.LABEL.USER_CRUD);

    return this.http.post(url, null, {
      headers: new HttpHeaders({
        'Authorization': token
      })
    });
  }

  public loginUser(userData: UserLogin) {
    const path = '/public/users/login';
    let headers = new HttpHeaders();

    headers = headers.append('Content-Type', 'application/json');

    const url = this.environment.backend + path;
    const options = {
      headers: headers,
      withCredentials: true
    };

    this.ga.trackServiceCall('loginUser', CommonConstants.GA.LABEL.USER_CRUD);
    return this.http.post(url, {userName: userData.userName.toLowerCase(), password: userData.password}, options);
  }

  public logoutUser() {
    const path = '/private/users/';
    const userId = this.currentState.snapshot.user.userDetails.userId;
    const token = this.currentState.snapshot.user.token;
    const url = this.environment.backend + path + userId;
    this.ga.trackServiceCall('logoutUser', CommonConstants.GA.LABEL.USER_CRUD);
    return this.http.delete(url, {
      headers: new HttpHeaders({
        'Authorization': token
      })
    });
  }


  public getUserDetails(userId: number) {
    const path = '/private/users/';
    const token = this.currentState.snapshot.user.token;
    const url = this.environment.backend + path + userId;
    return this.http.get(url, {
      headers: new HttpHeaders({
        'Authorization': token
      }),
      withCredentials: true
    });
  }

  public renewToken(token: string) {
    const path = '/public/users/';
    let headers = new HttpHeaders();

    headers.append('Content-Type', 'application/json');
    headers.set('Authorization', token);

    const url = this.environment.backend + path;

    return this.http.put(url, {
      headers: new HttpHeaders({
        'Authorization': token
      })
    });
  }

  private getSelectableActions(excludeActions): {
    active,
    actionId,
    enabled
  }[] {
    // let actions = this.currentState.snapshot.admin.actions;
    let actions = [];
    if (!actions || actions.length == 0) {
      return null;
    }
    excludeActions.forEach(value => {
      const existingEntryId = value.actionId;
      actions = actions.filter(action => action.id != existingEntryId)
    });
    actions = actions.map(action => {
      const permission = {
        active: false,
        actionId: action.id,
        enabled: false
      };
      return permission;
    });
    return actions;
  }

  /**
   * Updates the user details, like userName, firstName, email...etc
   * @param request
   */
  public updateUser(request: UserRequest) {
    const path = '/private/users/';
    const token = this.currentState.snapshot.user.token;
    const url = this.environment.backend + path + request.userId;
    const options = {
      headers: new HttpHeaders({
        'Authorization': token
      })
    };
    this.ga.trackServiceCall('updateUser', CommonConstants.GA.LABEL.USER_CRUD);
    return this.http.put(url, request, options);
  }

  public updateTermsAndConditions(userId: number, termId: number, accepted: boolean) {
    const token = this.currentState.snapshot.user.token;
    const url = this.environment.backend + `/private/terms/${termId}/user/${userId}`;
    const options = {
      headers: new HttpHeaders({
        'Authorization': token
      })
    };
    this.ga.trackServiceCall('updateTermsAndConditions', CommonConstants.GA.LABEL.USER_CRUD);
    return this.http.put<TermsAndConditions[]>(url, {accepted, termsId: termId, userId}, options);
  }


  /**
   * Sends request to generate password and send it via email. Username or email must be set in the request.
   * @param request
   */
  public forgotUserPassword(request: UserRequest) {
    const path = '/public/users/forgot';
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    const url = this.environment.backend + path;
    const options = {
      headers: headers
    };
    request = {...request, userName: request.userName.toLowerCase()};
    console.info('Calling forgot password service:');
    console.info(url);
    console.info(request);
    console.info(options);
    this.ga.trackServiceCall('forgotUserPassword', CommonConstants.GA.LABEL.USER_CRUD);
    return this.http.post(url, request, options);
  }


  public updateUserPassword(request: PasswordRequest) {
    const path = '/private/users/';
    const token = this.currentState.snapshot.user.token;
    const url = this.environment.backend + path + request.userId + '/password';
    const options = {
      headers: new HttpHeaders({
        'Authorization': token
      })
    };
    this.ga.trackServiceCall('updateUserPassword', CommonConstants.GA.LABEL.USER_CRUD);
    return this.http.put(url, request, options);
  }

  public hasSystemfunctionAccess(systemFunction, userDetails = null) {
    return true;
    /*
    let ud;
    if (userDetails) {
      ud = userDetails;
    }
    else {
      ud = this.currentState.snapshot.user.userDetails;
    }

    console.info('Checking sysfunction. ' + systemFunction);
    console.info(ud);
    if (ud) {
      ud.roles.forEach(role => {
        role.acls.forEach(acl => {

        })
      });
      return true;
    }
    return false;
    */
  }

  public hasPermission(aclsOfUser, systemFunction, permissionAction = 'create') {
    let filtered = aclsOfUser.filter(acl => {
      let hasSystemFunction = acl.sysfunctionName == systemFunction;
      const active = acl.active;
      const hasAccess = acl.actionName.toLowerCase() == permissionAction;
      return hasSystemFunction && active && hasAccess;
    })
    console.log(filtered);
    return filtered.length > 0;
  }

  public checkPasswords(controls: any): any {
    if (controls && controls.value) {
      let confirmPass = controls.value;
      let pass = controls.parent.controls.newPassword.value;
      if ((pass && confirmPass) && (pass != confirmPass)) {
        return {"notSame": true};
      } else {
        return null;
      }
    }
    return null;
  }

  public markMessageRead(messageId, userId) {
    const path = '/private/messages/';
    const token = this.currentState.snapshot.user.token;
    const url = this.environment.backend + path;
    const options = {
      headers: new HttpHeaders({
        'Authorization': token
      })
    };
    const request = {
      "messageId": messageId,
      "userId": userId
    };
    this.ga.trackServiceCall('markMessageRead', CommonConstants.GA.LABEL.SUPPORT);
    return this.http.put(url, request, options);
  }

  public searchUsers(userCredential: string) {
    const path = '/private/users/search/';
    const token = this.currentState.snapshot.user.token;
    const url = this.environment.backend + path + userCredential;
    this.ga.trackServiceCall('searchUsers', CommonConstants.GA.LABEL.SUPPORT);
    return this.http.get(url, {
      headers: new HttpHeaders({
        'Authorization': token
      })
    });
  }

  public sendContactUsEmail(request: ContactUsRequest) {
    const path = '/private/users';
    let headers = new HttpHeaders();

    const token = this.currentState.snapshot.user.token;
    const url = this.environment.backend + path + '/' + request.userId + '/contactus';
    const options = {
      headers: new HttpHeaders({
        'Authorization': token
      })
    };
    this.ga.trackServiceCall('sendContactUsEmail', CommonConstants.GA.LABEL.SUPPORT);
    return this.http.post(url, request, options);
  }

  public deleteAccount(userId) {
    const path = '/private/users/delete/';
    const token = this.currentState.snapshot.user.token;
    const url = this.environment.backend + path + userId;
    const options = {
      headers: new HttpHeaders({
        'Authorization': token
      })
    };
    return this.http.delete(url, options);
  }

  public getNotifications() {
    const path = '/private/users/';
    const token = this.currentState.snapshot.user.token;
    const userId = this.currentState.snapshot.user.userDetails.userId;
    const url = this.environment.backend + path + userId + '/notifications';
    return this.http.get(url, {
      headers: new HttpHeaders({
        'Authorization': token
      })
    });
  }


  public updateNotification(userId, notId, show) {
    const path = '/private/users/';
    const token = this.currentState.snapshot.user.token;
    const url = this.environment.backend + path + userId + '/notifications/' + notId;
    return this.http.put(url, show, {
      headers: new HttpHeaders({
        'Authorization': token
      })
    });
  }

  public getACLsOfUser(userId: string | number) {
    const path = '/private/users/';
    const token = this.currentState.snapshot.user.token;
    let uid = !!userId ? userId : this.currentState.snapshot.user.userDetails.userId;
    const url = this.environment.backend + path + uid + '/acls';
    return this.http.get<Acl[]>(url, {
      headers: new HttpHeaders({
        'Authorization': token
      })
    });
  }


  public getAffiliates() {
    const path = '/public/';
    const url = this.environment.backend + path + 'affiliates';
    this.http.get<any[]>(url).subscribe(r => this.store.dispatch(new LoadAffiliates(r)))
  }

  public addAffiliateMembership(request: AffiliateMembership[]) {
    const path = '/private/users/affiliates/membership';
    const url = this.environment.backend + path;
    const token = this.currentState.snapshot.user.token;
    const options = {
      headers: new HttpHeaders({
        'Authorization': token
      })
    };
    return this.http.post(url, request, options);
  }

  public removeAffiliateMembership(membershipId: number) {
    const path = '/private/users/affiliates/membership/' + membershipId;
    const url = this.environment.backend + path;
    const token = this.currentState.snapshot.user.token;
    const options = {
      headers: new HttpHeaders({
        'Authorization': token
      })
    };
    return this.http.delete<any[]>(url, options);
  }

  public updateAffiliateMembership(membership) {
    const path = '/private/users/affiliates/membership/' + membership.id;
    const url = this.environment.backend + path;
    const token = this.currentState.snapshot.user.token;
    const options = {
      headers: new HttpHeaders({
        'Authorization': token
      })
    };
    return this.http.put<any>(url, membership, options);

  }

  loadPrfAssociations(userId: any) {
    const path = `/private/users/${userId}/prfassociations/`;
    const url = this.environment.backend + path;
    const token = this.currentState.snapshot.user.token;
    return this.http.get<PrfAssociation[]>(url, {
      headers: new HttpHeaders({
        'Authorization': token
      })
    });
  }
}
