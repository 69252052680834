import { Injectable } from '@angular/core';
import {
    Polygon
} from 'ol/geom';

export type PolyCoords = number[][][];

@Injectable({
    providedIn: 'root'
})
export class GeometryService {

    constructor() {

    }

    isValid(polyCoords: PolyCoords) {
        try {
            const polygon = new Polygon(polyCoords);
            return true;
        } catch (ex) {
            console.error('invalid polygon', ex);
        }
    }

    distance(coords1, coords2) {
        return Math.sqrt(Math.pow(coords1[0] - coords2[0], 2) + Math.pow(coords1[1] - coords2[1], 2));
    }

    equals(coords1: any[], coords2: any[]) {
        if (coords1.length === coords2.length) {
            let i = 0;
            let result = true;
            while (i < coords1.length && result) {
                result = Array.isArray(coords1[i])
                    ? Array.isArray(coords2[i])
                        ? this.equals(coords1[i], coords2[i])
                        : false
                    : coords1[i] === coords2[i];
                i++;
            }
            return result;
        }
        return false;
    }

    cleanCoordinates(coordinates: any[]) {
        let idx = coordinates.length - 1;
        while (idx > 0) {
            if (coordinates[idx][0] === coordinates[idx - 1][0] && coordinates[idx][1] === coordinates[idx - 1][1]) {
                coordinates.splice(idx, 1);
            }
            idx--;
        }
    }
}