import { Action } from '@ngrx/store';
import { type } from '../../types';
import { Boundary, BoundaryCreateRequest, MappingRequest } from '../../../model';

export const BoundaryActionTypes = {
  LOAD_USER_BOUNDARIES: type('[app] [Boundaries] Load User Boundaries'),
  LOAD_USER_BOUNDARIES_SUCCESS: type('[app] [Boundaries] Load User Boundaries Success'),
  LOAD_USER_BOUNDARIES_FAILED: type('[app] [Boundaries] Load User Boundaries Failed'),
  LOAD_BOUNDARIES: type('[app] [Boundaries] Load Boundaries'),
  LOAD_BOUNDARIES_SUCCESS: type('[app] [Boundaries] Load Boundaries Success'),
  LOAD_BOUNDARIES_FAILED: type('[app] [Boundaries] Load Boundaries Failed'),
  CREATE_BOUNDARY: type('[app] [Boundaries] Create Boundary'),
  CREATE_BOUNDARY_FAILED: type('[app] [Boundaries] Create Boundary failed'),
  CREATE_BOUNDARY_SUCCESS: type('[app] [Boundaries] Create Boundary success'),
  CREATE_BOUNDARIES_FROM_PARENT: type('[app] [Boundaries] Create Boundaries from parent'),
  CREATE_BOUNDARIES_FROM_PARENT_FAILED: type('[app] [Boundaries] Create Boundaries from parent failed'),
  CREATE_BOUNDARIES_FROM_PARENT_SUCCESS: type('[app] [Boundaries] Create Boundaries from parent success'),
  UPDATE_BOUNDARY: type('[app] [Boundaries] Update Boundary'),
  UPDATE_BOUNDARY_FAILED: type('[app] [Boundaries] Update Boundary failed'),
  UPDATE_BOUNDARY_SUCCESS: type('[app] [Boundaries] Update Boundary success'),
  DELETE_BOUNDARY: type('[app] [Boundaries] Delete Boundary'),
  DELETE_BOUNDARY_FAILED: type('[app] [Boundaries] Delete Boundary failed'),
  DELETE_BOUNDARY_SUCCESS: type('[app] [Boundaries] Delete Boundary success'),
  MASS_UPDATE_BOUNDARIES: type('[app] [Boundaries] Mass Updated Boundaries'),
  UN_MERGE_BOUNDARY_FAIL: type('[app] [Mappings] Unmerge boundary failed'),
  UN_MERGE_BOUNDARY_SUCCESS: type('[app] [Mappings] Unmerge boundary success'),
  UN_MERGE_BOUNDARY: type('[app] [Mappings] Unmerge boundary'),
  MERGE_POLYGONS: type('[app] [Mappings] Merge polygons'),
  MERGE_POLYGONS_SUCCESS: type('[app] [Mappings] Merge polygons success'),
  MERGE_POLYGONS_FAIL: type('[app] [Mappings] Merge polygons failed')
};

export class LoadUserBoundariesAction implements Action {
  readonly type = BoundaryActionTypes.LOAD_USER_BOUNDARIES;

  constructor(public payload: number | string) {
  }
}

export class LoadUserBoundariesSuccessAction implements Action {
  readonly type = BoundaryActionTypes.LOAD_USER_BOUNDARIES_SUCCESS;

  constructor(public payload: Boundary[]) {
  }
}

export class LoadUserBoundariesFailedAction implements Action {
  readonly type = BoundaryActionTypes.LOAD_USER_BOUNDARIES_FAILED;

  constructor(public payload: any) {
  }
}

export class LoadBoundariesAction implements Action {
  readonly type = BoundaryActionTypes.LOAD_BOUNDARIES;

  constructor(public payload: { boundaryIdsString: string, userId: number | string }) {
  }
}

export class LoadBoundariesSuccessAction implements Action {
  readonly type = BoundaryActionTypes.LOAD_BOUNDARIES_SUCCESS;

  constructor(public payload: Boundary[]) {
  }
}

export class LoadBoundariesFailedAction implements Action {
  readonly type = BoundaryActionTypes.LOAD_BOUNDARIES_FAILED;

  constructor(public payload: any) {
  }
}


export class CreateBoundaryAction implements Action {
  readonly type = BoundaryActionTypes.CREATE_BOUNDARY;

  constructor(public payload: { request: MappingRequest }) {
  }
}

export class CreateBoundarySuccessAction implements Action {
  readonly type = BoundaryActionTypes.CREATE_BOUNDARY_SUCCESS;

  constructor(public payload: Boundary) {
  }
}

export class CreateBoundaryFailedAction implements Action {
  readonly type = BoundaryActionTypes.CREATE_BOUNDARY_FAILED;

  constructor(public payload: any) {
  }
}

export class CreateBoundariesFromParentAction implements Action {
  readonly type = BoundaryActionTypes.CREATE_BOUNDARIES_FROM_PARENT;

  constructor(public payload: { request: BoundaryCreateRequest }) {
  }
}

export class CreateBoundariesFromParentSuccessAction implements Action {
  readonly type = BoundaryActionTypes.CREATE_BOUNDARIES_FROM_PARENT_SUCCESS;

  constructor(public payload: { added: Array<Boundary>, deleted: Boundary }) {
  }
}

export class CreateBoundariesFromParentFailedAction implements Action {
  readonly type = BoundaryActionTypes.CREATE_BOUNDARIES_FROM_PARENT_FAILED;

  constructor(public payload: any) {
  }
}

export class UpdateBoundaryAction implements Action {
  readonly type = BoundaryActionTypes.UPDATE_BOUNDARY;

  constructor(public payload: MappingRequest) {
  }
}

export class UpdateBoundarySuccessAction implements Action {
  readonly type = BoundaryActionTypes.UPDATE_BOUNDARY_SUCCESS;

  constructor(public payload: Boundary) {
  }
}

export class UpdateBoundaryFailedAction implements Action {
  readonly type = BoundaryActionTypes.UPDATE_BOUNDARY_FAILED;

  constructor(public payload: any) {
  }
}

export class DeleteBoundaryAction implements Action {
  readonly type = BoundaryActionTypes.DELETE_BOUNDARY;

  constructor(public payload: Boundary) {
  }
}

export class DeleteBoundarySuccessAction implements Action {
  readonly type = BoundaryActionTypes.DELETE_BOUNDARY_SUCCESS;

  constructor(public payload: Boundary) {
  }
}

export class DeleteBoundaryFailedAction implements Action {
  readonly type = BoundaryActionTypes.DELETE_BOUNDARY_FAILED;

  constructor(public payload: any) {
  }
}

export class MassUpdateBoundariesAction implements Action {
  readonly type = BoundaryActionTypes.MASS_UPDATE_BOUNDARIES;

  constructor(public payload: { event: string, newBoundaries: Boundary[], removedBoundaryIds: (string | number)[] }) {
  }
}

export class UnMergeBoundaryAction implements Action {
  type = BoundaryActionTypes.UN_MERGE_BOUNDARY;

  constructor(public payload: MappingRequest) {
  }
}
export class UnMergeBoundarySuccessAction implements Action {
  type = BoundaryActionTypes.UN_MERGE_BOUNDARY_SUCCESS;

  constructor(public payload: { newBoundary: Boundary, removed: (string | number)[] }) {
  }
}

export class UnMergeBoundaryFailedAction implements Action {
  type = BoundaryActionTypes.UN_MERGE_BOUNDARY_FAIL;

  constructor(public payload: MappingRequest[]) {
  }
}


export class MergePolygonsAction implements Action {
  type = BoundaryActionTypes.MERGE_POLYGONS;

  constructor(public payload: MappingRequest[]) {
  }
}
export class MergePolygonsSuccessAction implements Action {
  type = BoundaryActionTypes.MERGE_POLYGONS_SUCCESS;

  constructor(public payload: { newBoundary: Boundary, removed: (string | number)[] }) {
  }
}

export class MergePolygonsFailedAction implements Action {
  type = BoundaryActionTypes.MERGE_POLYGONS_FAIL;

  constructor(public payload: MappingRequest[]) {
  }
}


export type BoundaryActions = LoadUserBoundariesAction |
  LoadUserBoundariesSuccessAction | LoadUserBoundariesFailedAction |
  LoadBoundariesAction | LoadBoundariesSuccessAction | LoadUserBoundariesFailedAction |
  CreateBoundaryAction | CreateBoundarySuccessAction | CreateBoundaryFailedAction |
  CreateBoundariesFromParentAction | CreateBoundariesFromParentSuccessAction | CreateBoundariesFromParentFailedAction |
  UpdateBoundaryAction | UpdateBoundarySuccessAction | UpdateBoundaryFailedAction |
  DeleteBoundaryAction | DeleteBoundarySuccessAction | DeleteBoundaryFailedAction | MassUpdateBoundariesAction |
  MergePolygonsFailedAction | MergePolygonsSuccessAction | MergePolygonsAction |
  UnMergeBoundaryFailedAction | UnMergeBoundarySuccessAction | UnMergeBoundaryAction;
