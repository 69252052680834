import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CurrentState } from '../store/current-state';
import * as eq from '../model/Equipments';
import { CommonState } from '../store/reducer';
import { combineLatest, Observable } from 'rxjs';
import { ENVIRONMENT_TOKEN, GOOGLE_ANALYTICS_TOKEN } from "../../environment/token-variables";
import { equipmentActionTypes } from '../store/equipments';
import { ServicesCommon } from './services-common.service';

@Injectable()
export class EquipmentService {

  constructor(
    private http: HttpClient,
    private currentState: CurrentState<CommonState>,
    private servicesCommon: ServicesCommon,
    @Inject(ENVIRONMENT_TOKEN) private readonly environment,
  ) {
  }

  setHeaders() {
    const token = this.currentState.snapshot.user.token;
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', token);
    headers.append('Content-Type', 'application/json');
    return headers;
  }


  private deleteUserAsset(assetId: number, userId: number, path: string) {
    const url = this.environment.backend + `/private/assetmonitor/user/${userId}/` + path + `/${assetId}`;
    return this.http.delete(url, { headers: this.setHeaders() });
  }

  public deleteUserSubscription(userId: number, subscriptionId: number) {
    return this.deleteUserAsset(subscriptionId, userId, 'subscription');
  }

  public deleteUserEquipment(userId: number, equipmentId: number) {
    return this.deleteUserAsset(equipmentId, userId, 'equipment');
  }

  public updateCameraTitle(equipmentId: number, newTitle: string) {
    const url = this.environment.backend + "/private/assetmonitor/equipment/camera/" + equipmentId + "/";
    const headers = this.setHeaders();
    return this.http.put(url, newTitle, { headers: headers });
  }

  public deleteUserEquipments(userId: number) {
    const url = this.environment.backend + `/private/assetmonitor/user/${userId}/equipments`;
    return this.http.delete(url, { headers: this.setHeaders() });
  }

  getSubscriptionTypes(): Observable<eq.SubscriptionType[]> {
    return this.servicesCommon.getAll<eq.SubscriptionType>('/private/assetmonitor/subscription/types');
  }

  getSubscriptionPackages(): Observable<eq.SubscriptionPackage[]> {
    return this.servicesCommon.getAll<eq.SubscriptionPackage>('/private/assetmonitor/subscription/packages');
  }

  getCellPlans(): Observable<eq.CellPlan[]> {
    return this.servicesCommon.getAll<eq.CellPlan>('/private/assetmonitor/cellular/plans');
  }

  getCellProviders(): Observable<eq.CellProvider[]> {
    return this.servicesCommon.getAll<eq.CellProvider>('/private/assetmonitor/cellular/providers');
  }

  getEquipmentTypes(): Observable<eq.EquipmentType[]> {
    return this.servicesCommon.getAll<eq.EquipmentType>('/private/assetmonitor/equipments/types');
  }

  submitSubscription(subscriptionPackageId: number, subscriptionTypeId: number, userId: number, activationDate: string) {
    return this.http.post<number>(this.environment.backend + `/private/assetmonitor/user/${userId}/subscription/`,
      { subscriptionPackageId, subscriptionTypeId, activationDateString: activationDate }, { headers: this.setHeaders() });
  }

  submitEquipment(equipment: eq.EquipmentDto, userId: number) {
    return this.http.post<number>(this.environment.backend + `/private/assetmonitor/user/${userId}/equipment/`,
      equipment, { headers: this.setHeaders() });
  }

  getUserSubscriptions(userId) {
    return this.servicesCommon.getAll<eq.SubscriptionDto>(`/private/assetmonitor/user/${userId}/subscription`);

  }

  getUserEquipments(userId) {
    return this.servicesCommon.getAll<eq.EquipmentDto>(`/private/assetmonitor/user/${userId}/equipment`);

  }


}
