import { Injectable } from '@angular/core';
import { MapOperationService } from './map-operation.service';
import {Feature} from 'ol';
import { MeasurementService } from './measurement.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class SumBoundaryAreaService {

    constructor(
        private mapOperationService: MapOperationService,
        private measurementService: MeasurementService,
        private translateService: TranslateService) { }

    displaySum(selectedBoundaries: Feature[]) {
        let sumOverlay = document.getElementById('sum-overlay');
        if (!sumOverlay) {
            sumOverlay = document.createElement('div');
            sumOverlay.setAttribute('id', 'sum-overlay');
            document.getElementById(this.mapOperationService.targetId).append(sumOverlay);
        }
        const total = this.measurementService.getSumBoundaryArea(selectedBoundaries);
        if (total) {
            sumOverlay.innerHTML = `<b>${this.translateService.instant('Total Area')}:</b></br>${total}`;
            sumOverlay.style.display = 'block';
        } else {
            sumOverlay.style.display = 'none';
        }
    }

}