import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { CommonFeBaseEffects } from '../../common-fe-base.effects';
import { CurrentState } from '../../current-state';
import { CommonState } from '../../reducer';
import { Action } from '@ngrx/store';
import * as mappingActions from './marker.actions';
import { MarkerActionTypes } from './marker.actions';
import { MappingsService } from "../../../services/mappings.service";



@Injectable()
export class MarkerEffects extends CommonFeBaseEffects {

  @Effect()
  loadUserMarkers: Observable<Action>;

  @Effect()
  loadMarker: Observable<Action>;

  @Effect()
  loadMarkerTypes: Observable<Action>;

  @Effect()
  createMarker: Observable<Action>;

  @Effect()
  updateMarker: Observable<Action>;

  @Effect()
  deleteMarker: Observable<Action>;

  constructor(
    protected currentState: CurrentState<CommonState>,
    private action$: Actions,
    private mappingService: MappingsService
  ) {
    super();
    this.loadUserMarkers = this.action$.pipe(
      ofType(MarkerActionTypes.LOAD_USER_MARKERS),
      map((action: mappingActions.LoadUserMarkersAction) => action.payload),
      switchMap((userId) => this.mappingService.getAllUserMarkers(userId).pipe(
        map(markers => new mappingActions.LoadUserMarkersSuccessAction(markers)),
        catchError(this.handleHttpError)
      )),
    );
    this.loadMarker = this.action$.pipe(
      ofType(MarkerActionTypes.LOAD_MARKER),
      map((action: mappingActions.LoadMarkerAction) => action.payload),
      switchMap((markerId) => this.mappingService.getUserMarker(markerId).pipe(
        map(marker => new mappingActions.LoadMarkerSuccessAction(marker)),
        catchError(this.handleHttpError)
      )),
    );
    this.loadMarkerTypes = this.action$.pipe(
      ofType(MarkerActionTypes.LOAD_MARKER_TYPES),
      switchMap(() => this.mappingService.getMarkerTypes().pipe(
        map(types => new mappingActions.LoadMarkerTypesSuccessAction(types)),
        catchError(this.handleHttpError)
      )),
    );
    this.createMarker = this.action$.pipe(
      ofType(MarkerActionTypes.CREATE_MARKER),
      map((action: mappingActions.CreateMarkerAction) => action.payload),
      switchMap((request) => this.mappingService.createMarker(request).pipe(
        map(newMarker => new mappingActions.CreateMarkerSuccessAction(newMarker)),
        catchError(this.handleHttpError)
      )),
    );
    this.updateMarker = this.action$.pipe(
      ofType(MarkerActionTypes.UPDATE_MARKER),
      map((action: mappingActions.UpdateMarkerAction) => action.payload),
      switchMap((request) => this.mappingService.updateMarker(request).pipe(
        map(newMarker => new mappingActions.UpdateMarkerSuccessAction(newMarker)),
        catchError(this.handleHttpError)
      )),
    );
    this.deleteMarker = this.action$.pipe(
      ofType(MarkerActionTypes.DELETE_MARKER),
      map((action: mappingActions.DeleteMarkerAction) => action.payload),
      switchMap((markerId) => this.mappingService.removeMarker(markerId).pipe(
        map(marker => new mappingActions.DeleteMarkerSuccessAction(marker)),
        catchError(this.handleHttpError)
      )),
    );
  }
}
