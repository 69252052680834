import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { CurrentState } from "common-lib";
import { PortalAppState } from "../store/reducer";
import { PortalUserService } from "../services/portal-user.service";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class LoggedInGuard implements CanActivate {
  constructor(
    private currentState: CurrentState<PortalAppState>,
    public router: Router, private portalUserService: PortalUserService,
  ) {
  }

  /**
   * This guard is to make sure user does not go back to login page if he is logged in.
   * Used only for login page.
   */
  canActivate(route: ActivatedRouteSnapshot, routerStateSnapshot: RouterStateSnapshot): Observable<boolean> {
    return this.portalUserService.isLoggedInUser().pipe(map(loggedIn => !loggedIn));
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.canActivate(childRoute, state);
  }
}
