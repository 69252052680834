<h3>
    {{title}}
</h3>
<span>{{subtitle}}</span>
<mat-nav-list>
    <a mat-list-item (click)="openLink('newUser')">
      <span matListItemTitle>{{newUserButton}}</span>
    </a>
    <a mat-list-item (click)="openLink('existingUser')">
      <span matListItemTitle>{{existingUserButton}}</span>
    </a>
</mat-nav-list>