import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import {
  DeleteAttachmentSuccessAction,
  EntityWithImage,
  ServicesCommon,
  SetAttachmentsAction,
} from "common-lib";
import { PortalAppState } from "../store/reducer";
import { AttachmentsPage } from "../pages/attachments/attachments.page";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { catchError } from "rxjs/operators";
import { of } from "rxjs";


@Injectable({
  providedIn: 'root'
})
export class AttachmentsPortalService {

  constructor(
    protected store: Store<PortalAppState>,
    public dialog: MatDialog,
    private servicesCommon: ServicesCommon,
    private http: HttpClient,
  ) {
  }


  openAttachments(type, attachments, newAttachment: EntityWithImage, id: number, dismissCallback: (result) => void = null) {
    const a = !!attachments ? Array.isArray(attachments) ? attachments : [attachments] : !!newAttachment ? [newAttachment] : null;
    const payload = {
      attachments: a,
      type: type as ('task' | 'marker' | 'item' | 'profit_loss' | 'animal'),
      id: id
    }
    this.store.dispatch(new SetAttachmentsAction(payload));

    const dialogRef = this.dialog.open(AttachmentsPage, {
      height: '600px',
      width: '500px',
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      if (!!result) {
        console.log(result);
        if (!!dismissCallback) {
          dismissCallback(result);
        }
      }
    });
  }

  deleteAttachment(attachment) {
    this.http.delete(
      environment.backend + `/private/attachments/${attachment.id}`,
      {headers: this.servicesCommon.getAuthHeaders()}
    ).pipe(catchError(error => {
      console.error(error);
      return of(null);
    })).subscribe((response: { attachmentId: number, type: 'task' | 'marker' | 'item' | 'profit_loss' | 'animal', moduleEntityId: number }) => {
      if (!!response) {
        this.store.dispatch(new DeleteAttachmentSuccessAction(response));
      }
    });
  }

}
