import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CommonState } from '../store/reducer';
import { CurrentState } from '../store/current-state';


export abstract class ServicesCommonBase {

  abstract getBaseUrl();

  constructor(
    protected currentState: CurrentState<CommonState>,
    protected readonly environment,
    protected http: HttpClient) {
  }

  getAuthHeaders() {
    const token = this.currentState.snapshot.user.token;
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', token);
    headers.append('Content-Type', 'application/json');
    return headers;
  }

  getAll<T>(path: string): Observable<T[]> {
    const url = this.getBaseUrl() + path;
    return this.http.get<T[]>(url, {headers: this.getAuthHeaders()});
  }

  get<T>(path: string): Observable<T> {
    const url = this.getBaseUrl() + path;
    return this.http.get<T>(url, {headers: this.getAuthHeaders()});
  }

  getUrl(path: string) {
    const url = this.getBaseUrl() + path;
    return url;
  }
}
