import { Action } from '@ngrx/store';
import * as user from './user.actions';
import { AuthActionTypes } from '../authentication';
import { AdminActionTypes } from '../admin';
import { ActionWithPayload } from '../types';
import { Affiliate, UserDetail } from "../../../public-api";

export interface UserState {
  isLoaded: boolean;
  isLoggedIn: boolean;
  token: string;
  id: number;
  userDetails: UserDetail,
  superLogin: boolean,
  affiliates: Affiliate[]
}

export const userStateInitialState: UserState = {
  isLoaded: false,
  isLoggedIn: false,
  token: null,
  id: null,
  userDetails: {
    aclsOfUser: [],
    admin: false,
    prfAdmin: false,
    email: null,
    firstName: null,
    lastName: null,
    phoneNumber: null,
    prfAssociations: [],
    userId: null,
    userName: null,
    nrOfParcelUsage: null,
    moduleSubscriptions: null,
    notifications: [],
    ranchesOfUser: [],
    ownedRanch: null,
    termsAndConditions: [],
    latestPRFYear: null,
    camerasOfUser: [],
    livestockSubscriptions: null,
    memberships: [],
    messages: null
  },
  superLogin: false,
  affiliates: []
};


export function userReducer(state: UserState = userStateInitialState, action: Action): UserState {
  let ud;
  let newUserDetails;
  switch (action.type) {
    case AuthActionTypes.LOGIN_SUCCESS:
      // case AuthActionTypes.RENEW_TOKEN_SUCCESS:
      const tokenDetails = (action as ActionWithPayload<any>).payload;
      return {
        ...state,
        isLoggedIn: true,
        token: tokenDetails.token,
        id: tokenDetails.userId,
        superLogin: false
      };
    case user.UserActionTypes.LOAD_USER_SUCCESS:
      const userDetails = (action as ActionWithPayload<any>).payload;
      return {
        ...state,
        isLoaded: true,
        userDetails: userDetails,
      };
    case user.UserActionTypes.SET_PHONE_AND_EMAIL:
      const phoneAndEmail = (action as ActionWithPayload<any>).payload;
      ud = {
        ...state.userDetails
      };
      if (!ud.phoneNumber) {
        ud.phoneNumber = phoneAndEmail.phone
      }
      if (!ud.email) {
        ud.email = phoneAndEmail.email
      }
      return {
        ...state,
        userDetails: ud
      };
    case AuthActionTypes.LOGOUT_SUCCESS:
      return userStateInitialState;
    case user.UserActionTypes.SET_PARCEL_LAYER_USAGE_COUNT:
      const nr = (action as ActionWithPayload<any>).payload;
      ud = {...state.userDetails};
      ud.nrOfParcelUsage = nr;
      return {
        ...state,
        userDetails: ud
      };
    case AuthActionTypes.LOGOUT:
      return {...state, isLoggedIn: false}
    case user.UserActionTypes.SET_PRF_ASSOCIATIONS:
      const prfAssociations = (action as ActionWithPayload<any>).payload.associations;
      const userId = (action as ActionWithPayload<any>).payload.userId;
      if (userId == state.userDetails.id) {
        ud = {...state.userDetails, prfAssociations: prfAssociations};
        return {...state, userDetails: ud}
      }
      return {...state};

    case AdminActionTypes.SUPER_DUPER_LOGIN_SUCCESS:
      const td = (action as ActionWithPayload<any>).payload;
      return {
        ...state,
        isLoggedIn: true,
        token: td.token,
        id: td.userId,
        superLogin: true
      };
    case user.UserActionTypes.SET_MODULE_SUBSCRIPTIONS:
      const subs = (action as ActionWithPayload<any>).payload;
      return {
        ...state,
        userDetails: {...state.userDetails, moduleSubscriptions: subs}
      };
    case user.UserActionTypes.UPDATE_NOTIFICATION_SUCCESS:
      const notId = (action as ActionWithPayload<any>).payload;
      let notifications = state.userDetails.notifications.map(noti => {
        let n = {...noti};
        if (n.id == notId) {
          n.show = false;
        }
        return n;
      });
      newUserDetails = {
        ...state.userDetails,
        notifications: notifications
      };
      return {
        ...state,
        userDetails: newUserDetails
      }
    case user.UserActionTypes.SET_USER_ACLS:
      const acls = (action as ActionWithPayload<any>).payload;
      newUserDetails = {
        ...state.userDetails,
        aclsOfUser: acls
      };
      return {
        ...state,
        userDetails: newUserDetails
      }
    case user.UserActionTypes.SET_OWNED_RANCH:
      const newRanch = (action as ActionWithPayload<any>).payload;
      newUserDetails = {
        ...state.userDetails,
        ownedRanch: newRanch
      };
      return {
        ...state,
        userDetails: newUserDetails
      }
    case user.UserActionTypes.LOAD_AFFILIATES_ACTION:
      const affiliates = (action as ActionWithPayload<any>).payload;
      return {
        ...state,
        affiliates: affiliates
      }
    case user.UserActionTypes.SET_TERMS_AND_CONDITIONS_ACTION:
      const termsAndConditions = (action as ActionWithPayload<any>).payload?.termsAndConditions;
      newUserDetails = {
        ...state.userDetails,
        termsAndConditions: termsAndConditions
      };
      return {
        ...state,
        userDetails: newUserDetails
      }
    case user.UserActionTypes.SET_AFFILIATE_MEMBERSHIPS:
      const memberships = (action as ActionWithPayload<any>).payload?.memberships;
      newUserDetails = {
        ...state.userDetails,
        memberships: memberships
      };
      return {
        ...state,
        userDetails: newUserDetails
      }
    default:
      return state;
  }
}

