import { MarkerActionTypes } from './marker.actions';

import { ActionWithPayload } from '../../types';
import { UserMarker, UserMarkerType } from '../../../model';
import { AttachmentActionTypes } from "../../attachments";


export interface MarkerState {
  markers: UserMarker[];
  selectedMarker: UserMarker; //do we need it?
  markerTypes: UserMarkerType[];
}

const markersInitialState: MarkerState = {
  markers: [],
  selectedMarker: null,
  markerTypes: [],
};


export function markerReducer(state = markersInitialState, action: ActionWithPayload<any>): MarkerState {
  switch (action.type) {
    case MarkerActionTypes.LOAD_USER_MARKERS_SUCCESS: {
      if (action.payload && Array.isArray(action.payload)) {
        return {...state, markers: action.payload};
      }
      break;
    }
    case MarkerActionTypes.LOAD_MARKER_SUCCESS: {
      if (action.payload) {
        const newMarker: UserMarker = action.payload;
        const markers = state.markers.filter(marker => marker.id !== +newMarker.id);
        markers.push(newMarker);
        if (newMarker.id == state.selectedMarker?.id) {
          return {...state, markers, selectedMarker: newMarker};
        }
        return {...state, markers};
      }
      break;
    }
    case MarkerActionTypes.LOAD_MARKER_TYPES_SUCCESS: {
      if (action.payload && Array.isArray(action.payload)) {
        return {...state, markerTypes: action.payload};
      }
      break;
    }
    case MarkerActionTypes.UPDATE_MARKER_SUCCESS: {
      const updatedMarker = (action as ActionWithPayload<any>).payload;
      const markers = state.markers.filter(marker => marker.id !== updatedMarker.id);
      markers.push(updatedMarker);
      return {...state, markers};
    }
    case MarkerActionTypes.CREATE_MARKER_SUCCESS: {
      const newMarker = (action as ActionWithPayload<any>).payload;
      return {...state, markers: [...state.markers, newMarker]};
    }
    case MarkerActionTypes.DELETE_MARKER_SUCCESS: {
      const removedMarkerId = (action as ActionWithPayload<any>).payload;
      const markers = state.markers.filter(marker => marker.id !== removedMarkerId);
      return {...state, markers};
    }
    case MarkerActionTypes.SELECT_MARKER: {
      const marker = (action as ActionWithPayload<any>).payload;
      return {...state, selectedMarker: marker};
    }
    case AttachmentActionTypes.DELETE_ATTACHMENT_SUCCESS: {
      const payload = (action as ActionWithPayload<any>).payload;
      const entityId = payload.moduleEntityId;
      const attachmentId = payload.attachmentId;
      const type = payload.type;
      if (type == 'marker') {
        let markers = state.markers.map(m => {
          if (m == entityId) {
            m.attachments = m.attachments.filter(a => a.id != attachmentId);
          }
          return m;
        })
        return {...state, markers};
      }
      return {...state, markers: state.markers};
    }
    default: {
      return state;
    }
  }
}
