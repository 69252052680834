import { createSelector } from "@ngrx/store";
import { CommonState } from "./reducer";
import { Animal, GPSEarTag } from "../model";

function getSelectedRanchId(store: CommonState): any {
  return store.ranches.selectedRanchId;
}

export function isLoggedIn(state: CommonState): boolean {
  return !!state.user ? state.user.isLoggedIn : false;
}

function modulePermissions(store: CommonState): Map<number | string, any[]> {
  if (!!store.user && !!store.user.userDetails) {
    return store.user.userDetails.moduleSubscriptions;
  }
  return null;
}

export const hasLivestockPermission = createSelector(
  isLoggedIn,
  getSelectedRanchId,
  modulePermissions,
  (loggedIn, selectedRanchId, permissions) => {
    if (!loggedIn) {
      return null;
    }
    if (!selectedRanchId) {
      return false;
    }
    const permissionsOfRanch = permissions[selectedRanchId];
    if (!permissionsOfRanch) {
      return false;
    }
    const activePermissions = permissionsOfRanch?.filter(p => p.active && (p.name == 'ls_premium' || p.name == 'ls_free_trial' || p.name == 'ls_premium_finance'));
    if (!!activePermissions && activePermissions.length > 0) {
      return true;
    }
    return false;
})

export const hasFinancePermission = createSelector(
  isLoggedIn,
  getSelectedRanchId,
  modulePermissions,
  (loggedIn, selectedRanchId, permissions) => {
    if (!loggedIn) {
      return null;
    }
    if (!selectedRanchId) {
      return false;
    }
    const permissionsOfRanch = permissions[selectedRanchId];
    if (!permissionsOfRanch) {
      return false;
    }
    const activePermissions = permissionsOfRanch.filter(p => p.active && (p.name == 'finance_access' || p.name == 'ls_premium_finance'));
    if (!!activePermissions && activePermissions.length > 0) {
      return true;
    }
    return false;
})

export const hasProfitLossPermission = createSelector(
  getSelectedRanchId,
  modulePermissions,
  (selectedRanchId, permissions) => {
    if (!selectedRanchId) {
      return false;
    }
    const permissionsOfRanch = permissions[selectedRanchId];
    if (!permissionsOfRanch) {
      return false;
    }
    const activePermissions = permissionsOfRanch.filter(p => p.active && p.profitLossEnabled);
    if (!!activePermissions && activePermissions.length > 0) {
      return true;
    }
    return false;
})

function ranchIds(store: CommonState): any[] {
  if (!!store.ranches && !!store.ranches.ranchesOfUser) {
    return store.ranches.ranchesOfUser.map(r => r.ranch.id);
  }
  return [];
}

export const trialLivestockPermissionExpired = createSelector(
  ranchIds,
  modulePermissions,
  (ranchIds, permissions) => {
    for (let i = 0; i < ranchIds.length; i++) {
      const selectedRanchId = ranchIds[i];
      const permissionsOfRanch = permissions[selectedRanchId];
      if (!permissionsOfRanch) {
        continue;
      }
      const freeTrial = permissionsOfRanch.find(p => p.name == 'ls_free_trial');
      if (!!freeTrial) {
        return !freeTrial.active;
      }
    }
    return false;
})

function getAllGPSEarTagLatest(store: CommonState): GPSEarTag[] {
  return store.gpsEarTags.latestPositions;
}
function getAllAnimals(store: CommonState): Animal[] {
  return store.livestock.animals;
}

export const allGpsLatest = createSelector(getAllGPSEarTagLatest, getAllAnimals,
  (gpsEarTags, animals) => {
    let enriched = gpsEarTags.map(tag => {
      const animalId = tag.animalId;
      const find = animals.find(animal => {
        return animal.id == animalId
      });
      if (find) {
        return {...tag, lot: find.lot}
      }
      return {...tag};
    })
    return enriched;
});