import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Inject, Injectable } from '@angular/core';
import { CurrentState } from "../store/current-state";
import { CommonState } from "../store/reducer";
import { ENVIRONMENT_TOKEN } from "../../environment/token-variables";
import {
  MessageEntity,
  PasswordRequest,
  PermissionRequest,
  PRFAssociationRequest,
  UserRequest,
  UserDetail
} from "../model";
import { Observable } from 'rxjs';

/**
 * Service for presenting Alerts one by one instead of stacking up on each other at the same time.
 */
@Injectable()
export class AdminService {

  ADMIN_PATH = '/private/admin/';

  constructor(
    private currentState: CurrentState<CommonState>,
    @Inject(ENVIRONMENT_TOKEN) private readonly environment,
    protected http: HttpClient) {

  }

  public loadAllUsers(pagingObject: { pageSize: number, pageNumber: number }) {
    const path = this.ADMIN_PATH + 'users/';
    const url = this.environment.backend + path;
    let params = new HttpParams();
    if (pagingObject && pagingObject.pageSize && pagingObject.pageNumber) {
      params = new HttpParams().set('pageSize', pagingObject.pageSize + '');
      params = params.set('pageNumber', pagingObject.pageNumber + '');
    }
    return this.http.get(url, {
      headers: this.getBasicHeaders(), params
    });
  }

  public getFilteredUsers(searchString: string): Observable<UserDetail[]> {
    const url = this.environment.backend + this.ADMIN_PATH + `users/search/${searchString}`;
    return this.http.get<UserDetail[]>(url, {headers: this.getBasicHeaders()});
  }

  public getUser(userId) {
    const path = this.ADMIN_PATH + 'users/';
    const url = this.environment.backend + path + userId;
    return this.http.get(url, {
      headers: this.getBasicHeaders()
    });
  }

  public loadAssetsForUser(userId: string) {
    const path = this.ADMIN_PATH + 'users/';
    const url = this.environment.backend + path + userId + "/assets";
    return this.http.get(url, {
      headers: this.getBasicHeaders()
    });
  }

  public loadAllRoles() {
    const path = this.ADMIN_PATH + 'roles/';
    const url = this.environment.backend + path;
    return this.http.get(url, {
      headers: this.getBasicHeaders()
    });
  }

  public loadRoleById(roleId) {
    const path = this.ADMIN_PATH + 'roles/';

    const url = this.environment.backend + path + roleId;
    return this.http.get(url, {
      headers: this.getBasicHeaders()
    });
  }

  public createNewRole(request: { title, name, description }) {
    const path = this.ADMIN_PATH + 'roles/';
    const url = this.environment.backend + path;
    return this.http.post(url, request, {
      headers: this.getBasicHeaders()
    });
  }

  public createNewUser(request: UserRequest) {
    const path = this.ADMIN_PATH + 'users/';
    const url = this.environment.backend + path;
    request.changerUserId = this.currentState.snapshot.user.id;
    return this.http.post(url, request, {
      headers: this.getBasicHeaders()
    });
  }

  public addPrfRoles(userId: number) {
    const path = this.ADMIN_PATH + 'users/prfAssociations/roles';
    const url = this.environment.backend + path;
    let request = {
      userId: userId
    }
    return this.http.post(url, request, {
      headers: this.getBasicHeaders()
    });
  }

  deleteRole(roleId) {
    const path = this.ADMIN_PATH + 'roles/';

    const url = this.environment.backend + path + roleId;
    return this.http.delete(url, {
      headers: this.getBasicHeaders()
    });
  }

  loadAllSysfunctions() {
    const path = this.ADMIN_PATH + 'sysfunctions/';
    const url = this.environment.backend + path;
    return this.http.get(url, {
      headers: this.getBasicHeaders()
    });
  }

  loadAllActions() {
    const path = this.ADMIN_PATH + 'actions/';
    const url = this.environment.backend + path;
    return this.http.get(url, {
      headers: this.getBasicHeaders()
    });
  }

  public addRoleToUser(request: PermissionRequest) {
    const path = this.ADMIN_PATH + 'acl/users/';
    const url = this.environment.backend + path + request.userId + '/roles';
    return this.http.post(url, request, {
      headers: this.getBasicHeaders()
    });
  }

  public removeRoleFromUser(request: PermissionRequest) {
    const path = this.ADMIN_PATH + 'acl/users/';
    const url = this.environment.backend + path + request.userId + '/roles/' + request.roleId;
    let options = {
      headers: this.getBasicHeaders()
    };

    return this.http.delete(url, options);
  }

  public addNewAclToUser(request: PermissionRequest) {
    const path = this.ADMIN_PATH + 'acl/users/';
    const url = this.environment.backend + path + request.userId + '/permissions';
    const options = {
      headers: this.getBasicHeaders()
    };
    return this.http.post(url, request, options);
  }

  public removeAclFromUser(request: PermissionRequest) {
    const path = this.ADMIN_PATH + 'acl/users/';
    let params = new HttpParams().set('functId', request.systemfunctionId);
    params = params.append('actionId', request.actionIds[0] + '');
    const url = this.environment.backend + path + request.userId + '/permissions';
    const options = {
      headers: this.getBasicHeaders(),
      params: params
    };
    return this.http.delete(url, options);
  }

  public addNewAclToRole(request: PermissionRequest) {
    const path = this.ADMIN_PATH + 'acl/roles/';
    const url = this.environment.backend + path + request.roleId + '/permissions';
    const options = {
      headers: this.getBasicHeaders()
    };
    return this.http.post(url, request, options);
  }

  /**
   * Removes a given sysfunction with action from the selectedRole.
   * @param request
   */
  public removeAclFromRole(request: PermissionRequest) {
    const path = this.ADMIN_PATH + 'acl/roles/';
    let params = new HttpParams().set('functId', request.systemfunctionId);
    params = params.append('actionId', request.actionIds[0] + '');
    const url = this.environment.backend + path + request.roleId + '/permissions';
    const options = {
      headers: this.getBasicHeaders(),
      params: params
    };
    return this.http.delete(url, options);
  }

  public updateUserPassword(request: PasswordRequest) {
    const path = this.ADMIN_PATH + 'users/';

    const url = this.environment.backend + path + request.userId + '/password';
    const options = {
      headers: this.getBasicHeaders()
    };

    return this.http.put(url, request, options);
  }

  public deleteUser(userId) {
    const path = this.ADMIN_PATH + 'users/';

    const url = this.environment.backend + path + userId;
    const options = {
      headers: this.getBasicHeaders()
    };
    return this.http.delete(url, options);
  }

  public saveNewPRFAssociation(request: PRFAssociationRequest[]) {

    const path = this.ADMIN_PATH + 'users/prfAssociations/';

    const url = this.environment.backend + path;
    const options = {
      headers: this.getBasicHeaders()
    };
    return this.http.post(url, request, options);
  }

  public deletePRFAssociation(prfAssociation: number, company: string) {
    const path = this.ADMIN_PATH + 'users/prfAssociations/' + company + '/';

    const url = this.environment.backend + path + prfAssociation;
    const options = {
      headers: this.getBasicHeaders()
    };
    return this.http.delete(url, options);
  }

  public saveNewLRPAssociation(request: PRFAssociationRequest[]) {

    const path = this.ADMIN_PATH + 'users/lrpAssociations/';

    const url = this.environment.backend + path;
    const options = {
      headers: this.getBasicHeaders()
    };
    return this.http.post(url, request, options);
  }

  public deleteLRPAssociation(lrpAssociation: number, company: string) {
    const path = this.ADMIN_PATH + 'users/lrpAssociations/' + company + '/';

    const url = this.environment.backend + path + lrpAssociation;
    const options = {
      headers: this.getBasicHeaders()
    };
    return this.http.delete(url, options);
  }

  /**
   * Service call to super login. It will login the user related to the userId
   * @param userId
   */
  public superLogin(userId: string | number) {
    const path = this.ADMIN_PATH + 'users/';
    const url = this.environment.backend + path + userId + '/superlogin';
    return this.http.post(url, {userId}, {
      headers: this.getBasicHeaders()
    });
  }

  public createNewInterruptionMessage(message: MessageEntity) {
    const request = {
      title: message.title,
      subTitle: message.subTitle,
      message: message.message,
      roleIds: message.roleIds
    };
    const path = this.ADMIN_PATH + 'messages/interruption';

    const url = this.environment.backend + path;
    const options = {
      headers: this.getBasicHeaders()
    };
    return this.http.post(url, request, options);
  }

  public loadMostRecentMessage() {
    const path = this.ADMIN_PATH + 'messages/interruption';

    const url = this.environment.backend + path;
    return this.http.get(url, {
      headers: this.getBasicHeaders()
    });
  }

  public udpateMesage(message: MessageEntity) {
    const request = {
      messageId: message.id,
      title: message.title,
      subTitle: message.subTitle,
      message: message.message
    };
    const path = this.ADMIN_PATH + 'messages/interruption/';
    const url = this.environment.backend + path + message.id;
    const options = {
      headers: this.getBasicHeaders()
    };
    return this.http.put(url, request, options);
  }


  public deleteMsssage(messageId) {
    const path = this.ADMIN_PATH + 'messages/interruption/';
    const url = this.environment.backend + path + messageId;
    const options = {
      headers: this.getBasicHeaders()
    };
    return this.http.delete(url, options);
  }

  sendGeneralPushNotificationMessage({title, message, roleIds, pushAction}): Observable<any> {
    const path = this.ADMIN_PATH + 'messages/push';
    const token = this.currentState.snapshot.user.token;
    const url = this.environment.backend + path;

    return this.http.post(url, {title, message, roleIds, pushAction}, {
      headers: new HttpHeaders({
          'Authorization': token
        }
      )
    });
  }

  private getBasicHeaders() {
    const token = this.currentState.snapshot.user.token;
    return new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': token
    });
  }


  deleteRanch(selectedRanch: any) {
    const path = this.ADMIN_PATH + 'ranches/';
    const token = this.currentState.snapshot.user.token;
    const url = this.environment.backend + path + selectedRanch;
    //this.ga.trackServiceCall('deleteRanch', CommonConstants.GA.LABEL.RANCHES);
    return this.http.delete(url, {
      headers: new HttpHeaders({
        'Authorization': token
      })
    });
  }

  public convertRoleName(data) {
    let name = data.name;
    if (!name) {
      name = data.title.toLowerCase();
    }
    name = name.replace(/\s/g, '_');
    return name;
  }
}
