import { ActionWithPayload } from "../../types";
import { MapPropertiesActions, MapPreselectionActionTypes } from "./map-properties.actions";
import { UserActionTypes } from "../../user";


export interface MapPropertiesState {
  parcelLayerApiKey: string;
}

const propertiesInitialState: MapPropertiesState = {
  parcelLayerApiKey: null
};

export function mapPropertiesReducer(state = propertiesInitialState, action: ActionWithPayload<any>): MapPropertiesState {
  let payload = action.payload;
  switch (action.type) {
    case UserActionTypes.LOAD_USER_SUCCESS:
      const userDetails = (action as ActionWithPayload<any>).payload;
      return {
        ...state,
        parcelLayerApiKey: userDetails.parcelLayerApiKey
      };
    default: {
      return state;
    }
  }
}
