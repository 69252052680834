import { Injectable, NgZone } from "@angular/core";
import { CurrentState } from "../store/current-state";
import { CommonState } from "../store/reducer";
import { HttpClient } from "@angular/common/http";
import { LRPQuoteEndorsement, LRPWatchListEndorsement, LRPWatchListQuote, UserDetail } from "../model";
import { County, LRPCommodity, LRPCommodityType, LRPEndorsementLength, LRPRequest, State } from "../model/LRPModel";
import { ServicesPRFCommon } from "./services-prf-common.service";
import { Observable } from "rxjs";

@Injectable()
export class LRPService {
  private readonly LRP_URL = `/private/lrp/base/`;

  constructor(
    private currentState: CurrentState<CommonState>,
    protected http: HttpClient,
    private servicesCommon: ServicesPRFCommon,
  ) {
  }

  public loadAllCommodities(): Observable<LRPCommodity[]> {
    return this.servicesCommon.getAll<LRPCommodity>(this.LRP_URL + 'commodities')
  }

  public loadAllEndorsementLengths(): Observable<LRPEndorsementLength[]> {
    return this.servicesCommon.getAll<LRPEndorsementLength>(this.LRP_URL + 'endorsements/lengths')
  }

  public loadAllCommodityTypes(): Observable<LRPCommodityType[]> {
    return this.servicesCommon.getAll<LRPCommodityType>(this.LRP_URL + 'commodities/types')
  }

  public loadAllStates(): Observable<State[]> {
    return this.servicesCommon.getAll<State>(this.LRP_URL + 'states')
  }

  getLRPProducers(request: { company: "base"; primaryName: string }) {
    return this.servicesCommon.getAll<any>(`/private/lrp/${request.company}/producers/${request.primaryName}`);
  }

  loadAllCounties() {
    return this.servicesCommon.getAll<County>(this.LRP_URL + 'counties')
  }


  loadAllLRPUsers() {
    return this.servicesCommon.getAll<UserDetail>(this.LRP_URL + 'users')
  }

  finalizeQuote(request: LRPRequest) {
    let userId = request.userId;
    if (!request.userId) {
      userId = this.currentState.snapshot.user.id;
    }
    let url = this.servicesCommon.getUrl(`/private/lrp/base/endorsements/finalize/user/${userId}`);
    return this.http.post<number>(url, request, {headers: this.servicesCommon.getAuthHeaders()});
  }

  loadLatestEffectiveDate() {
    return this.servicesCommon.get<{ latestEffectiveDate: string }>(this.LRP_URL + 'effectivedate/latest')
  }

  runQuote(request: LRPRequest) {
    let endorsementLengthCount = '';
    if (!!request?.endorsementLengthCounts) {
      request?.endorsementLengthCounts.forEach(l => {
        endorsementLengthCount += l + ',';
      })
      if (endorsementLengthCount.length > 0) {
        endorsementLengthCount = endorsementLengthCount.substring(0, endorsementLengthCount.length - 1);
      }
    }
    let commodityTypes = '';
    if (!!request?.typeCodes) {
      request?.typeCodes.forEach(l => {
        commodityTypes += l + ',';
      })
      if (commodityTypes.length > 0) {
        commodityTypes = commodityTypes.substring(0, commodityTypes.length - 1);
      }
    }
    let effDate = 'effectiveDate=' + (!!request.effectiveDate ? request.effectiveDate : '');
    let stateFipsCode = 'stateFipsCode=' + (!!request?.stateFipsCode ? request?.stateFipsCode : '');
    let countyFipsCode = 'countyFipsCode=' + (!!request?.countyFipsCode ? request?.countyFipsCode : '');
    let commodityCode = 'commodityCode=' + (!!request?.commodityCode ? request?.commodityCode : '');
    let typeCode = 'typeCode=' + commodityTypes;
    let numberOfHead = 'numberOfHead=' + (!!request?.numberOfHead ? request?.numberOfHead : -1);
    let targetWeight = 'targetWeight=' + (!!request?.targetWeight ? request?.targetWeight : -1);
    let length = 'length=' + endorsementLengthCount;
    let insuredShare = 'insuredShare=' + (!!request?.insuredShare ? request?.insuredShare : -1);

    let queryParams = '?'
      + effDate
      + '&' + stateFipsCode
      + '&' + countyFipsCode
      + '&' + commodityCode
      + '&' + typeCode
      + '&' + numberOfHead
      + '&' + targetWeight
      + '&' + length
      + '&' + insuredShare
    return this.servicesCommon.getAll<any>(this.LRP_URL + 'endorsements' + queryParams);

  }


  createNewWatchList(request: LRPRequest) {
    let userId = request.userId;
    if (!request.userId) {
      userId = this.currentState.snapshot.user.id;
    }
    let url = this.servicesCommon.getUrl(`/private/lrp/base/watchlist/user/${userId}`);
    return this.http.post<LRPWatchListQuote>(url, request, {headers: this.servicesCommon.getAuthHeaders()});
  }

  deleteWatchList(id: number) {
    let url = this.servicesCommon.getUrl(`/private/lrp/base/watchlist/${id}`);
    return this.http.delete<LRPWatchListQuote>(url, {headers: this.servicesCommon.getAuthHeaders()});
  }

  updateWatchList(request: LRPRequest) {
    let url = this.servicesCommon.getUrl(`/private/lrp/base/watchlist`);
    return this.http.put<LRPWatchListQuote>(url, request, {headers: this.servicesCommon.getAuthHeaders()});
  }

  loadWatchlistEndorsements(userId: number) {
    return this.servicesCommon.getAll<{
      watchListItem: number,
      endorsements: Map<number, LRPWatchListEndorsement[]>
    }>(`/private/lrp/base/watchlist/endorsements/user/${userId}`);
  }

  loadWatchlistQuotes(userId: number) {
    return this.servicesCommon.getAll<LRPWatchListQuote>(`/private/lrp/base/watchlist/user/${userId}`);
  }

  updateDailyReportSubscription(request: LRPRequest[]) {
    let url = this.servicesCommon.getUrl(`/private/lrp/base/reports/daily/subscribe`);
    return this.http.post<UserDetail[]>(url, request, {headers: this.servicesCommon.getAuthHeaders()});
  }
}
