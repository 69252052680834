export interface PRFRequest {
  year?: string;
  associationIds?: string[];
  primaryName?: string;
  company: 'hargrove' | 'aap';
}

export class ClaimStatusResponse {
  policyKey;
  policyKeyLabel;
  countyLabel;
  county: {
    county: string,
    countyCode: string
    countyState: string
    countyStateCode: string
  };
  totalPremium: number;
  totalClaims: number;
  intClaim: number[];
  isPredicted: boolean[];
  gridNumber;
  coverageType;
  producerPrimaryName;
  producerFirstName;
  producerTaxId;
  totalRow: boolean = false;
  grandTotal: boolean = false;
  dataForGrids: GridDetailsForClaims[];
  gridIndexes: number[] = [];
  gridPredicted: boolean[] = [];
  associationId?: number;
}

export class GridDetailsForClaims {
  claimValues: number[] = [];
  finalIndexes: number[] = [];
  avgRainfallValues: number[] = [];
  actualRainfallValues: number[] = [];
  predicted: boolean[] = [];
  gridId: string;
  coverageType: string;
  coverageLevel: number;
  county: SimpleCounty;
  producerTaxId: string;
  producerPrimaryName: string;
  producerFirstName: string;
  policyKey;
  reinsuranceYear;
  policyKeyLabel;
  countyLabel;
  totalRow;
  totalRowPredicted = false;
  totalClaims;
  rainfallValues: any[] = [];
  associationId?: number;
}

export class SimpleCounty {
  county: string;
  countyCode: string | number;
  countyState: string;
  countyStateCode: string | number;
  coverageType;
}

export class PremiumAndCoverageResponse {
  policyKey;
  policyDescription;
  policyGuarantee;
  policyPremium;
  policyAcres;
  coverageType;
  coverageLevel;
  companyCode: string;
  countyDescription;
  policyNumber;
  county;
  gridPremium;
  gridGuarantee;
  gridNumber;
  gridAcres;
  grazingLandAcres;
  hayLandAcres;
  producer: Producer;
  guaranteeDataArray: any[];
  year;
  premiumDataArray: any[];
  policyKeyLabel;
  countyLabel;
  totalRow: boolean;
  associationId?: number;
}

export class Producer {
  firstName: string;
  primaryName: string;
  taxId: string;
  associationId?: number;
  internalId: number;
}

export class PremiumSummary {
  guaranteeSummary: number = 0;
  claimSummary: number = 0;
  premiumSummary: number = 0;
  county: string = '';
  coverageType: string = '';
  policyKey: string = '';
  companyCode: string;
  profit: number = 0;
  relatedResponses: PremiumAndCoverageResponse[] = [];
  producer: Producer;
  year: string = '';
  acres: number;
  hayLandAcres: number;
  grazingLandAcres: number;
}

export class PRFAssociationRequest {
  primaryName: string;
  firstName: string;
  taxId: string;
  userId: number;
  company: string;
  internalId?: number;
}


export class AFRequest {
  year?: string;
  associationIds?: string[];
  primaryName?: string;
  growingSeason?: string;
}

export class AnnualForageResponse {
  actualRainfall: number[] = [];
  avgRainfallValues: number[] = [];
  claims: number[] | string[] = [];
  companyCode: string;
  county: SimpleCounty;
  coverageLevel: number;
  coverageLevels: number[] = [];
  coverages: number[] = [];
  dualUnit: boolean[] = [];
  finalIndexes: number[] = [];
  gridNumber: string;
  growingSeasons: number[] = [];
  insuredAcreage: number[] = [];
  percentOfCoverage: number[] = [];
  policyKey: string | number;
  policyNumber: string;
  predicted: boolean[] = [];
  premiums: number[] = [];
  producer: Producer;
  rainfallValues: any[] = [];
  rainfallValuesForGS: any[][] = [[]];
  year: string;
  years: number[] = [];

  claimsForGS: any[];
  coverageKeysForGS: any[];
  coverageLevelsForGS: any[];
  coveragesForGS: any[];
  dualUnitForGS: any[];
  insuredAcreageForGS: any[];
  percentOfCoverageForGS: any[];
  predictedForGS: any[];
  premiumLineKeysForGS: any[];
  premiumsForGS: any[];
  yearsForGS: any[];
  avgRainfallValuesForGS: any[];
  finalIndexesForGS: any[];
  actualRainfallForGS: any[];
}
