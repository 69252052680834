import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { catchError, filter, map, mergeMap, switchMap } from "rxjs/operators";

import {
  AuthActionTypes,
  CurrentState,
  LoadRanchesForUserSuccessAction,
  LoadUserAction,
  LoadUserFailAction,
  LoadUserSuccessAction,
  LoginSuccessAction, LogoutSuccessAction,
  SetLatestPRFYearAction,
  SetSelectedRanchIdAction,
  UserActionTypes,
  RanchesActionTypes,
  UserService,
  LoginFailAction,
  CommonConstants,
  CreateErrorMessage,
  SetSelectedAssociationIdsAction
} from "common-lib";
import * as uiActions from "../ui/ui.actions";
import { PortalBaseEffects } from "../portalBaseEffects";
import { PortalAppState } from "../reducer";
import { ActionTypes, CheckSessionAction } from "./portal-user.actions";
import { PortalUserService } from "../../services/portal-user.service";
import { Router } from '@angular/router';

@Injectable()
export class PortalUserEffects extends PortalBaseEffects {
  @Effect()
  loginSuccess: Observable<Action>;

  @Effect()
  loadUserDetails: Observable<Action>;

  @Effect()
  checkSession: Observable<Action>;

  @Effect()
  initEnabledPages: Observable<Action>;

  @Effect()
  selectDefaultRanch: Observable<Action>;

  @Effect()
  loginFailed: Observable<Action>;

  constructor(
    protected currentState: CurrentState<PortalAppState>,
    private actions: Actions,
    private portalUserService: PortalUserService,
    private userService: UserService,
    private router: Router
  ) {
    super(currentState);

    this.loginSuccess = this.actions.pipe(ofType(AuthActionTypes.LOGIN_SUCCESS)
      , map((successAction: any) => {
        this.router.navigateByUrl('/home');
        return new LoadUserAction((successAction.payload as any).userId);
      }));

    this.initEnabledPages = this.actions.pipe(ofType(ActionTypes.INIT_ENABLED_PAGES, AuthActionTypes.LOGOUT_SUCCESS)
      , map((successAction: any) => {
        const pageItems = this.filterEntryPages(null);
        return new uiActions.SetAvailableEntryPagesAction(pageItems);
      }));

    this.checkSession = this.actions.pipe(ofType(ActionTypes.CHECK_SESSION)
      , map((action: CheckSessionAction) => {
        return action;
      })
      , switchMap(() => {
        return this.portalUserService.checkSession().pipe(map((sessionInfo: any) => {
          return new LoginSuccessAction(sessionInfo);
        }), catchError(error => {
          console.error('Error during checkSession');
          return new Observable<Action>((observer) => {
            observer.next(new LogoutSuccessAction());
            observer.complete()
          });
        }))
      }));

    this.loadUserDetails = this.actions.pipe(
      ofType(
        UserActionTypes.LOAD_USER,
        UserActionTypes.UPDATE_USER_SUCCESS,
        UserActionTypes.UPDATE_PASSWORD_SUCCESS,
      )
      , map((loginAction: any) => {
        return loginAction.payload;
      })
      , switchMap((userId: number) => {
          const userDetailsObs = this.getUserDetails(userId)
            .pipe(map((loginResponse: any) => {
              const enabledPageItems = this.filterEntryPages(loginResponse);
              return ({userDetails: loginResponse, enabledPages: enabledPageItems})
            }));
          return userDetailsObs.pipe(mergeMap((value: any) => {
            let actions = [];
            actions.push(new uiActions.SetAvailableEntryPagesAction(value.enabledPages));
            actions.push(new LoadUserSuccessAction(value.userDetails));
            const prfAssoc = value.userDetails.prfAssociations;
            if (prfAssoc && prfAssoc.length == 1) {
              const associationId = prfAssoc[0].id;
              //FIXME szabi, 2024.nov.24 handle this properly when PRF is added to portal
              actions.push(new SetSelectedAssociationIdsAction([associationId]));
            }
            const ranchesOfUser = value.userDetails.ranchesOfUser;
            if (ranchesOfUser && ranchesOfUser.length > 0) {
              actions.push(new LoadRanchesForUserSuccessAction(ranchesOfUser));
              delete value.userDetails.ranchesOfUser;
            }
            const latestPRFYear = value.userDetails.latestPRFYear;
            if (latestPRFYear && latestPRFYear.length > 0) {
              actions.push(new SetLatestPRFYearAction(latestPRFYear));
              delete value.userDetails.latestPRFYear;
            }
            return actions;
          }), catchError(error => {
            console.error(error);
            return new Observable<Action>((observer) => {
              observer.next(new LoadUserFailAction(error));
              // observer.next(new errorActions.CreateErrorMessage(errorEntry));
              observer.complete()
            });
          }));
        }
      ));


    this.selectDefaultRanch = this.actions.pipe(ofType(RanchesActionTypes.LOAD_RANCHES_FOR_USER_SUCCESS),
      switchMap((userAction: LoadRanchesForUserSuccessAction) => {
        //TODO storage
        const ownedRanches = this.currentState.snapshot.ranches.ranchesOfUser.filter(r => r.manager).map(rou => rou.ranch);
        const memberedRanches = this.currentState.snapshot.ranches.ranchesOfUser.filter(r => !r.manager).map(rou => rou.ranch);
        const subscriptions = this.currentState.snapshot.user.userDetails.moduleSubscriptions;
        let selectedRanch = null;

        if (ownedRanches?.length > 0) {
          ownedRanches.map(r => r.id).forEach(id => {
            if (!selectedRanch) {
              selectedRanch = id;
            }
            if (!!subscriptions[id]) {
              selectedRanch = id;
            }
          })
        }
        if (!selectedRanch && memberedRanches?.length > 0) {
          memberedRanches.map(r => r.id).forEach(id => {
            if (!selectedRanch) {
              selectedRanch = id;
            }
            if (!!subscriptions[id]) {
              selectedRanch = id;
            }
          })
        }
        return of(new SetSelectedRanchIdAction(selectedRanch));
    }));

    this.loginFailed = this.actions.pipe(ofType(AuthActionTypes.LOGIN_FAIL)
      , filter((action: LoginFailAction) => {
        const noConnectionErrorCode = CommonConstants.SERVICE_ERRORS.AUTH.NO_CONNECTION;
        const wrongCredsError = action.errorCode != noConnectionErrorCode;
        return wrongCredsError;
      }), map((action: LoginFailAction) => {
        if (action.payload.error && action.payload.error.error) {
          return action.payload.error.error;
        }
        return action.payload.error;
      })
      , switchMap((loginError: any) => {
        return of(new CreateErrorMessage(loginError));
      }));

  }


  getUserDetails(userId: number) {
    return this.userService.getUserDetails(userId);
  }

  registerUser(userData) {
    return this.userService.registerUser(userData);
  }
}


