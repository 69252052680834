import { Inject, Injectable } from '@angular/core';
import { UserLogin } from "../../../../pastureProFECommon/projects/common-lib/src/lib/model";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CurrentState, ENVIRONMENT_TOKEN } from "common-lib";
import { PortalAppState } from "../store/reducer";
import { Store } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class PortalUserService {

  constructor(@Inject(ENVIRONMENT_TOKEN) private readonly environment, protected http: HttpClient, private currentState: CurrentState<PortalAppState>, private store: Store<PortalAppState>) {
  }

  public isLoggedInUser(): Observable<boolean> {
    if (this.currentState.snapshot.user.isLoggedIn) {
      return this.store.select(state => state.user.isLoggedIn);
    } else {
      return this.checkSession().pipe(map(sessionInfo => {
        return !!(sessionInfo && (sessionInfo as any).userId && (sessionInfo as any).token);
      }), catchError(error => {
        return of(false);
      }))
    }
  }

  public checkSession() {
    const path = '/public/checksession';
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    const url = this.environment.backend + path;
    const options = {
      headers: headers,
      withCredentials: true
    };
    // this.ga.trackServiceCall('loginUser', CommonConstants.GA.LABEL.USER_CRUD);
    return this.http.get(url, options);
  }
}
