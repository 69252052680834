import { Action } from '@ngrx/store';
import { type, ActionWithPayload } from '../types';
import * as eq from '../../model/Equipments';


export const equipmentActionTypes = {
  LOAD_SUBSCRIPTION_TYPES: type('[app] [Equipments] Load Subscription Types'),
  LOAD_SUBSCRIPTION_TYPES_FAILURE: type('[app] [Equipments] Load Subscription Types Failure'),
  LOAD_SUBSCRIPTION_TYPES_SUCCESS: type('[app] [Equipments] Load Subscription Types Success'),
  LOAD_SUBSCRIPTION_PACKAGES: type('[app] [Equipments] Load Subscription Packages'),
  LOAD_SUBSCRIPTION_PACKAGES_SUCCESS: type('[app] [Equipments] Load Subscription Packages Success'),
  LOAD_SUBSCRIPTION_PACKAGES_FAILURE: type('[app] [Equipments] Load Subscription Packages Failure'),
  LOAD_CELL_PLANS: type('[app] [Equipments] Load Cell Plans'),
  LOAD_CELL_PLANS_SUCCESS: type('[app] [Equipments] Load Cell Plans Success'),
  LOAD_CELL_PLANS_FAILURE: type('[app] [Equipments] Load Cell Plans Failure'),
  LOAD_CELL_PROVIDERS: type('[app] [Equipments] Load Cell Providers'),
  LOAD_CELL_PROVIDERS_SUCCESS: type('[app] [Equipments] Load Cell Providers Success'),
  LOAD_CELL_PROVIDERS_FAILURE: type('[app] [Equipments] Load Cell Providers Failure'),
  LOAD_EQUIPMENT_TYPES: type('[app] [Equipments] Load Equipment Types'),
  LOAD_EQUIPMENT_TYPES_SUCCESS: type('[app] [Equipments] Load Equipment Types Success'),
  LOAD_EQUIPMENT_TYPES_FAILURE: type('[app] [Equipments] Load Equipment Types Failure'),
  ADD_SUBSCRIPTION: type('[app] [Equipments] Add Subscription'),
  ADD_SUBSCRIPTION_SUCCESS: type('[app] [Equipments] Add Subscription Success'),
  ADD_SUBSCRIPTION_FAILURE: type('[app] [Equipments] Add Subscription Failure'),
  ADD_EQUIPMENT: type('[app] [Equipments] Add Equipment'),
  ADD_EQUIPMENT_SUCCESS: type('[app] [Equipments] Add Equipment Success'),
  ADD_EQUIPMENT_FAILURE: type('[app] [Equipments] Add Equipment Failure'),
  LOAD_USER_EQUIPMENTS: type('[app] [Equipments] Load User Equipments'),
  LOAD_USER_EQUIPMENTS_SUCCESS: type('[app] [Equipments] Load User Equipments Success'),
  LOAD_USER_EQUIPMENTS_FAILURE: type('[app] [Equipments] Load User Equipments Failure'),
  LOAD_USER_SUBSCRIPTIONS: type('[app] [Equipments] LoadUserSubscriptions'),
  LOAD_USER_SUBSCRIPTIONS_SUCCESS: type('[app] [Equipments] Load User Subscriptions Success'),
  LOAD_USER_SUBSCRIPTIONS_FAILURE: type('[app] [Equipments] Load User Subscriptions Failure'),
  DELETE_USER_EQUIPMENT: type('[app] [Equipments] Delete User Equipment'),
  DELETE_USER_EQUIPMENT_SUCCESS: type('[app] [Equipments] Delete User Equipment Success'),
  DELETE_USER_EQUIPMENT_FAILURE: type('[app] [Equipments] Delete User Equipment Failure'),
  DELETE_USER_ALL_EQUIPMENTS_SUCCESS: type('[app] [Equipments] Delete All User Equipment Success'),
  DELETE_USER_ALL_EQUIPMENTS_FAILURE: type('[app] [Equipments] Delete All User Equipment Failure'),
  DELETE_USER_SUBSCRIPTION: type('[app] [Equipments] Delete User Subscription'),
  DELETE_USER_SUBSCRIPTION_SUCCESS: type('[app] [Equipments] Delete User Subscription Success'),
  DELETE_USER_SUBSCRIPTION_FAILURE: type('[app] [Equipments] Delete User Subscription Failure'),
  SELECT_ASSET_MONITOR_EQUIPMENTS: type('[app] [Equipments] Select equipment'),
  SELECT_ASSET_MONITOR_SUBSCRIPTION: type('[app] [Equipments] Select subscription'),
  RENAME_CAMERA_SUCCESS_ACTION: type('[app] [Equipments] Rename camera success'),
  RENAME_CAMERA_FAIL_ACTION: type('[app] [Equipments] Rename camera failed'),

};


export class DeleteUserSubscriptionAction implements Action {
  readonly type = equipmentActionTypes.DELETE_USER_SUBSCRIPTION;

  constructor(public payload: { userId: number, subscriptionId: number }) {
  }
}

export class DeleteUserSubscriptionSuccessAction implements Action {
  readonly type = equipmentActionTypes.DELETE_USER_SUBSCRIPTION_SUCCESS;

  constructor(public payload: { subscriptionId: number }) {
  }
}

export class DeleteUserSubscriptionFailureAction implements Action {
  readonly type = equipmentActionTypes.DELETE_USER_SUBSCRIPTION_FAILURE;
}

export class DeleteUserEquipmentAction implements Action {
  readonly type = equipmentActionTypes.DELETE_USER_EQUIPMENT;

  constructor(public payload: { userId: number, equipmentId: number }) {
  }
}

export class DeleteUserEquipmentSuccessAction implements Action {
  readonly type = equipmentActionTypes.DELETE_USER_EQUIPMENT_SUCCESS;

  constructor(public payload: { equipmentId: number }) {
  }
}

export class DeleteUserEquipmentFailureAction implements Action {
  readonly type = equipmentActionTypes.DELETE_USER_EQUIPMENT_FAILURE;
}

export class DeleteAllUserEquipmentSuccessAction implements Action {
  readonly type = equipmentActionTypes.DELETE_USER_ALL_EQUIPMENTS_SUCCESS;

  constructor(public payload: { equipmentIds: number[] | string[] }) {
  }
}

export class DeleteAllUserEquipmentFailureAction implements Action {
  readonly type = equipmentActionTypes.DELETE_USER_ALL_EQUIPMENTS_FAILURE;
}


export class LoadUserSubscriptionsAction implements Action {
  readonly type = equipmentActionTypes.LOAD_USER_SUBSCRIPTIONS;

  constructor(public payload: { userId: number }) {
  }
}

export class LoadUserSubscriptionsSuccessAction implements Action {
  readonly type = equipmentActionTypes.LOAD_USER_SUBSCRIPTIONS_SUCCESS;

  constructor(public payload: eq.SubscriptionDto[]) {
  }
}

export class LoadUserSubscriptionsFailureAction implements Action {
  readonly type = equipmentActionTypes.LOAD_USER_SUBSCRIPTIONS_FAILURE;

  constructor(public payload: string) {
  }
}

export class LoadUserEquipmentsAction implements Action {
  readonly type = equipmentActionTypes.LOAD_USER_EQUIPMENTS;

  constructor(public payload: { userId: string }) {
  }
}

export class LoadUserEquipmentsSuccessAction implements Action {
  readonly type = equipmentActionTypes.LOAD_USER_EQUIPMENTS_SUCCESS;

  constructor(public payload: eq.EquipmentDto[]) {
  }
}

export class LoadUserEquipmentsFailureAction implements Action {
  readonly type = equipmentActionTypes.LOAD_USER_EQUIPMENTS_FAILURE;

  constructor(public payload: string) {
  }
}


export class LoadEquipmentTypesAction implements Action {
  readonly type = equipmentActionTypes.LOAD_EQUIPMENT_TYPES;
}

export class LoadEquipmentTypesSuccessAction implements Action {
  readonly type = equipmentActionTypes.LOAD_EQUIPMENT_TYPES_SUCCESS;

  constructor(public payload: eq.EquipmentType[]) {
  }
}

export class LoadEquipmentTypesFailureAction implements Action {
  readonly type = equipmentActionTypes.LOAD_EQUIPMENT_TYPES_FAILURE;

  constructor(public payload: string) {
  }
}

export class LoadCellProvidersAction implements Action {
  readonly type = equipmentActionTypes.LOAD_CELL_PROVIDERS;
}

export class LoadCellProvidersSuccessAction implements Action {
  readonly type = equipmentActionTypes.LOAD_CELL_PROVIDERS_SUCCESS;

  constructor(public payload: eq.CellProvider[]) {
  }
}

export class LoadCellProvidersFailureAction implements Action {
  readonly type = equipmentActionTypes.LOAD_CELL_PROVIDERS_FAILURE;

  constructor(public payload: string) {
  }
}

export class LoadCellPlansAction implements Action {
  readonly type = equipmentActionTypes.LOAD_CELL_PLANS;
}

export class LoadCellPlansSuccessAction implements Action {
  readonly type = equipmentActionTypes.LOAD_CELL_PLANS_SUCCESS;

  constructor(public payload: eq.CellPlan[]) {
  }
}

export class LoadCellPlansFailureAction implements Action {
  readonly type = equipmentActionTypes.LOAD_CELL_PLANS_FAILURE;

  constructor(public payload: string) {
  }
}

export class LoadSubscriptionPackagesAction implements Action {
  readonly type = equipmentActionTypes.LOAD_SUBSCRIPTION_PACKAGES;
}

export class LoadSubscriptionPackagesSuccessAction implements Action {
  readonly type = equipmentActionTypes.LOAD_SUBSCRIPTION_PACKAGES_SUCCESS;

  constructor(public payload: eq.SubscriptionPackage[]) {
  }
}

export class LoadSubscriptionPackagesFailureAction implements Action {
  readonly type = equipmentActionTypes.LOAD_SUBSCRIPTION_PACKAGES_FAILURE;

  constructor(public payload: string) {
  }
}

export class LoadSubscriptionTypesAction implements Action {
  readonly type = equipmentActionTypes.LOAD_SUBSCRIPTION_TYPES;
}

export class LoadSubscriptionTypesSuccessAction implements Action {
  readonly type = equipmentActionTypes.LOAD_SUBSCRIPTION_TYPES_SUCCESS;

  constructor(public payload: eq.SubscriptionPackage[]) {
  }
}

export class LoadSubscriptionTypesFailureAction implements Action {
  readonly type = equipmentActionTypes.LOAD_SUBSCRIPTION_TYPES_FAILURE;

  constructor(public payload: string) {
  }
}

export class AddSubscriptionAction implements Action {
  readonly type = equipmentActionTypes.ADD_SUBSCRIPTION;

  constructor(public payload: {
    subscriptionPackageId: number,
    subscriptionTypeId: number,
    userId: number,
    activationDate: string
  }) {
  }
}

export class AddSubscriptionSuccessAction implements Action {
  readonly type = equipmentActionTypes.ADD_SUBSCRIPTION_SUCCESS;

  constructor(public payload: { subscription: eq.SubscriptionDto }) {
  }
}

export class AddSubscriptionFailureAction implements Action {
  readonly type = equipmentActionTypes.ADD_SUBSCRIPTION_FAILURE;

  constructor(public payload: any) {
  }
}

export class AddEquipmentAction implements Action {
  readonly type = equipmentActionTypes.ADD_EQUIPMENT;

  constructor(public payload: { equipment: eq.EquipmentDto, userId: number }) {
  }
}

export class AddEquipmentSuccessAction implements Action {
  readonly type = equipmentActionTypes.ADD_EQUIPMENT_SUCCESS;

  constructor(public payload: { equipment: eq.EquipmentDto }) {
  }
}

export class AddEquipmentFailureAction implements Action {
  readonly type = equipmentActionTypes.ADD_EQUIPMENT_FAILURE;

  constructor(public payload: any) {
  }
}

export class SelectAssetMonitorEquipments implements Action {
  readonly type = equipmentActionTypes.SELECT_ASSET_MONITOR_EQUIPMENTS;

  constructor(public payload: number[] | string[]) {
  }
}

export class SelectAssetMonitorSubscription implements Action {
  readonly type = equipmentActionTypes.SELECT_ASSET_MONITOR_SUBSCRIPTION;

  constructor(public payload: number | string) {
  }
}

export class RenameCameraSuccessAction implements Action {
  readonly type = equipmentActionTypes.RENAME_CAMERA_SUCCESS_ACTION;

  constructor(public payload: { equipmentId: any, newTitle: any }) {
  }
}

export class RenameCameraFailedAction implements Action {
  readonly type = equipmentActionTypes.RENAME_CAMERA_FAIL_ACTION;

  constructor(public payload: { equipmentId: any, newTitle: any }) {
  }
}

export type EquipmentActions =
  LoadSubscriptionTypesAction |
  LoadSubscriptionTypesSuccessAction |
  LoadSubscriptionTypesFailureAction |
  LoadSubscriptionPackagesAction |
  LoadSubscriptionPackagesSuccessAction |
  LoadSubscriptionPackagesFailureAction |
  LoadCellPlansAction |
  LoadCellPlansSuccessAction |
  LoadCellPlansFailureAction |
  LoadCellProvidersAction |
  LoadCellProvidersSuccessAction |
  LoadCellProvidersFailureAction |
  LoadEquipmentTypesAction |
  LoadEquipmentTypesSuccessAction |
  LoadEquipmentTypesFailureAction |
  AddSubscriptionAction |
  AddSubscriptionFailureAction |
  AddSubscriptionSuccessAction |
  AddEquipmentAction |
  AddEquipmentSuccessAction |
  AddEquipmentFailureAction |
  LoadUserEquipmentsAction |
  LoadUserEquipmentsFailureAction |
  LoadUserEquipmentsSuccessAction |
  LoadUserSubscriptionsAction |
  LoadUserSubscriptionsSuccessAction |
  DeleteUserEquipmentAction |
  DeleteUserEquipmentSuccessAction |
  DeleteUserEquipmentFailureAction |
  DeleteUserSubscriptionAction |
  DeleteUserSubscriptionSuccessAction |
  SelectAssetMonitorEquipments |
  SelectAssetMonitorSubscription |
  DeleteUserSubscriptionFailureAction |
  RenameCameraFailedAction |
  RenameCameraSuccessAction |
  DeleteAllUserEquipmentSuccessAction |
  DeleteAllUserEquipmentFailureAction;




