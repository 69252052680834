import { NgModule } from '@angular/core';
import { FeService } from "./common-fe.service";
import { GaugesService } from "./services/gauges.service";
import { UserService } from "./services/user.service";
import { RanchService } from "./services/ranch.service";
import { AdminService } from "./services/admin.service";
import { TasksService } from "./services/tasks.service";
import { PrfService } from "./services/prf.service";
import { EmailService } from "./services/email.service";
import { EquipmentService } from './services/equipments.service';
import { ImagesService } from './services/images.service';
import { ServicesCommon } from './services/services-common.service';
import { MappingsService } from "./services/mappings.service";
import { LivestockService } from './services/livestock.service';
import { FinanceService } from './services/finance.service';
import { ClimateService } from './services/climate.service';
import { LRPService } from "./services/lrp.service";
import { ServicesPRFCommon } from "./services/services-prf-common.service";
import { GeneralReportService } from "./services/general-report.service";

@NgModule({
  declarations: [],
  imports: [],
  providers: [
    FeService,
    GaugesService,
    UserService,
    RanchService,
    PrfService,
    EmailService,
    TasksService,
    AdminService,
    EquipmentService,
    ImagesService,
    ServicesCommon,
    ServicesPRFCommon,
    MappingsService,
    LivestockService,
    LRPService,
    FinanceService,
    ClimateService,
    GeneralReportService,
  ],
  exports: []
})
export class CommonFeModule {
}
