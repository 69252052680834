import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { RanchService, UserService } from "common-lib";
import { PortalAppState } from 'app/store/reducer';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-existing-users-search',
  templateUrl: './existing-users-search.page.html'
})
export class ExistingUsersSearchPage {


  userCredential: any;
  userCredentialValid: boolean;
  private userSearchObs: Observable<any>;
  noUserFound;
  ranchManager: any;

  constructor(

    protected router: Router,
    protected store: Store<PortalAppState>,
    public translateService: TranslateService,
    private userService: UserService,
    public dialogRef: MatDialogRef<ExistingUsersSearchPage>,
  ) {
  
  }

  validateSearchInput() {
    if (!this.userCredential || this.userCredential.length < 2) {
      this.userCredentialValid = false;
    } else {
      this.userCredentialValid = true;
    }
  }

  searchUsers() {
    this.userSearchObs = this.userService.searchUsers(this.userCredential);
    this.userSearchObs.subscribe(users => {
      console.log(users);
      if (!users || users.length < 1) {
        console.log('No user found');
        this.noUserFound = true;
      } else {
        this.noUserFound = false
      }
    });
  }

  userSelected(user: any) {
    const response = {
      userId: user.id,
      ranchManager: !!this.ranchManager
    };
    this.dialogRef.close(response)
  }

  toggleRanchManager() {
    this.ranchManager = !this.ranchManager;
  }

  onAddNewUserClicked() {
    // this.modalCtrl.dismiss({addNewUser: true});
  }

  getPageName() {
    return 'ExistingUsersSearchPage';
  }
}
