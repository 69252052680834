import { Action } from '@ngrx/store';
import { type } from "../types";
import { EntityWithImage } from "../../model";


export const AttachmentActionTypes = {
  SET_ATTACHMENTS: type('[net] [Attachment] Set attachments'),
  UPDATE_ATTACHMENTS: type('[net] [Attachment] Update attachments'),
  SET_SELECTED_ATTACHMENT: type('[net] [Attachment] Set selected attachment'),
  RESET_ATTACHMENTS: type('[net] [Attachment] Reset attachments'),
  UPDATE_ATTACHMENT: type('[net] [Attachment] Update attachment'),
  UPLOAD_ATTACHMENT_SUCCESS: type('[net] [Attachment] Upload attachment success'),
  DELETE_ATTACHMENT_SUCCESS: type('[app] [General] Delete attachment success'),

};

export class SetAttachmentsAction implements Action {
  type = AttachmentActionTypes.SET_ATTACHMENTS;

  constructor(public readonly payload: {
    attachments: EntityWithImage[],
    type: ('task' | 'marker' | 'item' | 'profit_loss' | 'animal'),
    id: number
  }) {
  }
}

export class ResetAttachmentsAction implements Action {
  type = AttachmentActionTypes.RESET_ATTACHMENTS;

  constructor(public readonly payload: any) {
  }
}

export class UpdateAttachmentsAction implements Action {
  type = AttachmentActionTypes.UPDATE_ATTACHMENTS;

  constructor(public readonly payload: EntityWithImage[]) {
  }
}


export class UpdateAttachmentAction implements Action {
  type = AttachmentActionTypes.UPDATE_ATTACHMENT;

  constructor(public readonly payload: EntityWithImage) {
  }
}


export class SetSelectedAttachmentAction implements Action {
  type = AttachmentActionTypes.SET_SELECTED_ATTACHMENT;

  constructor(public readonly payload: EntityWithImage) {
  }
}
export class UploadAttachmentSuccessAction implements Action {
  type = AttachmentActionTypes.UPLOAD_ATTACHMENT_SUCCESS;

  constructor(public readonly payload: EntityWithImage) {
  }
}
export class DeleteAttachmentSuccessAction implements Action {
  type = AttachmentActionTypes.DELETE_ATTACHMENT_SUCCESS;

  constructor(public payload: { attachmentId: number, type: 'task' | 'marker' | 'item' | 'profit_loss' | 'animal', moduleEntityId: number }) {
  }
}


export declare type AttachmentActions =
  SetAttachmentsAction
  | UpdateAttachmentsAction
  | SetSelectedAttachmentAction
  | ResetAttachmentsAction
  | UpdateAttachmentAction
  | UploadAttachmentSuccessAction
  | DeleteAttachmentSuccessAction;


