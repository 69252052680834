import { Actions, Effect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { Action, Store } from "@ngrx/store";
import { CommonState } from "../reducer";
import { CommonFeBaseEffects } from "../common-fe-base.effects";
import { TranslateService } from "@ngx-translate/core";
import { CurrentState } from "../current-state";

import { NotificationsService } from "../../services/notifications.service";
import { catchError, map, switchMap } from "rxjs/operators";
import * as eqActions from "../equipments/equipments.actions";
import { Observable } from "rxjs";
import { equipmentActionTypes } from "../equipments/equipments.actions";
import {
  ClimateSmartActionTypes,
  LoadClimateSmartSurveyQuestion,
  SetClimateSmartSurveyQuestion
} from "./climate_smart.actions";
import { ClimateService } from "../../services/climate.service";

@Injectable()
export class ClimateSmartEffects extends CommonFeBaseEffects {

  @Effect()
  loadSurveyQuestions: Observable<Action>;

  constructor(
    private translateService: TranslateService,
    private store: Store<CommonState>,
    protected currentState: CurrentState<CommonState>,
    private climateService: ClimateService,
    private actions: Actions, private notificationService: NotificationsService) {
    super();

    this.loadSurveyQuestions = this.actions.pipe(
      ofType(ClimateSmartActionTypes.LOAD_SURVEY_QUESTION),
      map((action: LoadClimateSmartSurveyQuestion) => action.payload.userId),
      switchMap((payload: number) => this.climateService.getSurveyQuestions(payload).pipe(
        map(questions => new SetClimateSmartSurveyQuestion(questions)),
        catchError(this.handleHttpError)
      )),
    );
  }
}
