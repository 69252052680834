<h1 mat-dialog-title>{{data.header}}</h1>

<div mat-dialog-content>
    <div *ngIf="data.content" [innerHTML]="data.content"></div>
    
    <ng-container *ngIf="data.fileInput">
        <mat-form-field>
            <mat-label>File</mat-label>
            <button mat-icon-button matPrefix (click)="f_input.click()">
              <mat-icon>attach_file</mat-icon>
            </button>
            <!--[value]="f_input?.files[0]"-->
            <input type="text" readonly matInput [value]="file?.name" (click)="f_input.click()">
            <input type="file" hidden #f_input (change)="fileChanged(f_input.files)">
          </mat-form-field>
    </ng-container>
    <ng-container *ngIf="data.textInput?.length">
      <mat-form-field *ngFor="let input of data.textInput; let index=index;">
        <mat-label>{{input}}</mat-label>
        <input type="text" matInput [(ngModel)]="text[index]">
      </mat-form-field>
    </ng-container>
    <ng-container *ngIf="!!data.select?.length">
      <mat-form-field>
        <mat-label>{{data.label}}</mat-label>
        <mat-select [(ngModel)]="select">
          <mat-option *ngFor="let s of data.select" [value]="s.value">{{ s.label }}</mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>

    <ng-container *ngIf="!!data.checkbox">
      <p><mat-checkbox color="primary" [(ngModel)]="checked">{{data.checkbox}}</mat-checkbox></p>
    </ng-container>
</div>

<div mat-dialog-actions>
    <button mat-button [mat-dialog-close]="null">{{data.secondaryBtnLabel}}</button>

    <button *ngIf="!data.fileInput && !data.textInput && !data.select && !data.checkbox" mat-flat-button color="primary" [mat-dialog-close]="true">{{data.primaryBtnLabel}}</button>

    <button *ngIf="data.fileInput" [disabled]="!file" mat-flat-button color="primary" [mat-dialog-close]="file">{{data.primaryBtnLabel}}</button>
    <button *ngIf="data.textInput" [disabled]="!text" mat-flat-button color="primary" [mat-dialog-close]="text">{{data.primaryBtnLabel}}</button>
    <button *ngIf="data.select" [disabled]="!select" mat-flat-button color="primary" [mat-dialog-close]="select">{{data.primaryBtnLabel}}</button>
    <button *ngIf="data.checkbox" mat-flat-button color="primary" [mat-dialog-close]="checked">{{data.primaryBtnLabel}}</button>
</div>