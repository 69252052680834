import { Action } from '@ngrx/store';
import { DevState } from './dev.reducer';
import { type } from '../types';


export const DevActionTypes = {
  SET_DEV_SETTINGS: type('[DEV] [User] Set dev settings'),
  DEV_GENERIC_SUCCESS_ACTION: type('[DEV] [User] Dev generic success action'),
  DEV_GENERIC_FAIL_ACTION: type('[DEV] [User] Dev generic failed action'),
  SWITCH_CONNECTION: type('[DEV] [User] Switch connection'),
};

export class SetDevSettinsAction implements Action {
  type = DevActionTypes.SET_DEV_SETTINGS;

  constructor(public payload: DevState) {
  }
}

export class SwitchConnectionAction implements Action {
  type = DevActionTypes.SWITCH_CONNECTION;

  constructor() {
  }
}


export class DevGenericSuccessAction implements Action {
  type = DevActionTypes.DEV_GENERIC_SUCCESS_ACTION;

  constructor() {
  }
}
export class DevGenericFailedAction implements Action {
  type = DevActionTypes.DEV_GENERIC_FAIL_ACTION;

  constructor(public payload: any) {
  }
}

export declare type DevActions =
  SetDevSettinsAction | SwitchConnectionAction | DevGenericSuccessAction | DevGenericFailedAction;


