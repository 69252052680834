export const ApplicationConstants = {

  VERSION: '5.0.0',
  GA: {
    CATEGORY: {
      SERVICE_CALL: 'Service call',
      USER_INTERACTION: 'User interaction',
    },
    LABEL: {
      GAUGE: 'Gauge',
      USER_CRUD: 'User CRUD',
      PRF: 'PRF',
      MENU: 'Menu',
      SUPPORT: 'Support',
      RANCHES: 'Ranches',
      TASKS: 'Tasks'
    }
  },
  HTTP_TIMEOUT_MS: 90000,
  defaultLanguage: 'en',
  emailRegexp: new RegExp("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$"),
  phoneRegexp: new RegExp("^[ 0-9-+()]*$"),
  dollarCurrencyRegexp: new RegExp("/\d(?=(\d{3})+\.)/g"),
  DTN_SERVICE_ERROR_KEYS: {
    businessError: 'ERROR.DTN_SERVICE_BUSINESS_ERROR',
    httpError: 'ERROR.DTN_SERVICE_ERROR',
  },
  
  ASSET_MONITOR: {
    CAMERA_EQUIPMENT_TYPE_IDS: [10, 20],
    REPEATER_EQUIPMENT_TYPE_IDS: [30],
    HOME_CAMERA_TYPE_ID: 10
  },

  GAUGE_MAPS: {
    markerStyle: {
      image: {
        anchor: [0.5, 0.5],
        anchorXUnits: 'fraction',
        anchorYUnits: 'pixels',
        scale: 0.2,
        opacity: 1,
        src: 'assets/imgs/marker_black.png',
      }
    },
    selectedMarkerStyle: {
      image: /** @type {olx.style.IconOptions} */ {
        anchor: [0.5, 0.5],
        anchorXUnits: 'fraction',
        anchorYUnits: 'pixels',
        opacity: 0.75,
        scale: 0.1,
        src: 'assets/imgs/marker_blue.png',
      }
    }
  },

  PRF_CONSTANTS: {
    interValKeys: [
      {id: 0, label: "LABEL.JANFEB"},
      {id: 1, label: "LABEL.FEBMAR"},
      {id: 2, label: "LABEL.MARAPR"},
      {id: 3, label: "LABEL.APRMAY"},
      {id: 4, label: "LABEL.MAYJUN"},
      {id: 5, label: "LABEL.JUNJUL"},
      {id: 6, label: "LABEL.JULAUG"},
      {id: 7, label: "LABEL.AUGSEP"},
      {id: 8, label: "LABEL.SEPOCT"},
      {id: 9, label: "LABEL.OCTNOV"},
      {id: 10, label: "LABEL.NOVDEC"},
    ],
    allInterval: {
      id: 11, label: "LABEL.ALL_INTERVAL"
    }
  },
  SYSTEM_FUNCTIONS: {
    herd_management_access: 'herd_management_access',
    prf_access: 'prf_access',
    adv_prf_access: 'adv_prf_access',
    users_admin_access: 'users_admin_access',
    admin_access: 'admin_access',
    rain_gauge_access: 'rain_gauges_access',
    mapping_access: 'mapping_access',
    recreation_access: 'recreation_access',
    tasks_access: 'tasks_access',
    weather_access: 'weather_access',
    marketplace_access: 'marketplace_access',
    user_management_access: 'user_management_access',
    settings_access: 'settings_access',
    water_monitoring_access: 'water_monitoring_access',
    general_manager: 'general_manager'

  },
  TASK_CONSTANTS: {
    defaultOrdering: {
      title: 'Date ' + getUpArrow(),
      id: 'due_date_asc',
    },
    orderings: [
      {
        title: 'Date ' + getUpArrow(),
        id: 'due_date_asc',
      },
      {
        title: 'Date ' + getDownArrow(),
        id: 'due_date_desc',

      },
      {
        title: 'Title ' + getUpArrow(),
        id: 'title_asc',
      },
      {
        title: 'Title ' + getDownArrow(),
        id: 'title_desc',
      }
    ]
  }

};

function getDownArrow() {
  return '&#8595';
}

function getUpArrow() {
  return '&#8593;';

}
