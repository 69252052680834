import { Inject, Injectable } from '@angular/core';
import { CurrentState } from "../store/current-state";
import { CommonState } from "../store/reducer";
import { HttpClient } from "@angular/common/http";
import { ServicesCommon } from "./services-common.service";
import { ENVIRONMENT_TOKEN, GOOGLE_ANALYTICS_TOKEN } from "../../environment/token-variables";
import { SetGPSTimeFramesAction, SetLatestPositionsAction } from "../store/gpseartags/gpseartags.actions";
import { Store } from "@ngrx/store";
import { GPSEarTag, GPSTimeFrame } from "../model";
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class GPSEarTagService {

  constructor(private currentState: CurrentState<CommonState>,
              protected http: HttpClient,
              private store: Store<CommonState>,
              private servicesCommon: ServicesCommon,
              @Inject(GOOGLE_ANALYTICS_TOKEN) private readonly ga,
              @Inject(ENVIRONMENT_TOKEN) private readonly environment) {
  }

  public loadLatestPositions(userId = null) {
    if (!userId) {
      userId = this.currentState.snapshot.user.id;
    }
    this.http.get<GPSEarTag[]>(
      this.environment.backend + `/private/gpseartags/user/${userId}/tags/positions/latest`,
      {headers: this.servicesCommon.getAuthHeaders()}).subscribe(r => this.store.dispatch(new SetLatestPositionsAction(r)));
  }

  public getLatestPositionOfTag(request: { userId: number | string, tagId: number | string }): Observable<GPSEarTag> {
    if (!request.userId) {
      request.userId = this.currentState.snapshot.user.id;
    }
    return this.http.get<GPSEarTag>(
      this.environment.backend + `/private/gpseartags/user/${request.userId}/tags/${request.tagId}/positions/latest`,
      {headers: this.servicesCommon.getAuthHeaders()});
  }

  public getTimeFrames() {
    return this.http.get<GPSTimeFrame[]>(
      this.environment.backend + `/private/gpseartags/timeframes`,
      {headers: this.servicesCommon.getAuthHeaders()}).subscribe(r => this.store.dispatch(new SetGPSTimeFramesAction(r)));
  }

  public getPositionHistoryOfTag(request: {tagId: number | string, timeFrame: number }): Observable<GPSEarTag[]> {
    return this.http.get<GPSEarTag[]>(
      this.environment.backend + `/private/gpseartags/tags/${request.tagId}/positions/timeframes/${request.timeFrame}/history`,
      {headers: this.servicesCommon.getAuthHeaders()});
  }

}
