import { Injectable } from "@angular/core";
import { formatDate } from '@angular/common';

@Injectable({
    providedIn: 'root'
})
export class DateService {

    readonly formats = {
        full: 'EEE, MM/dd/yyyy, hh:mm a',
        long: 'MM/dd/yyyy, hh:mm a',
        time: 'hh:mm a',
        dateString: 'yyyy-MM-dd',
        date: 'MMMM dd, yyyy',
        simpleDate: 'MM/dd/yyyy',
        short: 'MM/dd hh:mm a',
        day: 'EEE, MM/dd/yyyy'
      };

    public getToday() {
        let now = new Date();
        return this.getDateString(new Date(now.getTime() - (now.getTimezoneOffset() * 60000)));
    }

    public getDateString(date: Date) {
        return formatDate(date, this.formats.dateString, "en-US")
    }

    public getsimpleDate(date: Date) {
        return formatDate(date, this.formats.simpleDate, "en-US")
    }

    public getIsoDate(date = null) {
        if (!date) {
            return new Date().toISOString();
        }
        return new Date(date).toISOString();
    }

    // public getNow() {
    //     let now = new Date();
    //     return new Date(now.getTime() - (now.getTimezoneOffset() * 60000));
    // }

    fullDate(date: Date) {
        return formatDate(date, this.formats.full, "en-US");
    }

    getDateWithoutTimeZoneString(date): string {
        return date ? (date.split('T'))[0] : null;
    }


}

