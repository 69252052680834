import { Action } from '@ngrx/store';
import { Ranch, RanchOfUser, RanchUser } from "../../model";
import { ActionWithPayload } from "../types";
import { RanchesActionTypes } from "./ranches.actions";
import { UserActionTypes } from "../user";
import * as user from "../user/user.actions";

export interface RanchesState {
  ranchesOfUser: RanchOfUser[]
  selectedRanchId: number;
  userOfRanches: Map<number, RanchUser[]>;
}

export const ranchesInitialState: RanchesState = {
  ranchesOfUser: null,
  selectedRanchId: null,
  userOfRanches: new Map(),
};


export function ranchesReducer(state = ranchesInitialState, action: Action): RanchesState {
  switch (action.type) {
    case RanchesActionTypes.LOAD_RANCHES_FOR_USER_SUCCESS:
      let ranchesResult = (action as ActionWithPayload<any>).payload;
      if (!ranchesResult) {
        ranchesResult = [];
      }
      return {
        ...state,
        ranchesOfUser: ranchesResult,
      };
    case UserActionTypes.LOAD_USER_SUCCESS:
      const userDetails = (action as ActionWithPayload<any>).payload;
      const ranchesOfUserDetail = userDetails.ranchesOfUser;
      if (ranchesOfUserDetail && ranchesOfUserDetail.length > 0) {
        return {
          ...state,
          ranchesOfUser: ranchesOfUserDetail,
        };
      } else {
        return {
          ...state,
          ranchesOfUser: []
        }
      }
    case RanchesActionTypes.RESET_RANCHES:
      return {
        ...state,
        ranchesOfUser: null,
      };
    case RanchesActionTypes.SET_SELECTED_RANCH_ID:
      return {
        ...state,
        selectedRanchId: (action as ActionWithPayload<any>).payload
      };
    case RanchesActionTypes.LOAD_RANCH_USERS_SUCCESS:
      let newUsersForRanch = new Map();
      Array.from(state.userOfRanches.keys()).forEach(key => {
        newUsersForRanch.set(+key, state.userOfRanches.get(key));
      });
      ((action as ActionWithPayload<any>).payload as any).forEach(({users, ranchId}) => {
        newUsersForRanch.set(+ranchId, users);
      });
      return {
        ...state,
        userOfRanches: newUsersForRanch,
      };
    case RanchesActionTypes.SHARE_BOUNDARIES_WITH_RANCH_SUCCESS:
      const updatedRanch = (action as ActionWithPayload<any>).payload;
      let updatedRanches = state.ranchesOfUser.map(r => {
        if (r.ranch.id == updatedRanch.id) {
          return {
            manager: r.manager,
            ranch: updatedRanch,
          }
        }
        return r;
      });
      return {
        ...state,
        ranchesOfUser: updatedRanches,
      };
    case UserActionTypes.SET_OWNED_RANCH:
      if (state.selectedRanchId == null) {
        return {
          ...state,
          selectedRanchId: (action as ActionWithPayload<any>).payload?.id
        };
      }
      return state;
    default:
      return state;
  }
}
