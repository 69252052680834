import { Injectable } from '@angular/core';
import { Vector as VectorLayer } from 'ol/layer.js';
import { Vector as VectorSource } from 'ol/source.js';
import { Style, Fill, Circle } from 'ol/style.js';
import { MapOperationService } from './map-operation.service';
import {Feature} from 'ol';
import { LayerBase } from './layer-base';
import { MapBubbleService } from './map-bubble-service';
import Point from 'ol/geom/Point';
import { PositionData } from './location-service';


const MY_POS_LAYER = 'my-position';
const MY_POS_LAYER_MAX_RESOLUTION = 500;

@Injectable({
    providedIn: 'root'
})
export class MyPositionLayerService extends LayerBase {


    constructor(
        mapOperationsService: MapOperationService,
        mapBubbleService: MapBubbleService
    ) {
        super(mapOperationsService, mapBubbleService);
        this.layerName = MY_POS_LAYER;
        this.maxResolution = MY_POS_LAYER_MAX_RESOLUTION;
    }

    private source: VectorSource;

    updatePosition = (positionData: PositionData) => {
        const features = this.source.getFeatures();
        if (features.length > 0 && positionData?.projectedCurrentPositionCoords) {
            features[0].setGeometry(new Point(positionData.projectedCurrentPositionCoords));
        }
    }

    addMyPositionLayer() {
        const feature = new Feature({
            geometry: new Point([0, 0])
        });

        this.source = new VectorSource({
            features: [feature]
        });
        this.layer = new VectorLayer({
            source: this.source,
            style: (ftr, resolution) => {
                return this.getFeatureStyle(ftr);
            },
        });
        this.addLayer();
        this.setLayerVisibility(false);
    }


    getFeatureStyle(feature) {
        return new Style({
            image: new Circle({
                radius: 6,
                fill: new Fill({
                    color: 'rgba(51, 153, 204, .75)'
                })
            })
        });

    }

    show = (show: boolean) => {
        this.setLayerVisibility(show);
    }

    baseStyle = (feature: Feature) => {
        return this.getFeatureStyle(feature);
    }
    selectedStyle = (feature: Feature) => {
        return this.getFeatureStyle(feature);
    }

    overlayHtml = () => null;

}
