import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import * as _ from 'lodash';
import { CommonState } from "../../store/reducer";
import { CurrentState } from '../../store/current-state';
import {
  LoadAllTaskActionsAction,
  LoadAllTaskCategoriesAction,
  LoadAllTaskStatusesAction,
  LoadAllTasksAction
} from "../../store/tasks";
import {
  LoadAnimalBreedsAction,
  LoadAnimalSpeciesAction,
  LoadAnimalTypesAction,
  LoadAvailableMoveBoundariesAction,
  LoadBirthResultsAction
} from "../../store/livestock";
import { LoadMarkerTypesAction, LoadUserBoundariesAction, LoadUserMarkersAction } from "../../store/mappings";

@Injectable({
  providedIn: 'root'
})
export class MapDataService {

  constructor(
    private store: Store<CommonState>,
    private currentState: CurrentState<CommonState>,
  ) {

  }

  public loadReferenceData() {
    this.store.dispatch(new LoadAllTaskCategoriesAction());
    this.store.dispatch(new LoadAllTaskActionsAction());
    this.store.dispatch(new LoadAllTaskStatusesAction());
    this.store.dispatch(new LoadAnimalTypesAction());
    this.store.dispatch(new LoadAnimalSpeciesAction());
    this.store.dispatch(new LoadBirthResultsAction());
    this.store.dispatch(new LoadAnimalBreedsAction());
    this.store.dispatch(new LoadAvailableMoveBoundariesAction(null));
    this.store.dispatch(new LoadMarkerTypesAction());

    this.store.dispatch(new LoadUserMarkersAction(this.currentState.snapshot.user.id));
    this.store.dispatch(new LoadAllTasksAction(null));
    this.store.dispatch(new LoadUserBoundariesAction(this.currentState.snapshot.user.id));
  }


}
