import {Feature} from 'ol';

import { Circle as CircleStyle, Fill, Stroke, Style } from 'ol/style';
import { Vector as VectorSource } from 'ol/source';
import { Vector as VectorLayer } from 'ol/layer';
import { LineString, Point, Polygon, SimpleGeometry, } from 'ol/geom';
import { Injectable } from '@angular/core';
import { MapOperationService } from './map-operation.service';
import { TranslateService } from '@ngx-translate/core';


const DRAWING_LAYER = 'drawing';
const MAX_POINTS = 300;
const SNAP_DISTANCE = 15; // px

export interface DrawInteractionOptions {
  onDrawFinish?: (coords: any[] | null) => void;
  lineColor?: string;
  pointColor?: string;
  fillColor?: string;
  lineStyle?: Style;
  pointStyle?: Style;
  fillStyle?: Style;
  showCancel?: boolean;
  showFinish?: boolean;
  line?: boolean;
  splitBoundary?: SimpleGeometry;
  removeControls?: boolean;
  finishButtonTitle?: string;
  autoFinish?: boolean;
}


@Injectable({
  providedIn: 'root'
})
export class DrawingService {

  private newPolyFeatures = [];
  private newPolyCoords: number[][];
  private source: VectorSource;
  private layer: VectorLayer<any>;
  private maxPoints: number;

  private readonly defaultStyles: DrawInteractionOptions = {
    fillColor: 'rgba(255, 255, 255, .4)',
    lineColor: 'silver',
    pointColor: 'silver',
    showCancel: true,
    showFinish: true,
    line: false,
    removeControls: true,
    finishButtonTitle: 'Finish'
  };

  private options: DrawInteractionOptions;

  constructor(private mapOperationService: MapOperationService, private translateService: TranslateService) {
  }


  private drawStyles = () => ({
    Point: this.options.pointStyle ? this.options.pointStyle :
      new Style({
        image: new CircleStyle({
          radius: 6,
          fill: new Fill({
            color: this.options.pointColor
          })
        })
      }),
    LineString: this.options.lineStyle ? this.options.lineStyle : new Style({
      stroke: new Stroke({
        color: this.options.lineColor,
        width: 3
      })
    }),
    Polygon: this.options.fillStyle ? this.options.fillStyle : new Style({
      fill: new Fill({
        color: this.options.fillColor
      })
    })
  });

  addLayer() {
    this.source = new VectorSource({
      wrapX: false
    });

    const vector = new VectorLayer({
      source: this.source,
      updateWhileInteracting: true,
      style: (feature, resolution) =>
        this.drawStyles()[feature.getGeometry().getType()]
    });
    this.mapOperationService.addLayer(vector, DRAWING_LAYER);
  }

  private addFeature(geometry, constr, fillColor?) {
    const geom = new constr(geometry);
    const feature = new Feature();
    feature.setGeometry(geom);
    //FIXME szabi: after lib update this fillcolor of feature is gone...
    // if (fillColor) {
    //   feature.fillColor = fillColor;
    // }
    this.source.addFeature(feature);
    return feature;
  }

  addEventHandlers() {
    this.mapOperationService.map.on('pointerdrag', this.positionChange);
    this.mapOperationService.map.on('moveend', this.positionChange);
    this.mapOperationService.map.getView().on('change:resolution', this.positionChange);
    document.getElementById('add-poly').onclick = () => this.addVertex(this.mapOperationService.map.getView().getCenter());
    const finishBtn = document.getElementById('finish-poly');
    if (finishBtn) {
      finishBtn.onclick = this.completeDraw;
    }
    const cancelBtn = document.getElementById('cancel-poly');
    if (cancelBtn) {
      cancelBtn.onclick = this.cancelDraw;
    }
  }

  removeEventHandlers() {
    try {
      this.mapOperationService.map.un('pointerdrag', (event) => {
        console.log(event)
      });
      this.mapOperationService.map.un('moveend', (event) => {
        console.log(event)
      });
      this.mapOperationService.map.getView().un('change:resolution', (event) => {
        console.log(event)
      });
    } catch (ex) {
      // eventhanler not registered
    }
  }


  private appendCtrls(mapId: string) {
    const mapElement = document.getElementById(mapId);
    const parent = document.createElement('div');
    mapElement.append(parent);
    parent.setAttribute('id', 'edit-poly-buttons');
    let btn = document.createElement('button');
    btn.className = 'edit-poly';
    btn.innerHTML = this.translateService.instant('BUTTON.LABEL.ADD');
    btn.setAttribute('id', 'add-poly');
    parent.append(btn);
    if (this.options.showFinish) {
      btn = document.createElement('button');
      btn.className = 'edit-poly';
      btn.innerHTML = this.options.finishButtonTitle;
      btn.setAttribute('id', 'finish-poly');
      parent.append(btn);
    }
    if (this.options.showCancel) {
      btn = document.createElement('button');
      btn.className = 'edit-poly';
      btn.innerHTML = this.translateService.instant('BUTTON.LABEL.CANCEL');
      btn.setAttribute('id', 'cancel-poly');
      parent.append(btn);
    }
    const center = document.createElement('div');
    center.className = 'edit-poly';
    center.innerHTML = `<svg height="100" width="100" viewBox="0 0 100 100">
            <line x1="0" y1="50" x2="100" y2="50" class="line"/>
            <line x1="50" y1="0" x2="50" y2="100" class="line"/>
        </svg>`;
    center.setAttribute('id', 'center');
    mapElement.append(center);
  }

  private removeCtrls() {
    Array.from(document.getElementsByClassName('edit-poly')).forEach(el => el.remove());
  }

  public startDrawPolygon(options: DrawInteractionOptions) {
    this.maxPoints = MAX_POINTS;
    this.startDraw(options);
  }

  public startDrawLine(options: DrawInteractionOptions) {
    this.maxPoints = 2;
    this.startDraw({
      ...options,
      line: true
    });
  }

  startDrawMultiLine(options: DrawInteractionOptions) {
    this.startDraw({
      ...options,
      line: true,
      fillColor: 'transparent'
    });
  }

  public startDrawPin(options: DrawInteractionOptions) {
    this.maxPoints = 1;
    this.startDraw({
      ...options,
      line: true
    });
  }


  private startDraw(options: DrawInteractionOptions) {
    this.options = {...this.defaultStyles, ...options};
    this.appendCtrls(this.mapOperationService.targetId);
    this.addEventHandlers();
    this.addLayer();
    this.newPolyCoords = [];
    if (this.maxPoints != 1) {
      this.addNewPolyFeatures(this.mapOperationService.map.getView().getCenter());
    }
    this.setFinishBtnEnabledState();
  }

  private cancelDraw = () => {
    this.finishDraw();
    if (this.options.onDrawFinish) {
      this.options.onDrawFinish(null);
    }
  }

  private completeDraw = () => {
    const minLength = this.options.line ? 2 : 4;
    if (this.newPolyCoords.length >= minLength) {
      this.addVertex(this.newPolyCoords[0], true);
    }
  }


  private addVertex = (coords, finish = false) => {
    if (!finish || !this.options.line) {
      this.addNewPolyFeatures(coords);
    }
    this.setFinishBtnEnabledState();
    if (this.newPolyCoords.length > this.maxPoints || finish || this.isFinished() || (this.maxPoints == 1 && this.options.autoFinish)) {
      this.finishFeature();
    }
  }


  private removeNewPolyFeatures() {
    if (this.newPolyFeatures) {
      this.newPolyFeatures.forEach(feature => this.source.removeFeature(feature));
    }
    this.newPolyFeatures = [];
  }

  private addNewPolyFeatures(newCoords) {
    this.newPolyCoords.push(newCoords);
    if (this.newPolyCoords.length > 1) {
      const line = this.addFeature(this.newPolyCoords, LineString);
      const poly = this.addFeature([[...this.newPolyCoords, this.newPolyCoords[0]]], Polygon);
      this.newPolyFeatures.push(line);
      this.newPolyFeatures.push(poly);
    }
    if (this.newPolyCoords && this.newPolyCoords.length > 1) {
      this.newPolyCoords.slice(0, this.newPolyCoords.length - 1).forEach((coords, idx) => {
        const point = this.addFeature(coords, Point);
        this.newPolyFeatures.push(point);
      });
    }
  }

  private setFinishBtnEnabledState() {
    const finishBtn = document.getElementById('finish-poly');
    if (finishBtn) {
      const minCoordsLength = this.options.line ? 2 : 4;
      if (this.newPolyCoords.length < minCoordsLength) {
        finishBtn.setAttribute('disabled', 'true');
      } else {
        finishBtn.removeAttribute('disabled');
      }
    }

    const addBtn = document.getElementById('add-poly');
    if (addBtn) {
      if (this.maxPoints <= this.newPolyCoords.length) {
        addBtn.setAttribute('disabled', 'true');
      } else {
        addBtn.removeAttribute('disabled');
      }
    }
  }

  public finishDraw() {
    this.removeNewPolyFeatures();
    this.removeEventHandlers();
    this.removeCtrls();
    if (this.layer) {
      this.mapOperationService.map.removeLayer(this.layer);
      this.layer = null;
    }
    this.newPolyCoords = null;
  }

  private finishFeature(coords = null) {
    if (!this.options.line) { //drawing a polygon
      this.newPolyCoords.pop();
      this.newPolyCoords.push((!!coords && coords.length > 1) ? coords : this.newPolyCoords[0]);
    }
    if (this.options.onDrawFinish) {
      this.options.onDrawFinish((!!coords && coords.length > 1) ? coords : this.newPolyCoords);
    }
    if (this.options.removeControls) {
      this.finishDraw();
    } else {
      this.removeNewPolyFeatures();
      this.newPolyCoords = [];
      this.setFinishBtnEnabledState();
    }
  }

  private pixelDistance(coords1, coords2) {
    const pixelCoord1 = this.mapOperationService.map.getPixelFromCoordinate(coords1);
    const pixelCoord2 = this.mapOperationService.map.getPixelFromCoordinate(coords2);
    return Math.sqrt(Math.pow(pixelCoord1[0] - pixelCoord2[0], 2) + Math.pow(pixelCoord1[1] - pixelCoord2[1], 2));
  }

  private snapToBorderCoordinates() {
    if (this.options.splitBoundary) {
      const center = this.mapOperationService.map.getView().getCenter();
      const closestPoint = this.options.splitBoundary.getClosestPoint(center);
      const distance = this.pixelDistance(center, closestPoint);
      if (distance < SNAP_DISTANCE) {
        return closestPoint;
      }
      return null;
    }
  }

  private isFinished() {
    if (this.newPolyCoords.length > 2) {
      const distance = this.pixelDistance(this.newPolyCoords[0], this.mapOperationService.map.getView().getCenter());
      return distance < SNAP_DISTANCE;
    }
    return false;
  }

  private positionChange = () => {
    if (this.newPolyCoords && this.maxPoints != 1) {
      this.removeNewPolyFeatures();
      this.newPolyCoords.pop();
      const snapCoords = this.snapToBorderCoordinates();
      if (snapCoords) {
        this.mapOperationService.map.getView().setCenter(snapCoords);
      }
      this.addNewPolyFeatures(this.mapOperationService.map.getView().getCenter());
      if (this.isFinished()) {
        this.mapOperationService.map.getView().setCenter(this.newPolyCoords[0]);
      }
    }
  }
}
