import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';


@Injectable({
    providedIn: 'root'
})
export class SvgService {

    constructor(private http: HttpClient, private sanitizer: DomSanitizer) { }

    getSvgSrc(path): Observable<string> {
        const headers = new HttpHeaders();
        headers.set('Accept', 'image/svg+xml');
        return this.http.get(path, { headers, responseType: 'text' });
    }

    sanitize(svgSrc) {
        return this.sanitizer.bypassSecurityTrustHtml(svgSrc);
    }

}