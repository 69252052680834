import { Injectable } from '@angular/core';
import { get as getProjection } from 'ol/proj';
import { LayerBase } from './layer-base';
import { MapOperationService } from './map-operation.service';
import {Feature} from 'ol';
import { MapBubbleService } from './map-bubble-service';
import LayerZIndexConfig from "./layer-zindex-config";
import { getTopLeft, getWidth } from 'ol/extent';
import WMTSTileGrid from 'ol/tilegrid/WMTS';
import WMTS from 'ol/source/WMTS';
import TileLayer from 'ol/layer/Tile';
import { MappingsService } from '../mappings.service';

const HXGN_LAYER = 'hxgn';
const MAX_PARCEL_LAYER_RESOLUTION = 0;

@Injectable({
  providedIn: 'root'
})
export class HxgnHdLayerService extends LayerBase {
  constructor(
    private mappingService: MappingsService,
    mapOperationsService: MapOperationService,
    mapBubbleService: MapBubbleService) {
    super(mapOperationsService, mapBubbleService);
    this.layerName = HXGN_LAYER;
    this.maxResolution = MAX_PARCEL_LAYER_RESOLUTION;
  }

  initHXGLayer(token) {
    const hxgURL = 'https://services.hxgncontent.com/streaming/wmts?access_token=' + token;
    var projection = getProjection('EPSG:3857');
    var projectionExtent = projection.getExtent();
    var size = getWidth(projectionExtent) / 256;
    var resolutions = new Array(21);
    var matrixIds = new Array(21);
    for (var z = 0; z < 21; ++z) {
      resolutions[z] = size / Math.pow(2, z);
      matrixIds[z] = z;
    }

    const hxgnWMTSSource = new WMTS({
      url: hxgURL,
      layer: 'HxGN_Imagery',
      matrixSet: 'WebMercator',
      format: 'image/unknown',
      projection: projection,
      tileGrid: new WMTSTileGrid({
        origin: getTopLeft(projectionExtent),
        resolutions: resolutions,
        matrixIds: matrixIds,
      }),
      style: 'default',
      wrapX: true,
    });
    this.layer = new TileLayer({
      source: hxgnWMTSSource
    });

    this.layer.on('change', e => {
      if (e.target.state_.sourceState === 'ready') {
        this.mapOperationsService.fireEvent('loading', false, null);
      }
    });
    this.addLayer();
    this.setLayerVisibility(true);
  }

  addHXGNLayer() {
    this.mappingService.getHXGNCredentials().subscribe(response => {
      this.initHXGLayer((response as any).token);
    })
  }

  show = (show: boolean) => {
    this.setLayerVisibility(show);
  };

  setLayerVisibility(visible: boolean) {
    if (this.layer) {
      const current = this.layer.getVisible();
      if (visible !== current) {
        this.layer.setVisible(visible);
        if (!visible) {
          this.hideBubbles();
        } else {
          this.showBubbles();
        }
        this.layer.setZIndex(visible ? LayerZIndexConfig[this.layerName] : 0);
      }
    }
  }

  baseStyle = (feature: Feature) => {
    return null;
  }
  selectedStyle = (feature: Feature) => {
    return null;
  }

  overlayHtml = () => null;
}

