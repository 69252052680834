const zIndexConfig = {
  user: 100,
  rainfall: 30,
  parcels: 20,
  grazing: 10,
  'my-position': 5,
  boundary: 90,
  'free-line': 91,
  measurement: 100,
  'livestock_badge': 100,
  'gps': 100,
  hxgn: 2,
  satellite: 1,
  transport: 3,
  places: 4,
  drawing: 101,
  'search_location': 5,
  'tracing': 6,
}

export default zIndexConfig;
