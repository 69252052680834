import { Action } from '@ngrx/store';
import { type } from "../../types";
import { PageItem } from "common-lib";


export const ActionTypes = {
  SHOW_LOADING: type('[app] [UI] Loading show'),
  HIDE_LOADING: type('[app] [UI] Loading hide'),
  SET_ENABLED_ENTRY_PAGES: type('[app] [UI] Set enabled entry pages')


};

export class ShowLoadingAction implements Action {
  type = ActionTypes.SHOW_LOADING;

  constructor() {
  }
}

export class HideLoadingAction implements Action {
  type = ActionTypes.HIDE_LOADING;

  constructor() {
  }
}

export class SetAvailableEntryPagesAction implements Action {
  type = ActionTypes.SET_ENABLED_ENTRY_PAGES;

  constructor(public readonly payload: PageItem[]) {
  }
}


export declare type UiActions =
  ShowLoadingAction
  | HideLoadingAction
  | SetAvailableEntryPagesAction;


