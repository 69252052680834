import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthImagePipe } from "../auth-image-pipe/auth-image-pipe";
import { SafeUrlPipe } from "./safe-url.pipe";



@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    SafeUrlPipe
  ],
  exports: [
    SafeUrlPipe
  ]
})
export class SafeUrlPipeModule { }
