import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UserMarker, MapEvent, UserMarkerGroup } from '../../model';
import * as _ from 'lodash';

import { MapOperationService } from './map-operation.service';
import { MapBubbleService } from './map-bubble-service';
import { LayerBase } from './layer-base';

import { Vector as VectorLayer } from 'ol/layer.js';
import { Vector as VectorSource } from 'ol/source.js';
import { Icon, Style } from 'ol/style.js';
import { Feature } from 'ol';
import Point from 'ol/geom/Point';
import { fromLonLat } from 'ol/proj';

const USER_LAYER = 'user';
const USER_LAYER_MAX_RESOLUTION = Infinity;


@Injectable({
  providedIn: 'root'
})
export class UserMarkerLayerService extends LayerBase {
  constructor(
    mapOperationsService: MapOperationService,
    mapBubbleService: MapBubbleService,
    private translateService: TranslateService
  ) {
    super(mapOperationsService, mapBubbleService);
    this.layerName = USER_LAYER;
    this.maxResolution = USER_LAYER_MAX_RESOLUTION;
  }

  protected overlayConfig = {
    align: 'top' as 'top',
    class: 'user-marker'
  };

  private markerSource: VectorSource;
  private createMarkerStarted = false;

  addMarkerLayer(isVisible = true) {
    this.markerSource = new VectorSource({
    });
    this.layer = new VectorLayer({
      source: this.markerSource,
      style: (feature, resolution) => {
        return this.getFeatureStyle(feature as any);
      },
    });
    this.addLayer();
    this.setLayerVisibility(isVisible);
  }

  updateLayer(markerGroups: UserMarkerGroup[]) {
    this.markerSource.clear();
    markerGroups.forEach(this.addMarker);
  }


  addMarker = (marker: UserMarkerGroup) => {
    const feature = new Feature({
      geometry: new Point(fromLonLat([marker.lng, marker.lat]))
    });
    feature.setProperties({featureData: marker});
    this.markerSource.addFeature(feature);
  }

  getFeatureStyle(feature: Feature) {
    const data: UserMarkerGroup = (feature.getProperties() as any).featureData;
    const tasks: UserMarker[] = data.markers.filter((m, idx) => idx !== data.mainIdx);
    let src: string = data.svgSrc;
    if (!src) {
      return;
    }

    src = src.replace(/fill:#c7b299/g, 'fill:' + data.markers[0].color);

    if (data.urgent) {
      let idx = src.indexOf('>');
      idx = src.substr(idx + 1).indexOf('>') + idx + 2;
      src = src.substr(0, idx) + '<style>.bg {fill: #de0000 !important}</style>' + src.substr(idx);

      src = src.replace(/fill:#4d4d4d/g, 'fill:#dadada');
      src = src.replace(/fill:#666666/g, 'fill:#dadada');

    }
    if (tasks.length > 0) {
      let end = src.indexOf('>', src.indexOf('?>') + 2);
      src = src.substr(end + 1);
      src = this.svgWithBadgeViewPort + src;
      end = src.lastIndexOf('</svg>');
      src = src.substr(0, end) + this.bagde(tasks.length) + '</svg>';
    }
    return new Style({
      image: new Icon({
        src: 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(src),
        scale: 0.12,
        anchor: [.5, 1]
      })
    });
  }

  mapEventHandler = (event: MapEvent) => {
    super.mapEventHandler(event);
    switch (event.name) {
      case 'click': {
        if (this.createMarkerStarted) {
          console.log('UserMarkerLayerService.mapEventHandler click happened. Event:');
          console.log(event);
          const {longitude, latitude} = event.eventData;
          this.mapOperationsService.fireEvent('add-feature', {
            layerName: this.layerName,
            featureData: {longitude, latitude}
          }, event);
          this.endCreateMarker();
        }
      }
    }
  }

  endCreateMarker() {
    this.createMarkerStarted = false;
    this.mapOperationsService.freezeMap(false);
  }

  startCreateMarker() {
    this.createMarkerStarted = true;
    this.mapOperationsService.freezeMap(true);
    setTimeout(() => this.endCreateMarker(), 30000);
  }

  show = (show: boolean) => {
    if (show) {
      this.setLayerVisibility(true);
    } else {
      this.setLayerVisibility(false);
    }
  }

  taskList = (tasks, main) => `
    <div class="marker-overlay-tasks ${main ? '' : 'simple'}">
    <div class="tasks-header highlight upper">${this.translateService.instant('Tasks')}</div>
    <ul class="marker-overlay-list">
    ${tasks.reduce((prev, marker, idx) => prev + (`
       <li class="marker-item">
           <div>${marker.title}</div>
           <div>${marker.description ? marker.description : ''}</div>
           <div class="toolbar-row">
           <a class="edit-user-marker " data-task-id="${marker.relatedTaskId}">
               ${this.editorBtn}
           </a>
           </div>
       </li>`), '')}
   </ul>
   </div>
   `

  overlayHtml = (feature: Feature) => {
    if (feature && (feature.getProperties() as any).featureData) {
      const data: UserMarkerGroup = (feature.getProperties() as any).featureData;
      const main = (data.mainIdx > -1 ? data.markers[data.mainIdx] : null);
      const tasks: UserMarker[] = data.markers.filter((m, idx) => idx !== data.mainIdx);
      return `${main ? `
            <div class="user-marker-popup">
                <div class="main marker-item">
                    <div class="highlight">${main.title}</div>
                    <div>${main.description ? main.description : ''}</div>
                    <div class="toolbar-row"><a class="edit-user-marker" data-marker-idx="${data.mainIdx}">
                            ${this.settingsBtn}
                    </a></div>
                </div>` : ''}
                ${tasks.length > 0 ? this.taskList(tasks, !!main) : ''}
            </div>`;
    }
    return null;
  }

  baseStyle = (feature: Feature) => {
    return this.getFeatureStyle(feature as any);
  }
  selectedStyle = (feature: Feature) => {
    return this.getFeatureStyle(feature as any);
  }


  readonly svgWithBadgeViewPort = `<?xml version="1.0" encoding="UTF-8" standalone="no"?><svg xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:cc="http://creativecommons.org/ns#" xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#" xmlns:svg="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/2000/svg" height="456" width="313" viewBox="0 -5 313 445" id="Layer_1" version="1.1">`;

  bagde = tasksCnt => `<circle cx="242" cy="60" r="70" fill="whitesmoke"></circle>
    <text font-family="Roboto, sans-serif" font-weight="bold" fill="#666666" font-size="90px"  text-anchor="middle" x="242" y="90">${tasksCnt}</text>`


}
