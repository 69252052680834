import { Action } from '@ngrx/store';
import { TaskCategory, TaskEntity } from "../../model";
import { type } from "../types";

export const TasksActionTypes = {
  LOAD_ALL_TASKS: type('[app] [TASKS] Load all tasks'),
  LOAD_ALL_TASKS_NEXT_PAGE: type('[app] [TASKS] Load all tasks next page'),
  LOAD_ALL_TASKS_FAILED: type('[app] [TASKS] Load all tasks failed'),
  LOAD_ALL_TASKS_SUCCESS: type('[app] [TASKS] Load all tasks success'),
  SET_ALL_TASKS: type('[app] [TASKS] Set all tasks'),

  LOAD_UPCOMING_TASKS: type('[app] [TASKS] Load upcoming tasks'),
  LOAD_UPCOMING_TASKS_NEXT_PAGE: type('[app] [TASKS] Load all upcoming next page'),
  LOAD_UPCOMING_TASKS_SUCCESS: type('[app] [TASKS] Load upcoming tasks success'),
  LOAD_UPCOMING_TASKS_FAILED: type('[app] [TASKS] Load upcoming tasks failed'),
  SELECT_TASK: type('[app] [TASKS] Select task'),
  RELOAD_TASK: type('[app] [TASKS] Reload task'),
  RESET_SELECTED_TASK: type('[app] [TASKS] Reset selected task'),
  LOAD_SELECT_TASK_SUCCESS: type('[app] [TASKS] Load selected task success'),
  LOAD_SELECT_TASK_FAILED: type('[app] [TASKS] Load selected task failed'),
  LOAD_TASK_DETAIL_SUCCESS: type('[app] [TASKS] Load task detail success'),
  LOAD_TASK_DETAIL_FAILED: type('[app] [TASKS] Load task detail failed'),

  LOAD_ALL_TASK_CATEGORIES: type('[app] [TASKS] Load all task categories'),
  LOAD_ALL_TASK_CATEGORIES_FAILED: type('[app] [TASKS] Load all task categories failed'),
  LOAD_ALL_TASK_CATEGORIES_SUCCESS: type('[app] [TASKS] Load all task categories success'),

  LOAD_ALL_TASK_ACTIONS: type('[app] [TASKS] Load all task actions'),
  LOAD_ALL_TASK_ACTIONS_FAILED: type('[app] [TASKS] Load all task actions failed'),
  LOAD_ALL_TASK_ACTIONS_SUCCESS: type('[app] [TASKS] Load all task actions success'),

  LOAD_ALL_TASK_STATUSES: type('[app] [TASKS] Load all task statuses'),
  LOAD_ALL_TASK_STATUSES_FAILED: type('[app] [TASKS] Load all task statuses failed'),
  LOAD_ALL_TASK_STATUSES_SUCCESS: type('[app] [TASKS] Load all task statuses success'),

  SAVE_NEW_TASK_SUCCESS: type('[app] [TASKS] Save new task success'),
  SAVE_NEW_TASK_FAILED: type('[app] [TASKS] Save new task failed'),

  UPDATE_TASK_SUCCESS: type('[app] [TASKS] Updatetask success'),
  UPDATE_TASK_FAILED: type('[app] [TASKS] Update task failed'),

  DELETE_TASK_SUCCESS: type('[app] [TASKS] Delete task success'),
  DELETE_TASK_FAILED: type('[app] [TASKS] Delete task failed'),

  SET_FILTER: type('[app] [TASKS] Set filter'),

  SET_AVAILABLE_ASSIGNEES: type('[app] [TASKS] Set available assingees'),

  ATTACHMENT_REMOVED_FROM_CACHE_SUCCESS: type('[app] [TASKS] Delete attachment from cache success'),
  ATTACHMENT_REMOVED_FROM_CACHE_FAILED: type('[app] [TASKS] Delete attachment from cache failed'),
  UPDATE_ALL_TASK_PAGE: type('[app] [TASKS] Update all tasks page left'),
  UPDATE_UPCOMING_TASK_PAGE: type('[app] [TASKS] Update more upcoming tasks page left'),

  RESET_CURRENT_PAGE: type('[app] [TASKS] Reset current page'),
};


export class LoadAllTasksAction implements Action {
  type = TasksActionTypes.LOAD_ALL_TASKS;

  constructor(public payload: { pageSize: number, pageNumber: number }) {
  }
}

export class LoadAllTasksNextPageAction implements Action {
  type = TasksActionTypes.LOAD_ALL_TASKS_NEXT_PAGE;

  constructor(public payload: any) {
  }
}

export class LoadAllTasksSuccessAction implements Action {
  type = TasksActionTypes.LOAD_ALL_TASKS_SUCCESS;

  constructor(public payload: any) {
  }
}

export class SetAllTasksAction implements Action {
  type = TasksActionTypes.SET_ALL_TASKS;

  constructor(public payload: TaskEntity[]) {
  }
}

export class LoadAllTasksFailedAction implements Action {
  type = TasksActionTypes.LOAD_ALL_TASKS_FAILED;

  constructor(public payload: any) {
  }
}

export class SelectTaskAction implements Action {
  type = TasksActionTypes.SELECT_TASK;

  constructor(public payload: any) {
  }
}

export class ReloadTaskAction implements Action {
  type = TasksActionTypes.RELOAD_TASK;

  constructor(public payload: any) {
  }
}

export class LoadSelectedTaskSuccessAction implements Action {
  type = TasksActionTypes.LOAD_SELECT_TASK_SUCCESS;

  constructor(public payload: any) {
  }
}

export class LoadSelectedTaskFailedAction implements Action {
  type = TasksActionTypes.LOAD_SELECT_TASK_FAILED;

  constructor(public payload: any) {
  }
}


export class ResetSelectedTaskAction implements Action {
  type = TasksActionTypes.RESET_SELECTED_TASK;

  constructor() {
  }
}

export class LoadTaskDetailSuccessAction implements Action {
  type = TasksActionTypes.LOAD_TASK_DETAIL_SUCCESS;

  constructor(public payload: TaskEntity) {
  }
}

export class LoadTaskDetailFailedAction implements Action {
  type = TasksActionTypes.LOAD_TASK_DETAIL_FAILED;

  constructor(public payload: any) {
  }
}

export class LoadAllTaskCategoriesAction implements Action {
  type = TasksActionTypes.LOAD_ALL_TASK_CATEGORIES;

  constructor() {
  }
}

export class LoadAllTaskCategoriesSuccessAction implements Action {
  type = TasksActionTypes.LOAD_ALL_TASK_CATEGORIES_SUCCESS;

  constructor(public payload: TaskCategory[]) {
  }
}

export class LoadAllTaskCategoriesFailedAction implements Action {
  type = TasksActionTypes.LOAD_ALL_TASK_CATEGORIES_FAILED;

  constructor(public payload: any) {
  }
}

export class LoadAllTaskActionsAction implements Action {
  type = TasksActionTypes.LOAD_ALL_TASK_ACTIONS;

  constructor() {
  }
}

export class LoadAllTaskActionsSuccessAction implements Action {
  type = TasksActionTypes.LOAD_ALL_TASK_ACTIONS_SUCCESS;

  constructor(public payload: TaskCategory[]) {
  }
}

export class LoadAllTaskActionsFailedAction implements Action {
  type = TasksActionTypes.LOAD_ALL_TASK_ACTIONS_FAILED;

  constructor(public payload: any) {
  }
}

export class LoadAllTaskStatusesAction implements Action {
  type = TasksActionTypes.LOAD_ALL_TASK_STATUSES;

  constructor() {
  }
}

export class LoadAllTaskStatusesSuccessAction implements Action {
  type = TasksActionTypes.LOAD_ALL_TASK_STATUSES_SUCCESS;

  constructor(public payload: TaskCategory[]) {
  }
}

export class LoadAllTaskStatusesFailedAction implements Action {
  type = TasksActionTypes.LOAD_ALL_TASK_STATUSES_FAILED;

  constructor(public payload: any) {
  }
}


export class SaveNewTaskSuccessAction implements Action {
  type = TasksActionTypes.SAVE_NEW_TASK_SUCCESS;

  constructor(public payload: any) {
  }
}

export class SaveNewTaskFailedAction implements Action {
  type = TasksActionTypes.SAVE_NEW_TASK_FAILED;

  constructor(public payload: any) {
  }
}

export class UpdateTaskSuccessAction implements Action {
  type = TasksActionTypes.UPDATE_TASK_SUCCESS;

  constructor(public payload: any) {
  }
}

export class UpdateTaskFailedAction implements Action {
  type = TasksActionTypes.UPDATE_TASK_FAILED;

  constructor(public payload: any) {
  }
}


export class DeleteTaskSuccessAction implements Action {
  type = TasksActionTypes.DELETE_TASK_SUCCESS;

  constructor(public payload: number | string) {
  }
}

export class DeleteTaskFailedAction implements Action {
  type = TasksActionTypes.DELETE_TASK_FAILED;

  constructor(public payload: any) {
  }
}

export class SetTaskFilterAction implements Action {
  type = TasksActionTypes.SET_FILTER;

  constructor(public payload: { categories: any[], statuses: any[], assignees: any[], ordering: any, locations: any[], ranches: any[] }) {
  }
}

export class LoadUpcomingTasksAction implements Action {
  type = TasksActionTypes.LOAD_UPCOMING_TASKS;

  constructor(public payload: { pageSize: number, pageNumber: number }) {
  }
}

export class LoadUpcomingNextPageAction implements Action {
  type = TasksActionTypes.LOAD_UPCOMING_TASKS_NEXT_PAGE;

  constructor(public payload: any) {
  }
}

export class LoadUpcomingTasksFailedAction implements Action {
  type = TasksActionTypes.LOAD_UPCOMING_TASKS_FAILED;

  constructor(public payload: any) {
  }
}

export class LoadUpcomingTasksSuccessAction implements Action {
  type = TasksActionTypes.LOAD_UPCOMING_TASKS_SUCCESS;

  constructor(public payload: any) {
  }
}


export class AttachmentRemovedFromCacheSuccessAction implements Action {
  type = TasksActionTypes.ATTACHMENT_REMOVED_FROM_CACHE_SUCCESS;

  constructor(public payload: any) {
  }
}

export class AttachmentRemovedFromCacheFailedAction implements Action {
  type = TasksActionTypes.ATTACHMENT_REMOVED_FROM_CACHE_FAILED;

  constructor(public payload: any) {
  }
}

export class SetAvailableAssigneesAction implements Action {
  type = TasksActionTypes.SET_AVAILABLE_ASSIGNEES;

  constructor(public payload: {
    id;
    userName: string,
    firstName: string,
    lastName: string,
    email: string
  }[]) {
  }
}

export class UpdateAvailableUpcomingTasksPageAction implements Action {
  type = TasksActionTypes.UPDATE_UPCOMING_TASK_PAGE;

  constructor(public payload: boolean) {
  }
}

export class UpdateAvailableAllTasksPageAction implements Action {
  type = TasksActionTypes.UPDATE_ALL_TASK_PAGE;

  constructor(public payload: boolean) {
  }
}

export class ResetCurrentPageAction implements Action {
  type = TasksActionTypes.RESET_CURRENT_PAGE;

  constructor() {
  }
}

export declare type TasksActions =
  LoadAllTasksAction
  | LoadAllTasksFailedAction
  | LoadAllTasksSuccessAction
  | SelectTaskAction
  | LoadSelectedTaskSuccessAction
  | LoadSelectedTaskFailedAction
  | LoadTaskDetailFailedAction
  | LoadTaskDetailSuccessAction
  | LoadAllTaskCategoriesAction
  | LoadAllTaskCategoriesFailedAction
  | LoadAllTaskCategoriesSuccessAction
  | LoadAllTaskActionsAction
  | LoadAllTaskActionsFailedAction
  | LoadAllTaskActionsSuccessAction
  | LoadAllTaskStatusesAction
  | SetAllTasksAction
  | LoadAllTaskStatusesSuccessAction
  | LoadAllTaskStatusesFailedAction
  | SaveNewTaskSuccessAction
  | SaveNewTaskFailedAction
  | UpdateTaskFailedAction
  | UpdateTaskSuccessAction
  | DeleteTaskFailedAction
  | DeleteTaskSuccessAction
  | SetTaskFilterAction
  | LoadUpcomingTasksAction
  | LoadUpcomingTasksFailedAction
  | LoadUpcomingTasksSuccessAction
  | SetAvailableAssigneesAction
  | ResetSelectedTaskAction
  | AttachmentRemovedFromCacheSuccessAction
  | AttachmentRemovedFromCacheFailedAction
  | LoadAllTasksNextPageAction
  | LoadUpcomingNextPageAction
  | UpdateAvailableUpcomingTasksPageAction
  | UpdateAvailableAllTasksPageAction
  | ResetCurrentPageAction
  | ReloadTaskAction;


