import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { ClimateSurveyQuestion, ENVIRONMENT_TOKEN } from "../../public-api";
import { ClimateRequest } from "../model/ClimateModel";
import { CurrentState } from "../store/current-state";
import { CommonState } from "../store/reducer";
import { ServicesCommon } from "./services-common.service";


@Injectable()
export class ClimateService {

  private readonly CLIMATE_URL = `/private/climatesmart/`;

  constructor(
    private currentState: CurrentState<CommonState>,
    protected http: HttpClient,
    private servicesCommon: ServicesCommon,
    @Inject(ENVIRONMENT_TOKEN) private readonly environment
  ) {
  }


  submitReport(request: ClimateRequest) {
    const url = this.CLIMATE_URL + "pasture";
    return this.http.post<any>(
      this.environment.backend + url,
      request,
      {headers: this.servicesCommon.getAuthHeaders()}
    );
  }

  getSurveyQuestions(userId) {
    const url = this.CLIMATE_URL + `survey/questions/user/${userId}`;
    return this.http.get<ClimateSurveyQuestion[]>(
      this.environment.backend + url,
      {headers: this.servicesCommon.getAuthHeaders()}
    );
  }

  sendSurveyAnswers(userId, answers: any) {
    const url = this.CLIMATE_URL + `survey/questions/user/${userId}`;
    return this.http.post<ClimateSurveyQuestion[]>(
      this.environment.backend + url,
      answers,
      {headers: this.servicesCommon.getAuthHeaders()}
    );
  }
}
