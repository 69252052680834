import {
  EntityWithImage,
  MappingRequest,
  UserMarkerGroup,
  UserMarker
} from '../../model';
import { UserMarkerConfigs } from '../map/user-marker-config';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { catchError, tap } from "rxjs/operators";
import { MappingsService } from '../mappings.service';
import { CurrentState } from '../../store/current-state';
import { CommonState } from '../../store/reducer';
import { Store } from '@ngrx/store';
import { CreateMarkerSuccessAction, DeleteMarkerAction, UpdateMarkerAction } from '../../store/mappings';
import { Observable, of } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class UserMarkerDataService {

  private _newMarkerType: number = -1;
  addMarkerPopupPageOpened: boolean;

  public get newMarkerType(): number {
    return this._newMarkerType;
  }

  public set newMarkerType(v: number) {
    this._newMarkerType = v;
  }

  constructor(
    private markerConfigs: UserMarkerConfigs,
    private mappingService: MappingsService,
    private store: Store<CommonState>,
    private currentState: CurrentState<CommonState>,
  ) {
  }

  async createMarker(latitude, longitude) {
    let type = this.newMarkerType;
    const markers = await this.markerConfigs.getMarkerTypes();
    if (type === -1 && markers.length > 0) {
      type = +markers[0].id;
    }
    const title = (await this.markerConfigs.getMarkerConfig(type)).title;
    const ranchIds = this.currentState.snapshot.ranches.ranchesOfUser.map(rou => {
      return rou.ranch.id;
    })
    if (type) {
      const markerData: UserMarker = {
        typeId: type,
        title,
        description: '',
        urgent: false,
        locationLng: longitude,
        locationLat: latitude,
        shared: false,
        attachments: [],
        sharedRanchIds: ranchIds,
      };
      return markerData;
    }
    return null;
  }

  isWaterSourceMarkerTypeSelected(typeId = null) {
    return typeId ? typeId === this.markerConfigs.waterSourceMarkerType : this.newMarkerType === this.markerConfigs.waterSourceMarkerType;
  }

  handleEditResult(result: 'delete' | 'save' | 'cancel', markerData: UserMarker, attachments: EntityWithImage[], mode: 'add' | 'edit'): Observable<any> {
    if (result === 'delete' && mode === 'edit' && markerData.id) {
      this.store.dispatch(new DeleteMarkerAction(markerData.id));
      return of('success');
    }
    console.log(markerData)
    if (result === 'save') {
      const {
        typeId, title, description, ownerId,
        featureTypeId, locationLat, locationLng,
        urgent, sharedRanchIds, color, updateTS
      } = markerData;
      let data: MappingRequest = {
        typeId, title, description, ownerId,
        featureTypeId, locationLat, locationLng,
        urgent, sharedRanchIds, color,
        creatorId: this.currentState.snapshot.user.id,
        updateTS, attachments
      };

      if (mode != 'add') {
        data.markerId = +markerData.id;
        // TODO mapping check mobile attachment handling
        this.store.dispatch(new UpdateMarkerAction(data));
        return of(data);
      } else {
        return this.mappingService.createMarker(data).pipe(
          catchError(error => {
            console.log(error);
            return of(null);
          }),
          tap((response: UserMarker) => {
            if (!!response) {
              this.store.dispatch(new CreateMarkerSuccessAction(response));
            }
          })
        )

      }
    }
    return of(null);
  }

  async getGroup(groupsDir: _.Dictionary<UserMarker[]>, key: string): Promise<UserMarkerGroup> {
    if (groupsDir[key].length) {
      const mainIdx = await this.getMain(groupsDir[key]);
      const type = mainIdx > -1 ? +groupsDir[key][mainIdx].typeId : this.markerConfigs.generalMarkerType;
      const group: UserMarkerGroup = {
        lat: +groupsDir[key][0].locationLat,
        lng: +groupsDir[key][0].locationLng,
        markerCnt: groupsDir[key].length,
        mainIdx,
        type,
        markers: groupsDir[key],
        urgent: !!groupsDir[key].find(marker => marker.urgent),
        svgSrc: ''
      };
      const src = await this.markerConfigs.getMarkerSvgSrc(+type);
      group.svgSrc = src;
      return group;
    }
  }

  async groupMarkers(markers: UserMarker[]): Promise<UserMarkerGroup[]> {
    const promise = new Promise<UserMarkerGroup[]>(async (res, rej) => {
      const groupsDir = _.groupBy(markers, marker => `${marker.locationLat},${marker.locationLng}`);
      const groups: UserMarkerGroup[] = [];
      for (const key in groupsDir) {
        if (groupsDir[key]) {
          groups.push(await this.getGroup(groupsDir, key));
        }
      }
      res(groups);
    });
    return promise;
  }


  async getMain(markers: UserMarker[]) {
    const taskMarker = await this.markerConfigs.getTaskMarker();
    return markers.findIndex((marker: UserMarker) => +marker.typeId !== taskMarker.id);
  }

}


