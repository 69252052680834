import { Injectable } from '@angular/core';
import { Vector as VectorLayer } from 'ol/layer.js';
import { Vector as VectorSource } from 'ol/source.js';
import {Feature} from 'ol';
import Point from 'ol/geom/Point';
import { Circle, Style, Fill, Stroke } from 'ol/style.js';
import { fromLonLat, transform } from 'ol/proj';
import { LayerBase } from './layer-base';
import { MapBubbleService } from './map-bubble-service';
import { MapOperationService } from './map-operation.service';
import { GPSEarTag } from '../../model';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import * as _ from 'lodash';
import { WKTGeometryConverter } from './wkt-converter.service';
import { LivestockBadgeLayerService } from './livestock-badge-layer.service';
import { FreeLineLayerService } from './free-line-layer.service';
import { UserMarkerLayerService } from './user-marker-layer.service';
import { GPSEarTagService } from '../gpseartag.service';
import { CommonState } from '../../store/reducer';
import { allGpsLatest, hasLivestockPermission } from '../../store/views';
import { CurrentState } from '../../store/current-state';


const GPS_LAYER = 'gps';
const USER_LAYER_MAX_RESOLUTION = Infinity;

@Injectable({
  providedIn: 'root'
})
export class GPSLayerService extends LayerBase {

  gpsEarTagSubscription: Subscription;
  gpsTimeFrameSubscription: Subscription;
  lsPermissionSubscription: Subscription;
  source: VectorSource;

  timeFrames: Array<any>;
  isHistory = false;
  lineFeature: Feature;

  lsPermission;

  constructor(
    mapOperationsService: MapOperationService,
    mapBubbleService: MapBubbleService,
    private translateService: TranslateService,
    private gpsEarTagService: GPSEarTagService,
    private wktGeometryConverter: WKTGeometryConverter,
    private store: Store<CommonState>,
    private currentState: CurrentState<CommonState>,
    private livestockBadgeLayerService: LivestockBadgeLayerService,
    private freeLineLayerService: FreeLineLayerService,
    private userMarkerLayerService: UserMarkerLayerService,
  ) {
    super(mapOperationsService, mapBubbleService);
    this.layerName = GPS_LAYER;
    this.maxResolution = USER_LAYER_MAX_RESOLUTION;
    this.timeFrames = [];

    this.gpsEarTagService.getTimeFrames();

    this.gpsTimeFrameSubscription = this.store.select(state => state.gpsEarTags.timeFrames).subscribe(timeFrames => {
      if (!!timeFrames) {
        timeFrames.forEach(tf =>{
          this.timeFrames.push({
            label: tf.title, 
            type: 'radio',
            value: tf.id
          })
        })
      }
    });

  }

  protected overlayConfig = {
    align: 'top' as 'top',
    class: 'gps-ear-tag'
  };

  public getTimeFrames() {
    return this.timeFrames;
  }

  private updateToLatest() {
    this.gpsEarTagSubscription = this.store.select(allGpsLatest)
      .pipe(filter(tags => !!tags))
      .subscribe(gpsTags => {
        if (gpsTags?.length) {
          this.updateLayer(gpsTags);
        }
    });
  }

  addGPSLayer(visible = true) {
    this.source = new VectorSource({});
    this.layer = new VectorLayer({
      source: this.source,
      style: (feature, resolution) => {
        return this.getFeatureStyle(feature as any);
      },
    });
    this.lsPermissionSubscription = this.store.select(hasLivestockPermission).subscribe(perm => {
      this.lsPermission = perm
    });

    this.addLayer();
    this.updateToLatest();
    this.setLayerVisibility(visible);
  }

  getFeatureStyle(feature) {
    let fill = new Fill({
      color: 'rgba(250, 216, 89, 1)',
    });
    let stroke = new Stroke({
      color: 'rgba(250, 216, 89, 0.4)',
      width: 2,
    });

    if ((feature.getProperties() as any).history?.isFirst) {
      fill = new Fill({
        color: 'rgba(89, 250, 167, 1)',
      });
      stroke = new Stroke({
        color: 'rgba(89, 250, 167, 0.4)',
        width: 2,
      });
    } else if ((feature.getProperties() as any).history?.isLast) {
      fill = new Fill({
        color: 'rgba(250, 89, 89, 1)',
      });
      stroke = new Stroke({
        color: 'rgba(250, 89, 89, 0.4)',
        width: 2,
      });
    }
  

    return new Style({
      image: new Circle({
        fill: fill,
        stroke: stroke,
        radius: 10,
      }),
    })
  };

  getLineFeatureStyle () {
    return new Style({
      stroke: new Stroke({
        color: 'rgb(250,250,250,1)',
        width: 2
      }),
    })
  }

  updateLayer(tags: GPSEarTag[]) {
    this.layer.getSource().getFeatures().forEach(
      feature => {
        this.deSelectFeature(feature);
      });

    this.source.clear();

    if (this.isHistory /*&& tags?.length <= 56*/) {
      this.addLineFeature(tags);
    }

    tags.forEach((tag, index) => {
      if (this.isHistory) {
        this.addFeature(tag, (index==0), (tags.length-1 == index));
      } else {
        this.addFeature(tag);
      }
    });
  }

  show = (show: boolean) => {
    if (show) {
      this.setLayerVisibility(true);
    } else {
      this.deSelectAll();
      this.setLayerVisibility(false);
    }
  }


  addLineFeature(tags: GPSEarTag[]) {
    let coords = [];
    tags.forEach((tag) => {
      coords.push(transform([parseFloat(tag.locationLng), parseFloat(tag.locationLat)], 'EPSG:4326', 'EPSG:3857'))
    });

    const line = this.wktGeometryConverter.lineStringToWKT(coords);
    const geometry = this.wktGeometryConverter.toGeometry(line);

    this.lineFeature = new Feature();
    this.lineFeature.setProperties({
      featureData: line
    });
    this.lineFeature.setGeometry(geometry);
    this.lineFeature.setStyle(this.getLineFeatureStyle());
    this.source.addFeature(this.lineFeature);
  }

  unHideLineFeature = () => {
    this.source.addFeature(this.lineFeature);
    this.changeHideLineBtnCtrls(true);
  }

  hideLineFeature = () => {
    this.changeHideLineBtnCtrls(false);
    this.source.removeFeature(this.lineFeature);
  }

  addFeature (tag, first = false, last = false) {
    let feature = new Feature({
      geometry: new Point(fromLonLat([tag.locationLng, tag.locationLat]))
    });
    feature.setProperties({featureData: tag, history: {isFirst: first, isLast: last}});
    this.source.addFeature(feature);
  }


  getDateTime(dateTime: string) {
    let splitted = dateTime.split('-');
    let dt = new Date(parseInt(splitted[0]), parseInt(splitted[1])-1, parseInt(splitted[2]), parseInt(splitted[3]), parseInt(splitted[4]), parseInt(splitted[5]));
    return dt.toLocaleDateString('en-us', { weekday:"short", year:"numeric", month:"short", day:"numeric", hour: "2-digit", minute:"2-digit"}) ;
  }

  overlayHtml = (feature: Feature) => {
    if (feature && (feature.getProperties() as any).featureData?.animalId) {
      const data: GPSEarTag = (feature.getProperties() as any).featureData;
      const animal = this.currentState.snapshot.livestock.animals.find(a => a.id == data.animalId);
      const animalType = this.currentState.snapshot.livestock.animalTypes.find(t => t.id == animal.typeId);
      let lot = this.currentState.snapshot.livestock.lots.find(l => l.id == animal.lotId);
      const lotName = data.lot ? data.lot.name : (lot ? lot.name : 'N/A');

      if (this.isHistory) {
        return `
        <div class="gps-ear-tag-popup">
            <div class="main marker-item">
                <div class="highlight">TAG #${animal.tagNumber} (${animalType.title})</div>
                ${lotName ? '<div class="boundary-description">LOT: '+lotName+'</div>' : '' }
                <div class="">${this.getDateTime(data.locationCreatedAt)}</div>
            </div>
        </div>
        `;
      } else {
        // console.log('hasLivestockPermission:' +this.lsPermission)
        let overlay = `
          <div class="gps-ear-tag-popup">
              <div class="main marker-item">
                  <div class="highlight">TAG #${animal.tagNumber} (${animalType.title})</div>
                  ${lotName ? '<div class="boundary-description">LOT: '+lotName+'</div>' : '' }
                  <div class="">${this.getDateTime(data.locationCreatedAt)}</div>
                  <div class="toolbar-row">
                    <a class="animal-details-btn" data-animal-idx="">${this.editorBtn}</a>`;
        overlay += this.lsPermission ? `<a class="gps-history-btn">${this.historyBtn}</a>` : ``;
        overlay +=  `</div></div></div>`;
        return overlay;
      }

    }
    return null;
  }

  baseStyle = (feature: Feature) => {
    return this.getFeatureStyle(feature as any);
  }

  selectedStyle = (feature: Feature) => {
    if (feature.getProperties().featureData?.animalId) {
      return this.getFeatureStyle(feature as any);
    }
    return this.getLineFeatureStyle();
  }

  appendCtrls() {
    const mapElement = document.getElementById('map');
    const controlContainer = document.createElement('div');
    mapElement.append(controlContainer);
    controlContainer.setAttribute('id', 'gps-control-container');
    let closeBtn = document.createElement('button');
    closeBtn.innerHTML = "Close history";
    closeBtn.setAttribute('id', 'gps-close-history');
    closeBtn.setAttribute('class', 'edit-poly mat-button-base mat-flat-button mat-primary');
    closeBtn.onclick = this.closeHistory;
    controlContainer.append(closeBtn);
    this.changeHideLineBtnCtrls(true);
  }

  changeHideLineBtnCtrls(isHide) {
    let hideLineBtn = document.getElementById('gps-hide-line');

    if (!hideLineBtn) {
      hideLineBtn = document.createElement('button');
      hideLineBtn.setAttribute('id', 'gps-hide-line');
      hideLineBtn.setAttribute('class', 'edit-poly mat-button-base mat-flat-button mat-primary');
    }

    if (isHide) {
      hideLineBtn.innerHTML = "Hide line";
      hideLineBtn.onclick = this.hideLineFeature;
    } else {
      hideLineBtn.innerHTML = "Show line";
      hideLineBtn.onclick = this.unHideLineFeature;
    }

    const controlContainer = document.getElementById('gps-control-container');
    controlContainer.append(hideLineBtn);
  }

  closeHistory = () => {
    const controlContainer = document.getElementById('gps-control-container');
    controlContainer.remove();
    this.isHistory = false;

    this.livestockBadgeLayerService.show(true);
    this.freeLineLayerService.show(true);
    this.userMarkerLayerService.show(true);
    
    this.updateToLatest();
  }


  showHistory(earTagId, timeFrame) {
    this.gpsEarTagSubscription = this.gpsEarTagService.getPositionHistoryOfTag({tagId: earTagId, timeFrame: timeFrame}).subscribe(tags => {
      if (tags?.length) {
        this.isHistory = true;
        this.updateLayer(tags);
        this.mapOperationsService.zoomToLocation(tags[0].locationLat, tags[0].locationLng, 15);
        this.appendCtrls();
      }
    });
  }

  clearSubscription() {
    if (this.gpsEarTagSubscription) {
      this.gpsEarTagSubscription.unsubscribe();
    }
    if (this.gpsTimeFrameSubscription) {
      this.gpsTimeFrameSubscription.unsubscribe();
    }
    this.lsPermissionSubscription?.unsubscribe();
  }

  readonly historyBtn = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M75 75L41 41C25.9 25.9 0 36.6 0 57.9V168c0 13.3 10.7 24 24 24H134.1c21.4 0 32.1-25.9 17-41l-30.8-30.8C155 85.5 203 64 256 64c106 0 192 86 192 192s-86 192-192 192c-40.8 0-78.6-12.7-109.7-34.4c-14.5-10.1-34.4-6.6-44.6 7.9s-6.6 34.4 7.9 44.6C151.2 495 201.7 512 256 512c141.4 0 256-114.6 256-256S397.4 0 256 0C185.3 0 121.3 28.7 75 75zm181 53c-13.3 0-24 10.7-24 24V256c0 6.4 2.5 12.5 7 17l72 72c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-65-65V152c0-13.3-10.7-24-24-24z"/></svg>';

}
