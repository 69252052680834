import { Injectable } from '@angular/core';
import { Vector as VectorLayer } from 'ol/layer.js';
import { Vector as VectorSource } from 'ol/source.js';
import { FeatureSelectEventData } from '../../model';
import { Style, Fill, Stroke, Circle } from 'ol/style.js';
import { MapOperationService } from './map-operation.service';
import {Feature} from 'ol';
import { LayerBase } from './layer-base';
import { MapBubbleService } from './map-bubble-service';
import { Projection } from 'ol/proj';
import { DrawingService } from './drawing.service';
import { LineString, MultiPoint } from 'ol/geom';
import { MeasurementService } from './measurement.service';


const projectionCode = 'EPSG:3857';
const MEASUREMENT_LAYER = 'measurement';
const MEASUREMENT_LAYER_MAX_RESOLUTION = Infinity;


@Injectable({
  providedIn: 'root'
})
export class MeasurementLayerService extends LayerBase {

  constructor(
    mapOperationsService: MapOperationService,
    mapBubbleService: MapBubbleService,
    private drawingService: DrawingService,
    private measurementService: MeasurementService
  ) {
    super(mapOperationsService, mapBubbleService);
    this.layerName = MEASUREMENT_LAYER;
    this.maxResolution = MEASUREMENT_LAYER_MAX_RESOLUTION;
  }

  private source: VectorSource;
  private drawInteraction = null;

  protected overlayConfig = {
    align: 'center' as 'center',
    class: 'measurement-popup'
  };

  private lightStroke = new Style({
    stroke: new Stroke({
      color: [255, 255, 255, 0.6],
      width: 3,
      lineDash: [4, 8],
      lineDashOffset: 6
    })
  });

  private darkStroke = new Style({
    stroke: new Stroke({
      color: [0, 0, 0, 0.6],
      width: 3,
      lineDash: [4, 8]
    })
  });

  handleFeatureSelection(event) {
    super.handleFeatureSelection(event);
    const data: FeatureSelectEventData = event.eventData;
    if (data.layerName === MEASUREMENT_LAYER && data.selectedFeature && !this.isFeatureSelected(data.selectedFeature)) {
      this.source.removeFeature(data.selectedFeature);
    }
  }

  addMeasurementLayer() {
    const projection = new Projection({
      code: projectionCode
    });
    this.source = new VectorSource({
      //FIXME szabi: 2022-05-18 this projection is gone after lib update?
      // projection
    });
    this.layer = new VectorLayer({
      source: this.source,
      style: (feature, resolution) => {
        return this.getFeatureStyle(feature);
      },
    });
    this.addLayer();
    this.setLayerVisibility(false);
  }


  removeInteraction() {
    this.mapOperationsService.map.removeInteraction(this.drawInteraction);
    this.drawInteraction = null;
  }

  measure() {
    this.mapOperationsService.freezeMap(true);
    this.setLayerVisibility(true);
    this.drawingService.startDrawLine({
      onDrawFinish: coords => {
        if (coords) {
          const line = new LineString(coords);
          const feature = new Feature();
          feature.setGeometry(line);
          this.source.addFeature(feature);

          let props = feature.getProperties();
          props.featureData = {size: this.measurementService.getLength(feature)};
          feature.setProperties(props);

          this.selectFeature(feature);
          this.mapOperationsService.freezeMap(false);
        }
      },
      //FIXME szabi: 2022-05-18 this lineStyle is gone after lib update?
      // lineStyle: [this.lightStroke, this.darkStroke],
      pointColor: 'black'
    });
  }


  show = (show: boolean) => {
    if (show) {
      this.setLayerVisibility(true);
    } else {
      this.setLayerVisibility(false);
    }
  }


  getFeatureStyle(feature) {
    const coordinates = feature.getGeometry().getCoordinates();
    return [this.darkStroke, this.lightStroke, new Style({
      geometry: new MultiPoint(coordinates),
      image: new Circle({
        radius: 6,
        fill: new Fill({
          color: 'black'
        }),
        stroke: new Stroke({
          color: 'black'
        }),
      })
    })];
  }


  baseStyle = (feature: Feature) => {
    return this.getFeatureStyle(feature);
  }
  selectedStyle = (feature: Feature) => {
    return this.getFeatureStyle(feature);
  }


  overlayHtml = (feature: Feature) => {
    if (feature) {
      const data = (feature.getProperties() as any).featureData;
      if (data) {
        return `<div>
             <div>${data.size}&nbsp;mi</div>
             </div>
              `;
      }
    }
    return null;
  }

}
