import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginPage } from "./pages/login/login.page";
import { HomePage } from "./pages/home/home.page";
import { LoggedInGuard } from "./guards/logged-in.guard";
import { AuthGuard } from "./guards/auth.guard";
import { AttachmentsPage } from "./pages/attachments/attachments.page";
import { RanchHomePage } from './pages/ranches/ranch-home/ranch-home.page';

const routes: Routes = [
  {path: '', redirectTo: 'home', pathMatch: 'full'},
  {path: 'home', component: HomePage, canActivate: [AuthGuard]},
  {path: 'login', component: LoginPage, canActivate: [LoggedInGuard]},
  {path: 'attachments', component: AttachmentsPage, canActivate: [AuthGuard]},
  {
    path: 'admin',
    loadChildren: () => import('./pages/admin/admin.module').then(m => m.AdminModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'settings',
    loadChildren: () => import('./pages/settings/settings.module').then(m => m.SettingsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'livestock',
    loadChildren: () => import('./pages/livestock/livestock.module').then(m => m.LivestockModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'mapping',
    loadChildren: () => import('./pages/mapping/mapping.module').then(m => m.MappingModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'finance',
    loadChildren: () => import('./pages/finance/finance.module').then(m => m.FinanceModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'tasks',
    loadChildren: () => import('./pages/task/task.module').then(m => m.TaskModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'lrp',
    loadChildren: () => import('./pages/lrp/lrp.module').then(m => m.LRPModule),
    canActivate: [AuthGuard]
  },
  { path: 'ranches', component: RanchHomePage, canActivate: [AuthGuard] },


  // { path: 'agreement', component: AgreementAcceptPage },
  // { path: 'updateuser', component: UpdateUserPage },
  // { path: 'gaugedetails', loadChildren: './pages/gauge-details/gauge-details.module#GaugeDetailsPageModule' },
  // { path: 'prf', loadChildren: './pages/prf/prf.module#PrfModule' },
  // { path: 'coverages', loadChildren: './pages/prf/prf-coverage.module#PrfCoveragePageModule' },
  // { path: 'claims', loadChildren: './pages/prf/prf-claims.module#PrfClaimsPageModule' },
  // { path: 'rainfall', loadChildren: './pages/prf/prf-rainfall.module#PrfRainfallPageModule' },
  // {
  //   path: 'water-monitoring', loadChildren: './pages/water-monitoring/water-monitoring.module#WaterMonitoringPageModule'
  // },
  // { path: 'support', loadChildren: './pages/support/support.module#SupportPageModule' },
  // { path: 'dev', loadChildren: './pages/dev/dev.module#DevPageModule' },
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {

}
