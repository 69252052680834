import { EntityWithImage } from './Images';
import { UserDetail } from './UserModel';
import { Attachment } from "./AttachmentModel";

export class TaskEntity {
  id: number;
  title: string;
  notes: string;
  dueDate: any;
  locationLat: string;
  locationLng: string;
  status: TaskStatus;
  createdBy: UserDetail;
  categories: TaskCategory[];
  actions: TaskAction[];
  assignees: UserDetail[];
  attachments: Attachment[];
  urgent;
  ranchId: number;
  boundaryId?: number;
  lotId?: number;
  animalIds?: number[];
  itemId?: number;
  locationTitle?: string;
  locationArea?: string;
  createdAtWithTZ: string;
  updatedAtWithTZ: string;
  updateTS: string;
  dueDateWithTZ: string;

  actionDetailValues?: Array<TaskActionDetailValue>;
}

export class TaskStatus {
  description: string;
  id: number;
  title: string;
  defaultStatus: boolean;
}

export class TaskCategory {
  id: number;
  title: string;
  description: string;
}

export class TaskAction {
  id: number;
  title: string;
  taskCategoryId: number;
  description: string;
  startDate?: boolean;
  climateSmart?: boolean;
  details: Array<TaskActionDetail>;
}


export class TaskActionDetail {
  id: number;
  inputData: any[];
  inputType: string | number;
  label: string;
  inputSuffix?: string;
  multipliable?: boolean;
  taskActionId?: number;
}

export class TaskActionDetailValue {
  id: number;
  taskId: number;
  taskActionDetailId: number;
  actionDetailDataId: number;
  value: string;
  groupId?: number;
}

export class TaskRequest {
  id: any;
  title: string;
  notes: string;
  dueDate: string | Date;
  locationLat: string;
  locationLng: string;
  statusId: number;
  createdBy: number;
  categoryIds: any[];
  actionIds: any[];
  assigneeIds: any[];
  attachmentIds: any[];
  urgent;
  ranchId: number;
  boundaryId: number;
  lotId?: number;
  animalIds?: number[];
  itemId?: number;
  locationTitle: string;
  locationArea: string;
  updateTS: string;
  actionDetails?: Array<TaskActionDetailValue>;
}
