import { Action } from '@ngrx/store';
import { type } from "../types";
import { RanchRequest } from "../../model";
import { LivestockActionTypes } from "../livestock";


export const RanchesActionTypes = {
  LOAD_RANCHES_FOR_NAME: type('[net] [Ranch] Load ranches for name'),
  LOAD_RANCHES_FOR_NAME_SUCCESS: type('[net] [Ranch] Load ranches for name success'),
  LOAD_RANCHES_FOR_NAME_FAIL: type('[net] [Ranch] Load ranches for name fail'),
  LOAD_RANCHES_FOR_USER_SUCCESS: type('[net] [Ranch] Load ranches success for user'),
  LOAD_RANCHES_FOR_USER_FAIL: type('[net] [Ranch] Load ranches failed for user'),
  RESET_RANCHES: type('[net] [Ranch] Reset ranches for name'),
  LOAD_RANCH_USERS: type('[net] [Ranch] Load users for ranch'),
  LOAD_RANCH_USERS_SUCCESS: type('[net] [Ranch] Load users for ranch success'),
  LOAD_RANCH_USERS_FAIL: type('[net] [Ranch] Load users for ranch fail'),
  CREATE_NEW_RANCH: type('[net] [Ranch] Create new ranch'),
  CREATE_NEW_RANCH_FAIL: type('[net] [Ranch] Create new ranch failed'),
  CREATE_NEW_RANCH_SUCCESS: type('[net] [Ranch] Create new ranch success'),
  ASSIGN_NEW_USER_TO_RANCH_SUCCESS: type('[net] [Ranch] Assign new user to ranch success'),
  ASSIGN_NEW_USER_TO_RANCH_FAIL: type('[net] [Ranch] Assign new user to ranch failed'),
  DELETE_RANCH_FAIL: type('[net] [Ranch] Delete ranch failed'),
  DELETE_RANCH_SUCCESS: type('[net] [Ranch] Delete ranch success'),
  LOAD_COMMON_USERS_FOR_USER: type('[net] [Ranch] Load common users for user'),
  LOAD_COMMON_USERS_FOR_USER_FAILED: type('[net] [Ranch] Load common users for user failed'),
  SHARE_BOUNDARIES_WITH_RANCH: type('[net] [Ranch] Share boundaires with ranch'),
  SHARE_BOUNDARIES_WITH_RANCH_SUCCESS: type('[net] [Ranch] Share boundaires with ranch success'),
  SHARE_BOUNDARIES_WITH_RANCH_FAILED: type('[net] [Ranch] Share boundaires with ranch failed'),
  SET_SELECTED_RANCH_ID: type('[app] [Ranch] Set selected ranch'),

};

export class LoadRanchesForNameAction implements Action {
  type = RanchesActionTypes.LOAD_RANCHES_FOR_NAME;

  constructor(public readonly payload: string) {
  }
}

export class LoadRanchesForNameSuccessAction implements Action {
  type = RanchesActionTypes.LOAD_RANCHES_FOR_NAME_SUCCESS;

  constructor(public readonly payload: any[]) {
  }
}

export class LoadRanchesForNameFailedAction implements Action {
  type = RanchesActionTypes.LOAD_RANCHES_FOR_NAME_FAIL;

  constructor(public readonly payload: any) {
  }
}

// export class LoadRanchesForUserAction implements Action {
//   type = MobileAuthActionTypes.LOAD_RANCHES_FOR_USER;
//
//   constructor(public readonly payload: any) {
//   }
// }

export class LoadRanchesForUserSuccessAction implements Action {
  type = RanchesActionTypes.LOAD_RANCHES_FOR_USER_SUCCESS;

  constructor(public readonly payload: any) {
  }
}

export class LoadRanchesForUserFailedAction implements Action {
  type = RanchesActionTypes.LOAD_RANCHES_FOR_USER_FAIL;

  constructor(public readonly payload: any) {
  }
}

export class LoadRanchUsersAction implements Action {
  type = RanchesActionTypes.LOAD_RANCH_USERS;

  constructor(public readonly payload: string[]) {
  }
}


export class LoadRanchUsersSuccessAction implements Action {
  type = RanchesActionTypes.LOAD_RANCH_USERS_SUCCESS;

  constructor(public readonly payload: any) {
  }
}

export class LoadRanchUsersFailedAction implements Action {
  type = RanchesActionTypes.LOAD_RANCH_USERS_FAIL;

  constructor(public readonly payload: any) {
  }
}

export class ResetRanchesAction implements Action {
  type = RanchesActionTypes.RESET_RANCHES;

  constructor() {
  }
}

export class CreateNewRanchAction implements Action {
  type = RanchesActionTypes.CREATE_NEW_RANCH;

  constructor(public readonly payload: RanchRequest) {
  }
}

export class CreateNewRanchFailedAction implements Action {
  type = RanchesActionTypes.CREATE_NEW_RANCH_FAIL;

  constructor(public readonly payload: any) {
  }
}

export class CreateNewRanchSuccessAction implements Action {
  type = RanchesActionTypes.CREATE_NEW_RANCH_SUCCESS;

  constructor(public readonly payload: any) {
  }
}

export class AssignNewUserToRanchSuccess implements Action {
  type = RanchesActionTypes.ASSIGN_NEW_USER_TO_RANCH_SUCCESS;

  constructor(public readonly payload: any[]) {
  }
}

export class AssignNewUserToRanchFailed implements Action {
  type = RanchesActionTypes.ASSIGN_NEW_USER_TO_RANCH_FAIL;

  constructor(public readonly payload: any) {
  }
}

export class DeleteRanchFailedAction implements Action {
  type = RanchesActionTypes.DELETE_RANCH_FAIL;

  constructor(public readonly payload: any) {
  }
}

export class DeleteRanchSuccessAction implements Action {
  type = RanchesActionTypes.DELETE_RANCH_SUCCESS;

  constructor(public readonly payload: any) {
  }
}

export class LoadCommonUsersForUserAction implements Action {
  type = RanchesActionTypes.LOAD_COMMON_USERS_FOR_USER;

  constructor(public readonly payload: any) {
  }
}

export class LoadCommonUsersForUserFailedAction implements Action {
  type = RanchesActionTypes.LOAD_COMMON_USERS_FOR_USER_FAILED;

  constructor(public readonly payload: any) {
  }
}


export class ShareBoundariesWithRanchAction implements Action {
  type = RanchesActionTypes.SHARE_BOUNDARIES_WITH_RANCH;

  constructor(public payload: RanchRequest) {
  }
}

export class ShareBoundariesWithRanchFailedAction implements Action {
  type = RanchesActionTypes.SHARE_BOUNDARIES_WITH_RANCH_FAILED;

  constructor(public payload: any) {
  }
}

export class ShareBoundariesWithRanchSuccessAction implements Action {
  type = RanchesActionTypes.SHARE_BOUNDARIES_WITH_RANCH_SUCCESS;

  constructor(public payload: any) {
  }
}

export class SetSelectedRanchIdAction implements Action {
  type = RanchesActionTypes.SET_SELECTED_RANCH_ID;

  constructor(public payload: string | number) {
  }
}


export declare type RanchesActions =
  LoadRanchesForNameAction
  | LoadRanchesForNameSuccessAction
  | LoadRanchesForNameFailedAction
  | LoadRanchesForUserFailedAction
  | LoadRanchUsersAction
  | LoadCommonUsersForUserAction
  | LoadRanchesForUserSuccessAction
  | LoadRanchUsersSuccessAction
  | CreateNewRanchSuccessAction
  | CreateNewRanchAction
  | CreateNewRanchFailedAction
  | LoadRanchUsersFailedAction
  | AssignNewUserToRanchSuccess
  | AssignNewUserToRanchFailed
  | DeleteRanchFailedAction
  | LoadCommonUsersForUserFailedAction
  | DeleteRanchSuccessAction
  | ShareBoundariesWithRanchAction
  | ShareBoundariesWithRanchFailedAction
  | ShareBoundariesWithRanchSuccessAction
  | SetSelectedRanchIdAction;


