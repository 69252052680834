import { Component, Inject, OnInit } from "@angular/core";
import {
   MAT_DIALOG_DATA,
   MatDialog,
   MatDialogRef
} from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import { PortalAppState } from "../../store/reducer";
import { TranslateService } from "@ngx-translate/core";
import {
  Attachment,
  CommonState,
  CurrentState,
  DeleteAttachmentSuccessAction,
  EntityWithImage,
  LoadAnimalByIdAction,
  LoadExpenseByIDAction,
  LoadItemByIdAction,
  LoadMarkerAction,
  ReloadTaskAction,
  ServicesCommon,
  SetSelectedAttachmentAction,
  UpdateAttachmentAction,
  UpdateAttachmentsAction
} from "common-lib";
import { Observable, of } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { catchError, filter, map } from "rxjs/operators";
import { DialogComponent } from "../../components/dialog/dialog.component";

@Component({
  selector: 'attachments-page',
  templateUrl: './attachments.page.html',
  styleUrls: ['./attachments.page.scss']
})
export class AttachmentsPage implements OnInit {
  selectedAttachments: Observable<EntityWithImage[]>;
  description = '';
  newAttachment;
  private file: any;
  private imageSrc: string | ArrayBuffer;
  wip: boolean = false;

  constructor(protected store: Store<PortalAppState>,
              public dialogRef: MatDialogRef<AttachmentsPage>,
              @Inject(MAT_DIALOG_DATA) public data: {},
              private ts: TranslateService,
              private servicesCommon: ServicesCommon,
              public dialog: MatDialog,
              private translateService: TranslateService,
              private http: HttpClient,
              private currentState: CurrentState<CommonState>,) {

  }

  ngOnInit(): void {
    this.selectedAttachments = this.store.select(state => state.attachments.attachments).pipe(filter(a => !!a), map(a => {
      return [].concat(a.map(b => {
        return {...b};
      }));
    }));
  }

  attachmentClicked(attachment: any) {
    console.log('clicked');
    console.log(attachment);
  }

  addAttachment() {
    console.log('addAttachment clicked');
  }

  deleteAttachment(attachment: EntityWithImage) {
    console.log('clicked');
    console.log(attachment);
    const type = this.currentState.snapshot.attachments.type;
    const moduleEntityId = this.currentState.snapshot.attachments.id;
    this.http.delete(
      environment.backend + `/private/attachments/${attachment.id}`,
      {headers: this.servicesCommon.getAuthHeaders()}
    ).pipe(catchError(error => {
      console.error(error);
      return of(null);
    })).subscribe((response: number) => {
      if (!!response) {
        let attachmentsAfterDelete = this.currentState.snapshot.attachments.attachments.filter(a => a.id != response);
        console.log('attachmentsAfterDelete');
        console.log(attachmentsAfterDelete);
        this.store.dispatch(new UpdateAttachmentsAction(attachmentsAfterDelete));
        this.store.dispatch(new SetSelectedAttachmentAction(null));
        let payload = {
          attachmentId: response,
          type: type as any,
          moduleEntityId: +moduleEntityId
        };
        this.store.dispatch(new DeleteAttachmentSuccessAction(payload));
        this.reloadRelatedModuleEntity();
        this.close(null, attachment);
      }
    });
  }

  updateAttachment(attachment: EntityWithImage) {
    console.log('Update attachment clicked');
    console.log(attachment)
    let request = {description: attachment.description};
    this.http.put(
      environment.backend + `/private/attachments/${attachment.id}`,
      request,
      {headers: this.servicesCommon.getAuthHeaders()}
    ).pipe(catchError(error => {
      console.error(error);
      return of(null);
    })).subscribe((response: Attachment) => {
      if (!!response) {
        this.currentState.snapshot.attachments.attachments.map(a => {
          if (a.id == response.id) {
            return response;
          }
          return a;
        });
        this.store.dispatch(new UpdateAttachmentAction(response));
        this.reloadRelatedModuleEntity();
      }
    });
  }

  close(newAttachment: EntityWithImage = null, deletedAttachment: EntityWithImage = null) {
    this.dialogRef.close({newAttachment, deletedAttachment});
  }

  private reloadRelatedModuleEntity() {
    const type = this.currentState.snapshot.attachments.type;
    const moduleEntityId = this.currentState.snapshot.attachments.id;
    if (!moduleEntityId) {
      return;
    }
    console.log('reloadRelatedModuleEntity called');
    if (type == 'animal' as 'livestock/profitloss/expense' | 'livestock/animal' | 'task') {
      // id = this.currentState.snapshot.livestock.selectedAnimal;
      this.store.dispatch(new LoadAnimalByIdAction(moduleEntityId));
    } else if (type == 'task' as 'livestock/profitloss/expense' | 'livestock/animal' | 'task') {
      // id = this.currentState.snapshot.tasks.selectedTask.id;
      this.store.dispatch(new ReloadTaskAction(moduleEntityId));
    } else if (type == 'profit_loss' as 'livestock/profitloss/expense' | 'livestock/animal' | 'task') {
      // id = this.currentState.snapshot.livestock.selectedProfitLoss;
      this.store.dispatch(new LoadExpenseByIDAction(moduleEntityId));
    } else if (type == 'item' as 'livestock/profitloss/expense' | 'livestock/animal' | 'task') {
      // id = this.currentState.snapshot.finance.selectedItem;
      this.store.dispatch(new LoadItemByIdAction(moduleEntityId));
    } else if (type == 'marker' as 'livestock/profitloss/expense' | 'livestock/animal' | 'task') {
      // id = this.currentState.snapshot.finance.selectedItem;
      this.store.dispatch(new LoadMarkerAction(moduleEntityId));
    }
  }

  save() {
    const moduleEntityId = !!this.currentState.snapshot.attachments.id ? this.currentState.snapshot.attachments.id : -1;
    // if (!moduleEntityId) {
    //   this.close(this.file);
    //   return;
    // }
    this.wip = true;
    const formData = new FormData();
    formData.append("file", this.file);
    formData.append("notes", this.description);
    const ranchId = this.currentState.snapshot.ranches.selectedRanchId;
    const type = this.currentState.snapshot.attachments.type;
    const path = `/private/attachments/upload/ranch/${ranchId}/module/${type}/${moduleEntityId}`;
    const url = environment.backend + path;
    const token = this.currentState.snapshot.user.token;
    const headersConst = {
      'Authorization': token, 'Accept-Language': 'en'
    };
    const options = {
      headers: headersConst
    };
    const upload$ = this.http.post(url, formData, options);
    upload$.pipe(catchError(error => {
      console.error(error);
      this.wip = false;
      return of(null);
    })).subscribe(r => {
      console.log(r);
      if (!!r) {
        this.wip = false;
        this.showSuccessDialog(this.translateService.instant('DIALOG.MSG.ATTACHMENT_UPLOAD_SUCCESS'), r);
      }
    });
  }

  showSuccessDialog(msg: string = null, uploadedAttachment) {
    let title = this.translateService.instant('DIALOG.TITLE.SUCCESS');
    let message = !msg ? this.translateService.instant('DIALOG.MSG.ATTACHMENT_UPLOAD_SUCCESS') : msg;
    let closeLabel = this.translateService.instant('BUTTON.CLOSE');

    const dialogRef = this.dialog.open(DialogComponent, {
      panelClass: "success-dialog",
      height: '300px',
      width: '300px',
      data: {
        header: title,
        content: message,
        primaryBtnLabel: closeLabel,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      this.reloadRelatedModuleEntity();
      this.close(uploadedAttachment);
    });

  }


  onFileSelected(event) {
    console.log(event);
    this.file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = e => this.imageSrc = reader.result;
    reader.readAsDataURL(this.file);

  }

  descriptionChanged(event: Event) {
    console.log(event);
  }
}
