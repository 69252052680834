import { Component, HostListener, OnInit } from '@angular/core';
import { LoadAllTaskActionsAction, LoadAllTaskCategoriesAction, LoadAllTaskStatusesAction, LoadAvailableMoveBoundariesAction, PageItem } from "common-lib";
import { Observable } from "rxjs";
import { PortalAppState } from "../../store/reducer";
import { Store } from "@ngrx/store";
import { map } from "rxjs/operators";
import { Router } from '@angular/router';

@Component({
  selector: 'home-page',
  templateUrl: './home.page.html',
  styleUrls: ['./home.page.scss']
})
export class HomePage implements OnInit {

  availableEntryPages: Observable<PageItem[]>;
  breakpoint = 4;

  constructor(
    private store: Store<PortalAppState>,
    private router: Router
    ) {
    this.availableEntryPages = this.store.select(state => state.uiState.availableEntryPages).pipe((map(items => {
      return items.filter(item => item.displayOnHomePage);
    })));
    this.getScreenSize();
  }

  ngOnInit() {
    this.store.dispatch(new LoadAllTaskCategoriesAction());
    this.store.dispatch(new LoadAllTaskActionsAction());
    this.store.dispatch(new LoadAllTaskStatusesAction());
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.breakpoint = Math.floor(window.innerWidth / 325);
  }


  navigate(url) {
    this.router.navigateByUrl(url);
  }
}
