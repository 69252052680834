import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { first, map } from 'rxjs/operators';
import * as _ from 'lodash';

import { Feature } from 'ol';
import { Fill, Stroke, Style, Text } from 'ol/style.js';

import { LayerBase } from './layer-base';
import { MapOperationService } from './map-operation.service';
import { MapBubbleService } from './map-bubble-service';
import { MeasurementService } from './measurement.service';
import LayerZIndexConfig from './layer-zindex-config';
import { CommonState } from '../../store/reducer';
import { CurrentState } from '../../store/current-state';
import { IncreaseParcelUsage } from '../../store/user';
import { CommonConstants } from '../../app.constants';
import { MVT } from "ol/format";
import { VectorTile } from "ol/source";
import VectorTileLayer from "ol/layer/VectorTile";
import RenderFeature, { toFeature } from "ol/render/Feature";

const PARCEL_LAYER = 'parcels';
const MAX_PARCEL_LAYER_RESOLUTION = 8;
const COLOR = '113, 206, 69';
const COLOR_SELECTED = '255, 206, 69';

@Injectable({
  providedIn: 'root'
})
export class ParcelLayerService extends LayerBase {

  private selectedParcelIds = {};
  private selectionLayer: VectorTileLayer;

  constructor(
    private store: Store<CommonState>,
    private currentState: CurrentState<CommonState>,
    mapOperationsService: MapOperationService,
    mapBubbleService: MapBubbleService,
    private measurementService: MeasurementService
  ) {
    super(mapOperationsService, mapBubbleService);
    this.layerName = PARCEL_LAYER;
    this.maxResolution = MAX_PARCEL_LAYER_RESOLUTION;
  }

  addParcelLayer() {
    const key = this.currentState.snapshot.mappings.properties.parcelLayerApiKey;
    const parcelTileSourceRegid = new VectorTile({
      format: new MVT(),
      url:
        'https://tiles.regrid.com/api/v1/parcels/{z}/{x}/{y}.mvt?token=' +
        key,
    });
    this.layer = new VectorTileLayer({
      maxResolution: MAX_PARCEL_LAYER_RESOLUTION,
      source: parcelTileSourceRegid,
      renderMode: 'vector',
      visible: true,
      style: (feature, resolution) => {
        return this.getFeatureStyle(feature, 'transparent');
      },
    });
    this.layer.on('change', e => {
      if (((e.target.getProperties()).source).getState() === 'ready') {
        this.mapOperationsService.fireEvent('loading', false, null);
      }
    });
    let that = this;
    this.selectionLayer = new VectorTileLayer({
      map: this.mapOperationsService.map,
      renderMode: 'vector',
      source: this.layer.getSource(),
      style: function (feature) {
        const values = feature.getProperties();
        let fid = values.fid;
        if (fid in that.selectedParcelIds) {
          return that.selectedStyle(feature as Feature);
        } else {
          return that.getFeatureStyle(feature, 'transparent');
        }
      },
    });
    this.addLayer();
    this.setLayerVisibility(false);
  }

  getFeatureStyle(feature, fill) {
    const values = feature.getProperties();
    let text = !!values.owner ? values.owner : 'Unknown';
    return new Style({
      text: new Text({
        textAlign: 'center',
        textBaseline: 'middle',
        text,
        font: '10px Calibri,sans-serif',
        fill: new Fill({
          color: '#000'
        }),
        stroke: new Stroke({
          color: '#fff',
          width: 3
        })
      }),
      fill: new Fill({
        color: fill
      }),
      stroke: new Stroke({
        color: `rgb(${COLOR})`,
        width: 1
      })
    });
  }

  baseStyle = (feature: Feature) => {
    return this.getFeatureStyle(feature, 'transparent');
  };

  selectedStyle = (feature: Feature) => {
    return this.getFeatureStyle(feature, `rgba(${COLOR_SELECTED}, .5)`);
  };

  protected deSelectFeature(featureToDeselect) {
    let deSelectFeature = super.deSelectFeature(featureToDeselect);
    const values = featureToDeselect.getProperties();
    let fid = values.fid;
    delete this.selectedParcelIds[fid];
    this.mapBubbleService.hide(featureToDeselect);
    deSelectFeature.forEach(f => {
      this.mapBubbleService.hide(f);
    })
    this.selectionLayer.changed();
    return deSelectFeature;
  }

  overlayHtml = (feature: any) => {
    const values = feature.getProperties();
    let title = !!values.owner ? values.owner : 'Unknown';
    let owner = !!values.owner ? values.owner : (!!values.ownfrst && !!values.ownlast) ? values.ownfrst + ' ' + values.ownlast : ''
    + !!values.owntype ? ' (' + values.owntype + ')' : '';
    owner = !!owner ? owner.trim() : owner;
    title = owner;
    const state = !!values.state2 ? values.state2.toUpperCase() : '';
    let address = !!values.address ? values.address : (!!values.saddno && !!values.saddpref && !!values.saddstr && !!values.saddsttyp) ? values.saddno + ' ' + values.saddpref + ' ' + values.saddstr + ' ' + values.saddsttyp : '';
    address = !!address ? address.trim() : address;
    let city = (!!values.szip ? values.szip : '') + (!!values.county ? ' ' + values.county.toUpperCase() : '') + (!!values.scity ? ' ' + _.startCase(values.scity.toLowerCase()) : '');
    city = city ? city.trim() : city;
    let fullAddress = state + (!!city ? (' ' + city) : '') + (!!address ? (' ' + address) : '');
    fullAddress = !!fullAddress ? fullAddress.trim() : fullAddress;
    if (!fullAddress || fullAddress.length < 1) {
      fullAddress = 'No information';
    }
    return `<div class="parcel-popup">
      <div class="highlight">${title}</div>
      <div>Area: ` + this.measurementService.getBoundaryArea(feature) + `</div>
      <div>Address: ` + fullAddress + `</div>` +
      `<div class="toolbar-row"><a class="edit-feature">
        ${this.boundaryBtn}
       </a>
       </div></div>`;
  };

  hideBubbles() {
    console.log('hideBubbles');
    console.log(this.selectedFeatures);
    this.selectedFeatures.filter(feature => feature.getProperties().layer == this.layerName).forEach((feature) => {
      this.mapBubbleService.hide(feature);
    });
  }

  showBubbles() {
    console.log('showBubbles');
    console.log(this.selectedFeatures);
    this.selectedFeatures.filter(feature => feature.getProperties().layer == this.layerName).forEach((feature) => {
      this.mapBubbleService.show(feature);
    });
  }


  protected handleFeatureSelection(event) {
    if (event.eventData && event.eventData.layerName == 'parcels') {
      const values = event.eventData.selectedFeature.getProperties();
      let fid = values.fid;
      this.selectedParcelIds[fid] = event.eventData.selectedFeature;
      // console.log('handleFeatureSelection');
      // console.log(event);
      let data = event.eventData;
      if (data.selectedFeature instanceof RenderFeature) {
        let selectedFeature = toFeature(data.selectedFeature);
        // this.selectedFeatures.push(data.selectedFeature);
        event.eventData.selectedFeature = selectedFeature;
      }
      const subscription = this.canReadParcel().subscribe(result => {
        console.log('Parcel read obs fired: ' + JSON.stringify(result));
        if (result) {
          if (result.canRead) {
            super.handleFeatureSelection(event);
            let source = this.selectionLayer.getSource();

            //it means the user can read because he hasnt reached the limit. Has not got the roles.
            if (!result.tier2 && !result.tier3) {
              this.store.dispatch(new IncreaseParcelUsage());
            }
            this.selectionLayer.changed();
          }
          if (!result.canRead) {
            this.hideBubbles();
            this.deSelectAll();
            this.mapOperationsService.fireEvent('parcel_tier1_expired', false, null);
          }
        }
      });
      if (subscription) {
        subscription.unsubscribe();
      }
    } else {
      super.handleFeatureSelection(event);
    }
  }

  deSelectAll() {
    super.deSelectAll();
    this.selectedParcelIds = {};
    this.selectionLayer.changed();
  }

  show = (show: boolean) => {
    this.setLayerVisibility(show);
  };

  setLayerVisibility(visible: boolean) {
    if (this.layer) {
      const current = this.layer.getVisible();
      if (visible !== current) {
        this.layer.setVisible(visible);
        this.selectionLayer.setVisible(visible);
        if (!visible) {
          this.hideBubbles();
        } else {
          this.showBubbles();
        }
        this.layer.setZIndex(visible ? LayerZIndexConfig[this.layerName] : 0);
      }
    }
  }

  private canReadParcel() {
    let numberObservable = this.store.select(state => state.user.userDetails.nrOfParcelUsage).pipe(
      first()
    );
    if (this.currentState.snapshot.dev.unlimitedParcelUsage) {
      numberObservable = this.store.select(state => state.user.userDetails.nrOfParcelUsage).pipe(
        map(n => 5),
        first());
    }
    let aclsObservable = this.store.select(state => state.user.userDetails.aclsOfUser);
    const combined = combineLatest([numberObservable, aclsObservable]).pipe(map(([numberOfUsage, acls]) => {
      const userTiers = this.getUserTier(acls);
      if (this.currentState.snapshot.dev.forcedParcelTierBuy) {
        return {canRead: false, tier2: userTiers.tier2Access, tier3: userTiers.tier3Access, numberOfUsage};
      }
      if (!numberOfUsage) {
        return {canRead: true, tier2: userTiers.tier2Access, tier3: userTiers.tier3Access, numberOfUsage};
      }
      if (numberOfUsage <= CommonConstants.PARCEL.PARCEL_MAX_USAGE || userTiers.tier2Access || userTiers.tier3Access) {
        return {canRead: true, tier2: userTiers.tier2Access, tier3: userTiers.tier3Access, numberOfUsage};
      }
      return {canRead: false, tier2: userTiers.tier2Access, tier3: userTiers.tier3Access, numberOfUsage};
    }));
    return combined;
    // return of({canRead: false, tier2: null, tier3: null});
  }

  private getUserTier(acls) {
    let tier2Access = false;
    let tier3Access = false;
    for (const acl of acls) {
      let sysfunctionName = acl.sysfunctionName;
      let action = acl.actionName;
      const hasAccess = action.toLowerCase() == 'access';
      if (sysfunctionName.toLowerCase() == CommonConstants.PARCEL.PARCEL_TIER_2_USAGE_SYSTEM_FUNCTION && hasAccess) {
        tier2Access = true;
      }
      if (sysfunctionName.toLowerCase() == CommonConstants.PARCEL.PARCEL_TIER_3_USAGE_SYSTEM_FUNCTION && hasAccess) {
        tier3Access = true;
      }
    }
    return {tier2Access, tier3Access};
  }

  protected getLayerSystemFuncion(): string {
    return CommonConstants.PARCEL.PARCEL_TIER_2_USAGE_SYSTEM_FUNCTION;
  }

  readonly boundaryBtn = `<svg xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:cc="http://creativecommons.org/ns#" xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#" xmlns:svg="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/2000/svg" id="svg8" version="1.1" viewBox="0 0 64.949768 72.803398" height="72.803398mm" width="64.949768mm">
  <g transform="translate(-54.995535,-40.492296)" id="layer1">
    <path id="path4518" class="stroke" d="M 111.125,50.181546 62.744048,59.630952 73.327381,106.12202 109.23512,96.294643 Z" style="fill:none;stroke-width:2.64583325;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"></path>
    <circle r="5" cy="59.331253" cx="64.536652" id="path4520" class="fill" style="stroke-width:4.77055883;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"></circle>
    <circle r="5" cy="50.033413" cx="110.40418" id="path4520-7" class="fill" style="stroke-width:4.77055883;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"></circle>
    <circle r="5" cy="94.400146" cx="109.60237" id="path4520-9" class="fill" style="stroke-width:4.77055883;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"></circle>
    <circle r="5" cy="103.75458" cx="73.253708" id="path4520-75" class="fill" style="stroke-width:4.77055883;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"></circle>
  </g>
</svg>`;
}

