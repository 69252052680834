import { Action } from '@ngrx/store';
import { type } from "../types";
import { ClimateSurveyQuestion, GPSEarTag, GPSTimeFrame } from "../../model";
import { ClimateSmartState } from "./climate_smart.reducer";


export const ClimateSmartActionTypes = {
  SET_SURVEY_QUESTIONS: type('[app] [ClimateSmart] Set climate smart questions'),
  LOAD_SURVEY_QUESTION: type('[app] [ClimateSmart] Load climate smart questions'),
};

export class SetClimateSmartSurveyQuestion implements Action {
  type = ClimateSmartActionTypes.SET_SURVEY_QUESTIONS;

  constructor(public payload: ClimateSurveyQuestion[]) {
  }
}

export class LoadClimateSmartSurveyQuestion implements Action {
  type = ClimateSmartActionTypes.LOAD_SURVEY_QUESTION;

  constructor(public payload: { userId: number }) {
  }
}

export declare type ClimateSmartActionTypes =
  SetClimateSmartSurveyQuestion | LoadClimateSmartSurveyQuestion;
