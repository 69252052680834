import { Action } from '@ngrx/store';
import { ActionWithPayload } from "../types";
import { ErrorActionTypes } from "./errors.actions";
// import { ActionWithPayload } from '../../types';
// import * as authentication from '../../authentication/authentication.actions';
// import * as user from '../../user/user.actions';

export interface ErrorsState {
  key?: String;
  defaultMessage?: String;
  messageTitle?: string;
  statusCode?: number;
  businessCode?: number
  rootCause?: any;
  translatedMessage?: String,
  additionalInfo?: any,
  ignorable?: boolean
}

export const errorInitialState: ErrorsState = {
  key: null,
  defaultMessage: null,
  messageTitle: null,
  statusCode: null,
  businessCode: null,
  rootCause: null,
  translatedMessage: null,
  additionalInfo: null,
  ignorable: false
};

export function reducer(state: ErrorsState = errorInitialState, action: Action): ErrorsState {

  switch (action.type) {
    // case user.GaugesActionTypes.LOAD_USER:
    // case user.GaugesActionTypes.LOAD_SWISS_PASS:
    // case authentication.GaugesActionTypes.LOGIN:
    case ErrorActionTypes.CLEAR_ERROR:
      // case user.GaugesActionTypes.REGISTER_LOYALTY_CARD:
      return errorInitialState;
    // case user.GaugesActionTypes.LOAD_USER_FAIL:
    // case user.GaugesActionTypes.LOAD_SWISS_PASS_FAIL:
    // case user.GaugesActionTypes.REGISTER_LOYALTY_CARD_FAIL:
    // 	const errorMessage = (action as ActionWithPayload<any>).payload;
    // 	return {
    // 		originalErrorMessage: null,
    // 		mappedErrorMessageKey: errorMessage,
    // 		originalErrorCode: null
    // 	};
    // case authentication.GaugesActionTypes.LOGIN_FAIL:
    // 	const loginFail = (action as authentication.LoginFailAction);
    // 	return {
    // 		originalErrorMessage: null,
    // 		mappedErrorMessageKey: loginFail.payload,
    // 		originalErrorCode: loginFail.errorCode
    // 	};
    case ErrorActionTypes.CREATE_ERROR:
      const error = (action as ActionWithPayload<any>).payload;
      if (error) {
        return {...error};
      }
    // 	else {
    // 		return {
    // 			originalErrorMessage: null,
    // 			mappedErrorMessageKey: error,
    // 			originalErrorCode: null
    // 		};
    // 	}
    default:
      return state;

  }
}
