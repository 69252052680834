import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { from, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from "rxjs/operators";
import { UserService } from "../../services/user.service";
import * as authentication from './authentication.actions';
import { AuthActionTypes } from './authentication.actions';
import { CommonFeBaseEffects } from "../common-fe-base.effects";
import { UserLogin } from "../../model";
import { CommonConstants } from "../../app.constants";

const TAG = 'AuthenticationEffects ';

@Injectable()
export class AuthenticationEffects extends CommonFeBaseEffects {

  @Effect()
  login: Observable<Action>;

  @Effect()
  logout: Observable<Action>;

  constructor(
    private actions: Actions,
    public userService: UserService) {
    super();

    this.login = this.actions
      .pipe(ofType(AuthActionTypes.LOGIN/*, userActionTypes.REGISTER_USER_SUCCESS*/)
        , map((action: authentication.LoginAction) => {
          console.log('AuthenticationEffects, login effect');
          return action.payload;
        })
        , switchMap((loginData: UserLogin) => this.loginUser(loginData)
          .pipe(map((loginResponse: any) => {
              let action = new authentication.LoginSuccessAction(loginResponse);
              return action;
            })
            , catchError(err => {
              console.error(TAG + '#loginEffect login failed:');
              if (err?.status == CommonConstants.SERVICE_ERRORS.AUTH.NO_CONNECTION || (
                err?.name == 'TimeoutError')) {
                return from([new authentication.LoginFailAction(CommonConstants.SERVICE_ERRORS.AUTH.NO_CONNECTION, {
                  error: err,
                  loginData: loginData
                })]);
              }
              return from([new authentication.LoginFailAction(err.status, {error: err, loginData: loginData})]);
            }))
        ));

    this.logout = this.actions
      .pipe(ofType(AuthActionTypes.LOGOUT)
        , map((action: authentication.LogoutAction) => {
          console.log('AuthenticationEffects, logout effect');
          return action.payload;
        })
        , switchMap(() => {
          return this.logoutUser().pipe(map((logoutResponse: Object) => new authentication.LogoutSuccessAction())
            , catchError(err => of(new authentication.LogoutSuccessAction())));
        })
      );
  }

  loginUser(loginData: UserLogin): Observable<Object> {
    return this.userService.loginUser(loginData);
  }

  logoutUser(): Observable<Object> {
    return this.userService.logoutUser();
  }
}
