import { Injectable } from '@angular/core';
// import { SQLiteObject } from "@awesome-cordova-plugins/sqlite";

@Injectable({
    providedIn: 'root'
})
export class PortalCacheService {

    constructor() {
    }
    addCacheEntry(key: string, value: any) {
        throw new Error('Method not implemented.');
    }
    getCacheEntry(key: string) {
        throw new Error('Method not implemented.');
    }
    getCacheKey(actionCacheKey: any, userId: any) {
        throw new Error('Method not implemented.');
    }



}
