import { MapOperationService } from './map-operation.service';
import { Style } from 'ol/style.js';
import { Feature } from 'ol';
import { MapBubbleService } from './map-bubble-service';
import LayerZIndexConfig from './layer-zindex-config';
import { MapEvent, FeatureSelectEventData, BubbleConfig } from '../../model';

export abstract class LayerBase {

  constructor(
    protected mapOperationsService: MapOperationService,
    protected mapBubbleService: MapBubbleService) {
  }


  private mapEventSubscription;
  protected layer: any;
  protected abstract baseStyle: (feature) => Style | Style[];
  protected abstract selectedStyle: (feature) => Style | Style[];
  protected zIndex: number;
  protected abstract show: (show: boolean) => void;
  protected layerName = '';
  protected maxResolution = Infinity;
  protected showLayer = false;
  protected abstract overlayHtml: (feature) => string | null;
  protected overlayConfig: BubbleConfig = {};
  protected isSelectable = true;

  selectedFeatures: Feature[] = [];


  addLayer = () => {
    this.mapOperationsService.addLayer(this.layer, this.layerName);
    if (!this.mapEventSubscription) {
      this.mapEventSubscription =
        this.mapOperationsService.events.subscribe(event => this.mapEventHandler(event)); // needed for this binding
    }
  }

  removeLayer() {
    try {
      if (this.layer) {
        this.mapOperationsService.map.removeLayer(this.layer);
        this.hideBubbles();
        this.layer = null;
        this.showLayer = false;
      }
    } catch (ex) {
    }
    if (this.mapEventSubscription) {
      this.mapEventSubscription.unsubscribe();
      this.mapEventSubscription = null;
    }
  }


  protected mapEventHandler(event: MapEvent) {
    switch (event.name) {
      case 'bubble-clicked': {
        if (this.isSelectable) {
          this.handleFeatureSelection(event);
        }
        break;
      }
      case 'feature-clicked': {
        if (event.eventData.position === 0 && this.isSelectable) { //top layer
          this.handleFeatureSelection(event);
        }
        break;
      }
      case 'resolution-changed': {
        this.handleResolutionChange(event);
        break;
      }
    }
  }


  protected handleResolutionChange(event) {
    if (this.showLayer) {
      if (event.eventData <= this.maxResolution) {
        if (event.originalEvent.oldValue > this.maxResolution) {
          this.show(true);
          this.showBubbles();
        }
      } else {
        if (event.originalEvent.oldValue < this.maxResolution) {
          this.show(false);
          this.mapOperationsService.fireEvent('wrong-resolution', this.layerName, null);
        }
      }
    }
  }

  private getOverlay(feature) {
    const overlayContent = this.overlayHtml(feature);
    if (overlayContent) {
      this.mapBubbleService.popup(overlayContent, feature, this.layerName, this.overlayConfig);
    }
  }

  protected deSelectFeature(featureToDeselect) {
    let removedFeatures = [];
    this.selectedFeatures = this.selectedFeatures.filter(feature => {
      const sameObject = feature === featureToDeselect;
      const same_ParcelId = (!!featureToDeselect.getProperties()?.ogc_fid && !!feature.getProperties()?.ogc_fid && featureToDeselect.getProperties().ogc_fid == feature.getProperties().ogc_fid);
      let isMatch = same_ParcelId || sameObject;
      if (isMatch) {
        removedFeatures.push(feature);
      }
      return !isMatch;
    });
    this.setDeSelectedStyle(featureToDeselect);
    return removedFeatures;
  }

  setDeSelectedStyle = featureToDeselect => {
    if (this.baseStyle) {
      featureToDeselect.setStyle(this.baseStyle(featureToDeselect));
    }
    this.mapBubbleService.remove(featureToDeselect);
  }

  protected selectFeature(featureToSelect) {
    const isSelected = this.isFeatureSelected(featureToSelect);
    if (!isSelected) {
      this.selectedFeatures = [...this.selectedFeatures, featureToSelect];
      this.getOverlay(featureToSelect);
      if (this.selectedStyle) {
        featureToSelect.setStyle(this.selectedStyle(featureToSelect));
      }
    }
  }

  deSelectAll() {
    this.selectedFeatures.forEach(this.setDeSelectedStyle);
    this.selectedFeatures = [];
  }

  hideBubbles() {
    if (!!this.layer.getSource()?.getFeatures()) {
      this.layer.getSource().getFeatures().forEach(
        feature => {
          this.mapBubbleService.hide(feature);
        });
    }
  }

  showBubbles() {
    if (!!this.layer.getSource()?.getFeatures()) {
      this.layer.getSource().getFeatures().forEach(
        feature => {
          this.mapBubbleService.show(feature);
        });
    }
  }

  protected isFeatureSelected(feature: Feature) {
    let found = this.selectedFeatures.find(f => {
      return f === feature || (!!f.getProperties()?.ogc_fid && !!feature.getProperties()?.ogc_fid && f.getProperties().ogc_fid == feature.getProperties().ogc_fid);
    });
    return !!found;
  }


  protected handleFeatureSelection(event) {
    const data: FeatureSelectEventData = event.eventData;
    if (data.layerName === this.layerName) {
      const isSelected = this.isFeatureSelected(data.selectedFeature);
      if (data.selectedFeature) {
        if (!isSelected) {
          this.selectFeature(data.selectedFeature);
        } else {
          this.deSelectFeature(data.selectedFeature);
        }
      }
    }
  }

  protected getLayerSystemFuncion() {
    return null;
  }

  toggleLayer() {
    this.showLayer = !this.showLayer;
    if (this.showLayer && this.mapOperationsService.getCurrentResolution() > this.maxResolution) {
      this.mapOperationsService.zoomTo(this.maxResolution);
    }
    this.show(this.showLayer);
    this.mapOperationsService.fireEvent('toggle-layer', {layerName: this.layerName, visible: this.showLayer}, null);
  }

  setLayerVisibility(visible: boolean) {
    if (this.layer) {
      const current = this.layer.getVisible();
      if (visible !== current) {
        this.layer.setVisible(visible);
        if (!visible) {
          this.deSelectAll();
        }
        this.layer.setZIndex(visible ? LayerZIndexConfig[this.layerName] : 0);
      }
    }
  }

  readonly editorBtn = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path d="M64 368v80h80l235.727-235.729-79.999-79.998L64 368zm377.602-217.602c8.531-8.531 8.531-21.334 0-29.865l-50.135-50.135c-8.531-8.531-21.334-8.531-29.865 0l-39.468 39.469 79.999 79.998 39.469-39.467z"></path>
    </svg>`;

  readonly settingsBtn = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="s-ion-icon">
        <path d="M413.967 276.8c1.06-6.235 1.06-13.518 1.06-20.8s-1.06-13.518-1.06-20.8l44.667-34.318c4.26-3.118 5.319-8.317 2.13-13.518L418.215 115.6c-2.129-4.164-8.507-6.235-12.767-4.164l-53.186 20.801c-10.638-8.318-23.394-15.601-36.16-20.801l-7.448-55.117c-1.06-4.154-5.319-8.318-10.638-8.318h-85.098c-5.318 0-9.577 4.164-10.637 8.318l-8.508 55.117c-12.767 5.2-24.464 12.482-36.171 20.801l-53.186-20.801c-5.319-2.071-10.638 0-12.767 4.164L49.1 187.365c-2.119 4.153-1.061 10.399 2.129 13.518L96.97 235.2c0 7.282-1.06 13.518-1.06 20.8s1.06 13.518 1.06 20.8l-44.668 34.318c-4.26 3.118-5.318 8.317-2.13 13.518L92.721 396.4c2.13 4.164 8.508 6.235 12.767 4.164l53.187-20.801c10.637 8.318 23.394 15.601 36.16 20.801l8.508 55.117c1.069 5.2 5.318 8.318 10.637 8.318h85.098c5.319 0 9.578-4.164 10.638-8.318l8.518-55.117c12.757-5.2 24.464-12.482 36.16-20.801l53.187 20.801c5.318 2.071 10.637 0 12.767-4.164l42.549-71.765c2.129-4.153 1.06-10.399-2.13-13.518l-46.8-34.317zm-158.499 52c-41.489 0-74.46-32.235-74.46-72.8s32.971-72.8 74.46-72.8 74.461 32.235 74.461 72.8-32.972 72.8-74.461 72.8z"></path>
        </svg>`;

}
