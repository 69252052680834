import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CommonState } from '../store/reducer';
import { CurrentState } from '../store/current-state';
import { ServicesCommon } from './services-common.service';
import { Image, EquipmentDto, UserMarker } from '../model';
import { ENVIRONMENT_TOKEN } from "../../environment/token-variables";

@Injectable()
export class ImagesService {


  constructor(
    private currentState: CurrentState<CommonState>,
    protected http: HttpClient,
    @Inject(ENVIRONMENT_TOKEN) private readonly environment,
    private servicesCommon: ServicesCommon) {
  }

  getMultipleCameraImages(equipmentIds: number[], date: string) {
    const cameraList = equipmentIds.join(',');
    if (date) {
      return this.servicesCommon.getAll<Image[]>(
        `/private/assetmonitor/equipment/camera/images?date=${date}&ids=${cameraList}`);
    }
  }


  getCameraImages(equipmentId: number, date: string) {
    if (date) {
      return this.servicesCommon.getAll<Image>(
        `/private/assetmonitor/equipment/camera/${equipmentId}/images?date=${date}`);
    }
    return this.servicesCommon.getAll<Image>(
      `/private/assetmonitor/equipment/camera/${equipmentId}/images`);
  }

  getFavoriteImages() {
    const userId = this.currentState.snapshot.user.id;
    return this.servicesCommon.getAll<Image>(
      `/private/assetmonitor/images/favorite/user/${userId}`);
  }

  addFavoriteImages(imageId: number | string) {
    const userId = this.currentState.snapshot.user.id;
    return this.http.post<Image>(
      this.environment.backend + `/private/assetmonitor/images/favorite/user/${userId}`,
      { imageId: imageId },
      { headers: this.servicesCommon.getAuthHeaders() });
  }

  removeFavoriteImages(imageId: number | string) {
    const userId = this.currentState.snapshot.user.id;
    return this.http.delete<any>(
      this.environment.backend + `/private/assetmonitor/images/favorite/user/${userId}/${imageId}`,
      { headers: this.servicesCommon.getAuthHeaders() });
  }

  getUserCameras(userId, loadSharedCameras = true) {
    const url = this.servicesCommon.getUrl(`/private/assetmonitor/user/${userId}/equipment/camera`);
    let params = new HttpParams();
    if (!loadSharedCameras) {
      params = params.append('loadSharedCameras', "false");
    } else {
      params = params.append('loadSharedCameras', "true");
    }
    const options = {
      headers: this.servicesCommon.getAuthHeaders(),
      params: params
    };
    return this.http.get<EquipmentDto[]>(url, options);
  }

  getUserQuickLook(userId) {
    return this.servicesCommon.getAll<Image>(`/private/assetmonitor/quicklook/user/${userId}`);
  }
}


