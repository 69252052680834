<mat-grid-list [cols]="breakpoint" rowHeight="400" class="homeScreen">
  <mat-grid-tile *ngFor="let tile of availableEntryPages | async" (click)="navigate(tile.url)">
    <div>
      <img class="img-responsive" src="{{ tile.logo }}">
    </div>
    <h2>
      {{tile.title | translate}}
    </h2>
  </mat-grid-tile>
</mat-grid-list>
