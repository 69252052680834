import { ActionWithPayload } from "../../types";
import { MapPreselectionActions, MapPreselectionActionTypes } from "./map-preselection.actions";


export interface MapPreselectionState {
  preselectedLocation: { locationLng: string, locationLat: string }
}

const offlineMapsInitialState: MapPreselectionState = {
  preselectedLocation: null
};

export function mapPreselectionReducer(state = offlineMapsInitialState, action: MapPreselectionActions & ActionWithPayload<any>): MapPreselectionState {
  let payload = action.payload;
  switch (action.type) {
    case MapPreselectionActionTypes.SET_PRESELECTED_LOCATION: {
      return {...state, preselectedLocation: payload?.location};
    }
    default: {
      return state;
    }
  }
}
