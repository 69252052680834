<mat-toolbar color="accent">

  <!-- <button mat-icon-button class="hamburger-menu" [matMenuTriggerFor]="menu">
    <mat-icon>menu</mat-icon>
  </button>

  <mat-menu #menu="matMenu">
    <ng-container *ngFor="let entryPage of availableEntryPages">
      <button mat-menu-item [routerLink]="entryPage.url">
        {{ entryPage.title | translate }}
      </button>
    </ng-container>
  </mat-menu> -->

  <span class="menu-spacer"></span>

  <div class="logo">
    <img src="assets/imgs/skull.png">
    <p>PASTURE PRO</p>
  </div>
  

  <div class="button-container">
    <ng-container *ngFor="let entryPage of availableEntryPages">
      <a mat-button [routerLink]="entryPage.url">
        {{ entryPage.title | translate }}
      </a>
    </ng-container>
  </div>

  <span class="fill-space"></span>
  
  <a *ngIf="userLoggedIn" mat-button (click)="logout()">
    Logout
  </a>
  <a *ngIf="!userLoggedIn" mat-button [routerLink]="'/login'">
    Login
  </a>

</mat-toolbar>
