import { Pipe, PipeTransform } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { PortalAppState } from "../../store/reducer";
import { CurrentState } from "common-lib";

@Pipe({
  name: 'authImage'
})
export class AuthImagePipe implements PipeTransform {

  constructor(
    private http: HttpClient,
    protected currentState: CurrentState<PortalAppState>
  ) {
  }

  async transform(src: string): Promise<any> {
    const token = this.currentState.snapshot.user.token;
    const headers = new HttpHeaders({'Authorization': `Bearer ${token}`});
    const imageBlob = await this.http.get(src, {headers, responseType: 'blob'}).toPromise();
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onloadend = () => resolve(
        reader.result as string);
      reader.readAsDataURL(imageBlob);
    });
  }
}
