import { FinanceCategory, FinanceEntity, ItemEntity, ItemType } from "../../model/FinanceModel";
import { ActionWithPayload } from "../types";
import { FinanceActionTypes } from "./finance.actions";
import { Action } from "@ngrx/store";


export interface FinanceState {
  items: Array<ItemEntity>,
  expenses: Array<FinanceEntity>,
  itemTypes: Array<ItemType>,
  recurringoptions: Array<any>,
  selectedItem: number,
  selectedExpense: number | string,
  categories: Array<FinanceCategory>,
}

export const financeInitialState: FinanceState = {
  items: [],
  expenses: [],
  itemTypes: [],
  recurringoptions: [],
  selectedItem: null,
  selectedExpense: null,
  categories: [],
}


export function reducer(state = financeInitialState, action: Action): FinanceState {
  const payload = (action as ActionWithPayload<any>).payload;
  let expenses;
  let items = [...state.items];

  switch (action.type) {

    case FinanceActionTypes.SET_SELECTED_ITEM:
      return {
        ...state,
        selectedItem: payload
      };
    case FinanceActionTypes.SET_SELECTED_EXPENSE:
      return {
        ...state,
        selectedExpense: payload
      };
    case FinanceActionTypes.LOAD_ITEM_TYPES_SUCCESS:
      return {
        ...state,
        itemTypes: payload
      };
    case FinanceActionTypes.LOAD_ALL_EXPENSE_SUCCESS:
      return {
        ...state,
        expenses: payload
      };
    case FinanceActionTypes.LOAD_ALL_ITEM_SUCCESS:
      return {
        ...state,
        items: payload
      };
    case FinanceActionTypes.LOAD_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: payload
      };
    case FinanceActionTypes.LOAD_RECURRING_OPTIONS_SUCCESS:
      return {
        ...state,
        recurringoptions: payload
      };
    case FinanceActionTypes.LOAD_ITEM_BY_ID_SUCCESS:
      items = state.items.map(i => {
        if (i.id == payload.id) {
          return payload;
        }
        return i;
      });
      return {
        ...state,
        items: items,
      };
    case FinanceActionTypes.ADD_NEW_EXPENSE_SUCCESS:
      expenses = [...state.expenses, payload];
      if (payload.relatedItemId) {
        let updatedItem = {...state.items.find(i => i.id == payload.relatedItemId)};
        updatedItem.relatedTransactionIds = [...updatedItem.relatedTransactionIds, payload.id];

        items = state.items.map(item => {
          if (item.id == updatedItem.id) {
            return updatedItem;
          }
          return item;
        });

      }

      // if (payload.animalIds?.length) {}

      return {
        ...state,
        expenses: expenses,
        items: items
      };
    case FinanceActionTypes.ADD_NEW_ITEM_SUCCESS:
      return {
        ...state,
        items: [...state.items, payload],
      };
    case FinanceActionTypes.DELETE_ITEM_SUCCESS:
      items = items.map(item => {
        if (item.id == payload.id) {
          return payload;
        }
        return item;
      });
      return {
        ...state,
        items: items
      };
    case FinanceActionTypes.DELETE_EXPENSE_SUCCESS:
      const removedExpId = payload;
      expenses = state.expenses.filter(e => e.id != removedExpId);
      return {
        ...state,
        expenses: expenses,
      };
    case FinanceActionTypes.UPDATE_EXPENSE_SUCCESS:
      expenses = state.expenses.map(e => {
        if (e.id == payload.id) {
          return payload;
        }
        return e;
      });

      return {
        ...state,
        expenses: expenses,
      };
    case FinanceActionTypes.UPDATE_ITEM_SUCCESS:
      items = state.items.map(i => {
        if (i.id == payload.id) {
          return payload;
        }
        return i;
      });
      return {
        ...state,
        items: items,
      };
    default:
      return state;
  }
}
