import { HttpErrorResponse } from "@angular/common/http";
import { Action } from "@ngrx/store";
import { Observable } from "rxjs";
import { ErrorHandler } from "../error-handler.helper";
import { ActionEffectFailedAction } from "./errors";

export class CommonFeBaseEffects {

  constructor() {

  }

  protected handleHttpError(err: HttpErrorResponse) {
    const actionEffectFailedAction = new ActionEffectFailedAction(err);
    return new Observable<Action>((observer) => {
      observer.next(actionEffectFailedAction);
      // observer.next(new errorActions.CreateErrorMessage(errorEntry));
      observer.complete()
    });
  }

  protected getErrorEntry(err) {
    return ErrorHandler.getErrorEntry(err);
  }
}
