import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'color-picker-component',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss'],
})



export class ColorPickerComponent implements OnInit {


  @Input()
  selectedColor: string;

  @Input()
  defaultColor: string;

  @Input()
  selectableColors: string[];

  @Output()
  selectedColorChange = new EventEmitter<string>();


  constructor() {
  }

  ngOnInit() {
    if (!this.selectedColor) {
      this.selectColor(this.defaultColor);
    }
  }

  selectColor(color: string) {
    this.selectedColor = color;
    this.selectedColorChange.emit(color);
  }

}
