import { Injectable } from '@angular/core';
import { Vector as VectorLayer } from 'ol/layer.js';
import { Vector as VectorSource } from 'ol/source.js';
import { Icon, Style } from 'ol/style.js';
import { MapOperationService } from './map-operation.service';
import {Feature} from 'ol';
import { LayerBase } from './layer-base';
import { MapBubbleService } from './map-bubble-service';
import Point from 'ol/geom/Point';
import { Projection, getPointResolution } from 'ol/proj';
import { UserMarker } from '../../model';
import { fromLonLat } from 'ol/proj';
import { UserMarkerConfigs } from './user-marker-config';
import { MappingsService } from '../mappings.service';

const projectionCode = 'EPSG:3857';
const GRAZING_LAYER = 'grazing';
const GRAZING_LAYER_MAX_RESOLUTION = 500;



@Injectable({
  providedIn: 'root'
})
export class GrazingLayerService extends LayerBase {

  radius: number;
  ringIncrement: number;

  constructor(
    mapOperationsService: MapOperationService,
    mapBubbleService: MapBubbleService,
    private markerConfigs: UserMarkerConfigs,
    private mappingService: MappingsService,
  ) {
    super(mapOperationsService, mapBubbleService);
    this.layerName = GRAZING_LAYER;
    this.maxResolution = GRAZING_LAYER_MAX_RESOLUTION;
    this.isSelectable = false;
  }

  private markerSource: VectorSource;

  addGrazingLayer(inc = null) {
    this.ringIncrement = inc;
    //FIXME szabi: 2022-05-18 this projection is gone after lib update?
    // const projection = new Projection({
    //   code: projectionCode
    // });
    this.markerSource = new VectorSource({
      // projection
    });
    this.layer = new VectorLayer({
      source: this.markerSource,
      style: (feature, resolution) => {
        return this.getFeatureStyle(feature);
      },
    });
    this.addLayer();
    this.setLayerVisibility(false);
  }

  updateLayer(userMarkers: UserMarker[], ringIncrement) {
    this.ringIncrement = ringIncrement;
    this.radius = ringIncrement * 4 * 1609.344;
    this.markerSource.clear();
    userMarkers.filter(marker => marker.typeId === this.markerConfigs.waterSourceMarkerType).forEach(this.addMarker);
  }

  addMarker = (marker: UserMarker) => {
    let feature = new Feature();
    feature.setProperties({
      featureData: marker
    })
    const point = new Point(fromLonLat([+marker.locationLng, +marker.locationLat]));
    feature.setGeometry(point);
    this.markerSource.addFeature(feature);
  }

  getExtent(feature) {
    const resolution = getPointResolution(this.mapOperationsService.map.getView().getProjection(),
      this.mapOperationsService.getCurrentResolution(), feature.getGeometry().getCoordinates());
    // console.log(resolution);
    return this.radius * 2 / resolution;
  }

  getFeatureStyle(feature) {
    let scale = this.getExtent(feature) / 200;
    return new Style({
      image: new Icon({
        src: 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(this.mappingService.getGrazingRings(this.ringIncrement)),
        anchor: [.5, .5],
        scale: scale
      })
    });
  }

  show = (show: boolean) => {
    this.setLayerVisibility(show);
  }

  baseStyle = (feature: Feature) => {
    return this.getFeatureStyle(feature);
  }
  selectedStyle = (feature: Feature) => {
    return this.getFeatureStyle(feature);
  }

  overlayHtml = () => null;

}
