import { Actions } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { Store } from "@ngrx/store";
import { CommonState } from "../reducer";
import { CommonFeBaseEffects } from "../common-fe-base.effects";
import { TranslateService } from "@ngx-translate/core";
import { CurrentState } from "../current-state";

import { NotificationsService } from "../../services/notifications.service";

@Injectable()
export class GpsEarTagsEffects extends CommonFeBaseEffects {

  constructor(
    private translateService: TranslateService,
    private store: Store<CommonState>,
    protected currentState: CurrentState<CommonState>,
    private actions: Actions, private notificationService: NotificationsService) {
    super();

  }
}
