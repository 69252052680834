import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Feature } from 'ol';
import { CommonState } from '../../store/reducer';
import { Boundary, MappingRequest } from '../../model';
import { CreateBoundariesFromParentAction,
  CreateBoundaryAction,
  DeleteBoundaryAction,
  UpdateBoundaryAction } from '../../store/mappings';
import { Observable, of } from 'rxjs';

const TITLECHARS = 'ABCDEFGHIJKLMNOPQSRTUVWZ';

@Injectable({
  providedIn: 'root'
})
export class PastureBoundaryDataService {
  editBoundaryPageOpened: boolean;

  constructor(
    private translateService: TranslateService,
    private store: Store<CommonState>,
  ) {
  }

  /**
   * Called after the user created a boundary.
   * @param data - newPolygons - the geometry (or geometries) of the newly added polygon (minus the intersections with other boundaries) in wkt format
   *             - modifiedPolygons - geometry - the geometry of the intersecting existing boundaries (minus the intersections with the new one) in wkt format
   *                                - parent - the intersecting boundary
   */
  createBoundary(geometry, title, ranchIds, trace=false) {
    this.store.dispatch(new CreateBoundaryAction({
      request: {
        title: title,
        description: '',
        geometry,
        sharedRanchIds: ranchIds,
        trace: trace,
      }
    }));
  }

  /**
   * Called after the user created a boundary from a parcel.
   * @param data - newPolygons - the geometry (or geometries) of the newly added polygon (minus the intersections with other boundaries) in wkt format
   *             - modifiedPolygons - geometry - the geometry of the intersecting existing boundaries (minus the intersections with the new one) in wkt format
   *                                - parent - the intersecting boundary
   *             - parcel - the parcel feature the new boundary is created from (needed for creating the title of the new boundary)
   */
  createBoundaryFromParcel(data: {
    boundaries: {
      newPolygons: string[],
      modifiedPolygons: {
        geometry: string
        parentId: string
      }[]
    }, ranchIds: [], parcel: Feature
  }) {
    data.boundaries.newPolygons.forEach((geometry) => {
      this.store.dispatch(new CreateBoundaryAction({
          request: {
            title: ((data.parcel.getProperties() && data.parcel.getProperties().owner) ?
              `${data.parcel.getProperties().owner}${this.translateService.instant('MAPPING.PARCEL_BOUNDARY')}` : ''),
            description: '',
            geometry,
            sharedRanchIds: data.ranchIds
          }
        }
      ));
    });
  }

  getBoundariesOfParent(parentId: number, boundaries: (Boundary & { parentId?: number })[]) {
    const result = {};
    result[parentId] = boundaries.filter(b => b.parentId === parentId);
    return result;
  }

  groupBoundariesByParent(boundaries: { parentId?: number }[]): {} {
    return boundaries.reduce((groups, {parentId}) =>
      groups[parentId] ? groups :
        {...groups, ...this.getBoundariesOfParent(parentId, boundaries)}, {});
  }


   handleEditResult(result: 'delete' | 'save' | 'cancel' | 'save-offline', boundary: Boundary): Observable<any> {
    if (result === 'delete' && !!boundary?.id) {
      this.store.dispatch(new DeleteBoundaryAction(boundary));
      return of('success');
    }
    if (result === 'save') {
      const request = {
        title: boundary.title,
        boundaryId: boundary.id,
        description: boundary.description,
        geometry: boundary.geometry,
        color: boundary.color,
        sharedRanchIds: boundary.sharedRanchIds,
        updateTS: boundary.updateTS
      } as MappingRequest;
      this.store.dispatch(new UpdateBoundaryAction(request));
      return of(request);
    }
    return of(null);
  }

  /**
   * Called after the user slit a boundaryparcel and the boundary-split.service calculated the the new boundaries' geometry.
   * @param added - added - the boundaries created by splitting
   *               - removed - the boundary that got split
   */
  handleBoundarySplit(added: Boundary[], removed: Boundary) {
    // console.log('handleBoundarySplit called');
    this.store.dispatch(
      new CreateBoundariesFromParentAction({
        request: {
          parent: removed,
          boundaries: added.map(({geometry}, idx) => ({
            geometry,
            title: `${removed.title} ${TITLECHARS[idx]}`,
            description: removed.description,
            color: removed.color,
          }))
        }
      })
    );
  }

  redrawBoundary(boundaryData: Boundary) {
    this.handleEditResult('save', boundaryData);
  }
}

