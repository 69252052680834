import { Action } from '@ngrx/store';
import { type } from "../types";
import { Acl, Affiliate, AffiliateMembership, PrfAssociation, Ranch, TermsAndConditions } from "../../model";


export const UserActionTypes = {
  //TODO cleanup actions
  LOAD_USER: type('[net] [User] Load'),
  LOAD_DONE: type('[net] [User] Load done'),
  LOAD_USER_SUCCESS: type('[net] [User] Load Success'),
  LOAD_USER_FAIL: type('[net] [User] Load Fail'),
  UPDATE_USER_SUCCESS: type('[net] [User] User Update Success'),
  UPDATE_USER_FAIL: type('[net] [User] User Update Fail'),
  UPDATE_PASSWORD_SUCCESS: type('[net] [User] Password Update Success'),
  UPDATE_PASSWORD_FAILED: type('[net] [User] Password Update Failed'),
  SEND_CONTACT_US_MSG_FAILED: type('[app] [User] send contact us message failed'),
  SET_PHONE_AND_EMAIL: type('[app] [User] Set email and phone'),
  INCREASE_PARCEL_LAYER_USAGE_COUNT: type('[net] [User] Increase parcel usage'),
  SET_PARCEL_LAYER_USAGE_COUNT: type('[net] [User] Set parcel usage'),
  DELETE_ACCOUNT: type('[net] [User] Delete account'),
  UPDATE_NOTIFICATION: type('[app] [User] notification update'),
  SET_MODULE_SUBSCRIPTIONS: type('[app] [User] Set Module subscriptions'),
  UPDATE_NOTIFICATION_SUCCESS: type('[app] [User] notification update success'),
  SET_USER_ACLS: type('[app] [User] Set user acls'),
  SET_OWNED_RANCH: type('[app] [User] Set owned ranch'),
  LOAD_AFFILIATES_ACTION: type('[app] [User] Load Affiliates'),
  UPDATE_TERMS_AND_CONDITIONS_ACTION: type('[app] [User] Update terms and conditions'),
  SET_TERMS_AND_CONDITIONS_ACTION: type('[app] [User] Set terms and conditions'),
  SET_PRF_ASSOCIATIONS: type('[app] [User] set prf associations'),
  LOAD_PRF_ASSOCIATIONS: type('[app] [User] load prf associations'),
  SET_AFFILIATE_MEMBERSHIPS: type('[app] [User] Set affiliate memberships')

};

export class LoadUserAction implements Action {
  type = UserActionTypes.LOAD_USER;

  constructor(public readonly payload: number) {
  }
}

export class LoadUserSuccessAction implements Action {
  type = UserActionTypes.LOAD_USER_SUCCESS;

  constructor(public payload: any) {
  }
}

export class LoadUserFailAction implements Action {
  type = UserActionTypes.LOAD_USER_FAIL;

  constructor(public payload: any) {
  }
}

export class UpdateUserSuccessUserAction implements Action {
  type = UserActionTypes.UPDATE_USER_SUCCESS;

  constructor(public readonly payload: any) {
  }
}

export class UpdateUserFailUserAction implements Action {
  type = UserActionTypes.UPDATE_USER_FAIL;

  constructor(public readonly payload: any) {
  }
}


export class UpdatePasswordSuccessUserAction implements Action {
  type = UserActionTypes.UPDATE_PASSWORD_SUCCESS;

  constructor(public readonly payload: any) {
  }
}

export class UpdatePasswordFailedUserAction implements Action {
  type = UserActionTypes.UPDATE_PASSWORD_FAILED;

  constructor(public readonly payload: any) {
  }
}

export class SendContactUsMessageFailed implements Action {
  type = UserActionTypes.SEND_CONTACT_US_MSG_FAILED;

  constructor(public readonly payload: any) {
  }
}

export class SetPhoneAndEmail implements Action {
  type = UserActionTypes.SET_PHONE_AND_EMAIL;

  constructor(public readonly payload: { phone: string, email: string }) {
  }
}

export class IncreaseParcelUsage implements Action {
  type = UserActionTypes.INCREASE_PARCEL_LAYER_USAGE_COUNT;

  constructor() {
  }
}

export class SetParcelUsage implements Action {
  type = UserActionTypes.SET_PARCEL_LAYER_USAGE_COUNT;

  constructor(public readonly payload: any) {
  }
}

export class UpdateNotificationAction implements Action {
  type = UserActionTypes.UPDATE_NOTIFICATION;

  constructor(public readonly payload: { id: number, show: boolean }) {
  }
}

export class UpdateNotificationSuccessAction implements Action {
  type = UserActionTypes.UPDATE_NOTIFICATION_SUCCESS;

  constructor(public readonly payload: any) {
  }
}

export class DeleteAccount implements Action {
  type: string = UserActionTypes.DELETE_ACCOUNT;

  constructor(public readonly payload: any) {
  }
}

export class SetModuleSubscriptions implements Action {
  type: string = UserActionTypes.SET_MODULE_SUBSCRIPTIONS;

  constructor(public readonly payload: Map<number, String[]>) {
  }
}

export class SetUserACLsAction implements Action {
  type: string = UserActionTypes.SET_USER_ACLS;

  constructor(public readonly payload: Acl[]) {
  }
}

export class SetOwnedRanchAction implements Action {
  type: string = UserActionTypes.SET_OWNED_RANCH;

  constructor(public readonly payload: Ranch) {
  }
}

export class LoadAffiliates implements Action {
  type: string = UserActionTypes.LOAD_AFFILIATES_ACTION;

  constructor(public readonly payload: Affiliate[]) {
  }
}

export class UpdateTermsAndConditionsAction implements Action {
  type: string = UserActionTypes.UPDATE_TERMS_AND_CONDITIONS_ACTION;

  constructor(public readonly payload: { termsId: number, userId: number, accepted: boolean }) {
  }
}

export class SetTermsAndConditionsAction implements Action {
  type: string = UserActionTypes.SET_TERMS_AND_CONDITIONS_ACTION;

  constructor(public readonly payload: { termsAndConditions: TermsAndConditions[] }) {
  }
}

export class SetPRFAssociationsAction implements Action {
  type: string = UserActionTypes.SET_PRF_ASSOCIATIONS;

  constructor(public readonly payload: { associations: PrfAssociation[], userId: number }) {
  }
}

export class LoadPRFAssociationsAction implements Action {
  type: string = UserActionTypes.LOAD_PRF_ASSOCIATIONS;

  constructor(public readonly payload: { userId: number }) {
  }
}

export class SetAffiliateMembershipsAction implements Action {
  type: string = UserActionTypes.SET_AFFILIATE_MEMBERSHIPS;

  constructor(public readonly payload: { userId: number, memberships: AffiliateMembership[] }) {
  }
}


export type UserActions
  = LoadUserAction
  | LoadUserFailAction
  | LoadUserSuccessAction
  | UpdatePasswordSuccessUserAction
  | UpdatePasswordFailedUserAction
  | UpdateUserFailUserAction
  | UpdateUserSuccessUserAction
  | UpdateTermsAndConditionsAction
  | SetPhoneAndEmail
  | SendContactUsMessageFailed
  | IncreaseParcelUsage
  | DeleteAccount
  | UpdateNotificationAction
  | UpdateNotificationSuccessAction
  | SetModuleSubscriptions
  | SetModuleSubscriptions
  | SetUserACLsAction
  | LoadAffiliates
  | LoadPRFAssociationsAction
  | SetPRFAssociationsAction
  | SetAffiliateMembershipsAction;

