import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LayerBase } from './layer-base';
import { MapBubbleService } from './map-bubble-service';
import { MapOperationService } from './map-operation.service';
import {Feature} from 'ol';
import { Point } from 'ol/geom';
import { Vector as VectorSource } from 'ol/source.js';
import { Vector as VectorLayer } from 'ol/layer.js';
import { transform } from 'ol/proj';
import { Icon, Style } from 'ol/style.js';

const SEARCH_LOCATION_LAYER = 'search_location';
const SEARCH_LOCATION_LAYER_MAX_RESOLUTION = 5;
const ZINDEX = 99999;

@Injectable({
    providedIn: 'root'
})
export class SearchLocationLayerService extends LayerBase {

    markerVectorSource: VectorSource;

    constructor(
        mapOperationsService: MapOperationService,
        mapBubbleService: MapBubbleService,
        private translateService: TranslateService,
    ) {
        super(mapOperationsService, mapBubbleService);
        this.layerName = SEARCH_LOCATION_LAYER;
        this.maxResolution = SEARCH_LOCATION_LAYER_MAX_RESOLUTION;
    }

    validateCoords(coords: { lat: number, lng: number }): boolean {
        let point = new Point(transform([coords.lng, coords.lat], 'EPSG:4326', 'EPSG:3857'));
        if (!!point.getCoordinates()[0] && !!point.getCoordinates()[1]) {
            return true;
        }
        return false;

    }

    addSearchMarkerLayer() {
        this.layer = new VectorLayer({
            zIndex: ZINDEX,
        });
        this.addLayer();
    }

    findLocation(coords) {
        this.mapOperationsService.showLoading(true);
        let iconFeature = new Feature({
            geometry: new Point(transform([coords.lng, coords.lat], 'EPSG:4326', 'EPSG:3857')),
        });
        iconFeature.setStyle(this.getFeatureStyle());

        this.markerVectorSource = new VectorSource({
            features: [iconFeature],
            wrapX: false,
        });

        this.layer.setSource(this.markerVectorSource);
        this.mapOperationsService.zoomTo(this.maxResolution);
        this.setLayerVisibility(true);
        this.mapOperationsService.showLoading(false);
    }

    baseStyle = (feature: Feature) => {
        return this.getFeatureStyle(feature);
    }
    selectedStyle = (feature: Feature) => {
        return this.getFeatureStyle(feature);
    }

    show = (show: boolean) => {
        this.setLayerVisibility(show);
    }

    overlayHtml = (feature: Feature) => {
        return null;
    }

    getFeatureStyle(feature = null) {
        return new Style({
            image: new Icon(/** @type {olx.style.IconOptions} */({
                anchor: [0.5, 48],
                anchorXUnits: 'fraction',
                anchorYUnits: 'pixels',
                opacity: 1,
                src: 'assets/imgs/marker_ball_green_48.png',
            }))
        });
    }
}
