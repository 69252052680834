import { Action } from "@ngrx/store";
import { type } from "./types";

export const BaseActionTypes = {
  GENERIC_UPDATE_SUCCESS_ACTION: type('[app] [General] Generic Success action'),
  CANNOT_GET_OFFLINE_ENTITY_ACTION: type('[app] [General] Cant get Offline entry action'),
};

export class GenericUpdateAction<EntityWithHash> {

  constructor(public payload: EntityWithHash) {
  }
}

export class GenericSuccessAction implements Action {
  type = BaseActionTypes.GENERIC_UPDATE_SUCCESS_ACTION;

  constructor(public payload: any) {
  }
}

export class CantGetOfflineEntityAction implements Action {
  type = BaseActionTypes.CANNOT_GET_OFFLINE_ENTITY_ACTION;

  constructor(public payload: any) {
  }
}

export declare type BaseActions =
  GenericUpdateAction<any> | GenericSuccessAction | CantGetOfflineEntityAction;
