import { Action } from '@ngrx/store';
import { type } from '../../types';
import { MappingRequest, UserMarker, UserMarkerType } from '../../../model/MappingsModel';
import { GenericUpdateAction } from '../../base.actions';


export const MarkerActionTypes = {
  LOAD_USER_MARKERS: type('[app] [Markers] Load User Markers'),
  LOAD_USER_MARKERS_SUCCESS: type('[app] [Markers] Load User Markers Success'),
  LOAD_MARKER: type('[app] [Markers] Load Marker'),
  LOAD_MARKER_SUCCESS: type('[app] [Markers] Load Marker Success'),
  LOAD_MARKER_TYPES: type('[app] [Markers] Load Marker types'),
  LOAD_MARKER_TYPES_FAILED: type('[app] [Markers] Load Marker types failed'),
  LOAD_MARKER_TYPES_SUCCESS: type('[app] [Markers] Load Marker types success '),
  CREATE_MARKER: type('[app] [Markers] Create marker'),
  CREATE_MARKER_FAILED: type('[app] [Markers] Create marker failed'),
  CREATE_MARKER_SUCCESS: type('[app] [Markers] Create marker success'),
  UPDATE_MARKER: type('[app] [Markers] Update marker'),
  UPDATE_MARKER_FAILED: type('[app] [Markers] Update marker failed'),
  UPDATE_MARKER_SUCCESS: type('[app] [Markers] Update marker success'),
  DELETE_MARKER: type('[app] [Markers] Delete marker'),
  DELETE_MARKER_FAILED: type('[app] [Markers] Delete marker failed'),
  DELETE_MARKER_SUCCESS: type('[app] [Markers] Delete marker success'),
  SELECT_MARKER: type('[app] [Markers] Select marker'),
};


export class LoadUserMarkersAction implements Action {
  readonly type = MarkerActionTypes.LOAD_USER_MARKERS;

  constructor(public payload: number | string) {
  }
}

export class LoadUserMarkersSuccessAction implements Action {
  readonly type = MarkerActionTypes.LOAD_USER_MARKERS_SUCCESS;

  constructor(public payload: UserMarker[]) {
  }
}


export class LoadMarkerAction implements Action {
  readonly type = MarkerActionTypes.LOAD_MARKER;

  constructor(public payload: number) { //marker id
  }
}

export class LoadMarkerSuccessAction implements Action {
  readonly type = MarkerActionTypes.LOAD_MARKER_SUCCESS;

  constructor(public payload: UserMarker) {
  }
}

export class LoadMarkerTypesAction implements Action {
  readonly type = MarkerActionTypes.LOAD_MARKER_TYPES;

  constructor() {
  }
}

export class LoadMarkerTypesSuccessAction implements Action {
  readonly type = MarkerActionTypes.LOAD_MARKER_TYPES_SUCCESS;

  constructor(public payload: UserMarkerType[]) {
  }
}


export class LoadMarkerTypesFailAction implements Action {
  readonly type = MarkerActionTypes.LOAD_MARKER_TYPES_FAILED;

  constructor(public payload: any) {
  }
}

export class CreateMarkerAction implements Action {
  readonly type = MarkerActionTypes.CREATE_MARKER;

  constructor(public payload: MappingRequest) {
  }
}

export class CreateMarkerSuccessAction implements Action {
  readonly type = MarkerActionTypes.CREATE_MARKER_SUCCESS;

  constructor(public payload: UserMarker) {
  }
}

export class CreateMarkerFailAction implements Action {
  readonly type = MarkerActionTypes.CREATE_MARKER_FAILED;

  constructor(public payload: any) {
  }
}

export class UpdateMarkerAction extends GenericUpdateAction<MappingRequest> implements Action {
  readonly type = MarkerActionTypes.UPDATE_MARKER;

  constructor(public payload: MappingRequest) {
    super(payload);
  }
}

export class UpdateMarkerSuccessAction implements Action {
  readonly type = MarkerActionTypes.UPDATE_MARKER_SUCCESS;

  constructor(public payload: UserMarker) {
  }
}

export class UpdateMarkerFailAction implements Action {
  readonly type = MarkerActionTypes.UPDATE_MARKER_FAILED;

  constructor(public payload: any) {
  }
}

export class DeleteMarkerAction implements Action {
  readonly type = MarkerActionTypes.DELETE_MARKER;

  constructor(public payload: number | string) {
  }
}

export class DeleteMarkerSuccessAction implements Action {
  readonly type = MarkerActionTypes.DELETE_MARKER_SUCCESS;

  constructor(public payload: number | string) {
  }
}

export class DeleteMarkerFailAction implements Action {
  readonly type = MarkerActionTypes.DELETE_MARKER_FAILED;

  constructor(public payload: any) {
  }
}

export class SelectMarkerAction implements Action {
  readonly type = MarkerActionTypes.SELECT_MARKER;

  constructor(public payload: any) {
  }
}


export type MarkerActions =
  LoadUserMarkersAction |
  CreateMarkerSuccessAction | CreateMarkerFailAction | CreateMarkerAction |
  UpdateMarkerSuccessAction | UpdateMarkerFailAction | UpdateMarkerAction |
  DeleteMarkerSuccessAction | DeleteMarkerFailAction | DeleteMarkerAction |
  SelectMarkerAction | LoadUserMarkersSuccessAction | LoadMarkerTypesFailAction | LoadMarkerTypesSuccessAction |
  LoadMarkerTypesAction | LoadMarkerAction | LoadMarkerSuccessAction;




