import { MapEvent } from "../../model";


export class MarkerUI {


    constructor(
    ) { }


    getSelectedMarker(event: MapEvent) {
        const elem = event.originalEvent.target && event.originalEvent.target.closest('.edit-user-marker[data-marker-idx]');
        if (elem) {
            const markerAttr = elem.getAttribute('data-marker-idx');
            if (markerAttr) {
                return (event.eventData.selectedFeature.getProperties()).featureData.markers[+markerAttr];
            }
        }
        return null;
    }

    getSelectedTaskId(event: MapEvent) {
        let elem = event.originalEvent.target && event.originalEvent.target.closest('.marker-item');
        if (elem) {
            elem = elem.querySelector('[data-task-id]');
        }
        if (elem) {
            const taskAttr = elem.getAttribute('data-task-id');
            return taskAttr;
        }
        return -1;
    }







}