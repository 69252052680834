import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthImagePipe } from "./auth-image-pipe";



@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    AuthImagePipe
  ],
  exports: [
    AuthImagePipe

  ]
})
export class AuthImagePipeModule { }
