import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CommonState } from '../store/reducer';
import { CurrentState } from '../store/current-state';
import {
  Animal,
  AnimalSpecies,
  AnimalStatus,
  AnimalType,
  LivestockListItem,
  LivestockRequest,
  Lot
} from '../model/LivestockModel';
import { LivestockReportRequest } from '../model/ReportModel';
import { ServicesCommon } from './services-common.service';
import { ENVIRONMENT_TOKEN, GOOGLE_ANALYTICS_TOKEN } from '../../environment/token-variables';
import { of, throwError } from 'rxjs';
import { Boundary, FinanceEntity, Ranch, RanchRequest, TaskEntity } from '../model';

import { CommonConstants } from '../app.constants';
import { FinanceService } from "./finance.service";


@Injectable()
export class LivestockService {

  private readonly LIVESTOCK_ATTACHMENT_URL = `/private/livestock/attachment`;
  private readonly ANIMAL_URL = `/private/livestock/animal`;
  private readonly BIRTH_URL = `/private/livestock/animal/birth`;
  private readonly LOT_URL = `/private/livestock/lot`;
  private readonly LS_SETTINGS_URL = `/private/livestock/settings`;

  constructor(
    private currentState: CurrentState<CommonState>,
    protected http: HttpClient,
    private servicesCommon: ServicesCommon,
    private financeService: FinanceService,
    @Inject(GOOGLE_ANALYTICS_TOKEN) private readonly ga,
    @Inject(ENVIRONMENT_TOKEN) private readonly environment
  ) {
  }

  createAnimal(request: LivestockRequest) {
    const url = this.ANIMAL_URL;
    return this.http.post<Animal[]>(
      this.environment.backend + url,
      request,
      {headers: this.servicesCommon.getAuthHeaders()}
    );
  }

  createLot(request: LivestockRequest) {
    const url = this.LOT_URL;
    return this.http.post<Lot>(
      this.environment.backend + url,
      request,
      {headers: this.servicesCommon.getAuthHeaders()}
    );
  }

  createBirthRecord(request: LivestockRequest) {
    const url = this.BIRTH_URL;
    return this.http.post<Animal>(
      this.environment.backend + url,
      request,
      {headers: this.servicesCommon.getAuthHeaders()}
    );
  }

  updateBirthRecord(request: LivestockRequest) {
    const url = this.BIRTH_URL + `/${request.birthRecordId}`;
    return this.http.put<Animal>(
      this.environment.backend + url,
      request,
      {headers: this.servicesCommon.getAuthHeaders()}
    );
  }

  getAllAnimalsByRanch(ranchId = null) {
    let selectedRanchId = this.currentState.snapshot.ranches.selectedRanchId;
    if (!!ranchId) {
      selectedRanchId = ranchId;
    }
    if (selectedRanchId) {
      const url = this.ANIMAL_URL + `/ranch/${selectedRanchId}`;
      return this.http.get<Animal[]>(
        this.environment.backend + url,
        {headers: this.servicesCommon.getAuthHeaders()}
      );
    }
    return of([]);
  }

  getAllAnimalsForUserId(userId = null) {
    if (!userId) {
      userId = this.currentState.snapshot.user.id;
    }
    if (!!userId) {
      const url = this.ANIMAL_URL + `/user/${userId}`;
      return this.http.get<Animal[]>(
        this.environment.backend + url,
        {headers: this.servicesCommon.getAuthHeaders()}
      );
    }
    return of([]);
  }

  getAllLotsByRanch() {
    const selectedRanchId = this.currentState.snapshot.ranches.selectedRanchId;
    if (selectedRanchId) {
      const url = this.LOT_URL + `/ranch/${selectedRanchId}`;
      return this.http.get<Lot[]>(
        this.environment.backend + url,
        {headers: this.servicesCommon.getAuthHeaders()}
      );
    }
    return of([]);
  }

  getAnimalById(animalId: number) {
    if (animalId < 0) {
      return throwError('Offline animal cannot be loaded via service.');
    }
    const url = this.ANIMAL_URL + `/${animalId}`;
    return this.http.get<Animal>(
      this.environment.backend + url,
      {headers: this.servicesCommon.getAuthHeaders()}
    );
  }

  getProfitLossById(id: number) {
    return this.financeService.getProfitLossById(id)
    // const url = this.PROFIT_LOSS_URL + `/${id}`;
    // return this.http.get<ProfitLoss>(
    //   this.environment.backend + url,
    //   {headers: this.servicesCommon.getAuthHeaders()}
    // );
  }

  updateAnimal(request: LivestockRequest) {
    const url = this.ANIMAL_URL;
    return this.http.put<Animal[]>(
      this.environment.backend + url,
      request,
      {headers: this.servicesCommon.getAuthHeaders()}
    );
  }

  deleteAnimal(animalIds) {
    const url = this.ANIMAL_URL + "/delete";
    return this.http.post<number[]>(
      this.environment.backend + url,
      animalIds,
      {headers: this.servicesCommon.getAuthHeaders()}
    );
  }

  public deleteAttachment(attachmentId) {
    const url = this.LIVESTOCK_ATTACHMENT_URL + `/${attachmentId}`;
    return this.http.delete<void>(
      this.environment.backend + url,
      {headers: this.servicesCommon.getAuthHeaders()}
    );
  }

  updateLot(request: LivestockRequest) {
    const url = this.LOT_URL + `/${request.lotId}`;
    return this.http.put<Lot>(
      this.environment.backend + url,
      request,
      {headers: this.servicesCommon.getAuthHeaders()}
    );
  }

  deleteLot(lotId: number) {
    const url = this.LOT_URL + `/${lotId}`;
    return this.http.delete<number>(
      this.environment.backend + url,
      {headers: this.servicesCommon.getAuthHeaders()}
    );
  }

  getAnimalTypes() {
    const url = this.ANIMAL_URL + `/types`;
    return this.http.get<AnimalType[]>(
      this.environment.backend + url,
      {headers: this.servicesCommon.getAuthHeaders()}
    );
  }

  getAnimalSpecies() {
    const url = this.ANIMAL_URL + `/species`;
    return this.http.get<AnimalSpecies[]>(
      this.environment.backend + url,
      {headers: this.servicesCommon.getAuthHeaders()}
    );
  }

  getAnimalBreeds() {
    const url = this.ANIMAL_URL + `/breeds`;
    return this.http.get<LivestockListItem[]>(
      this.environment.backend + url,
      {headers: this.servicesCommon.getAuthHeaders()}
    );
  }

  getTagColors() {
    const url = this.ANIMAL_URL + `/tagcolors`;
    return this.http.get<LivestockListItem[]>(
      this.environment.backend + url,
      {headers: this.servicesCommon.getAuthHeaders()}
    );
  }

  getAnimalStatuses() {
    const url = this.ANIMAL_URL + `/statuses`;
    return this.http.get<AnimalStatus[]>(
      this.environment.backend + url,
      {headers: this.servicesCommon.getAuthHeaders()}
    );
  }

  getBirthResults() {
    const url = this.BIRTH_URL + `/results`;
    return this.http.get<LivestockListItem[]>(
      this.environment.backend + url,
      {headers: this.servicesCommon.getAuthHeaders()}
    );
  }

  getBirthMethods() {
    const url = this.BIRTH_URL + `/methods`;
    return this.http.get<LivestockListItem[]>(
      this.environment.backend + url,
      {headers: this.servicesCommon.getAuthHeaders()}
    );
  }

  getCalvingEases() {
    const url = this.BIRTH_URL + `/calvingeases`;
    return this.http.get<LivestockListItem[]>(
      this.environment.backend + url,
      {headers: this.servicesCommon.getAuthHeaders()}
    );
  }

  getAllProfitLossRecordsByRanch() {

    const selectedRanchId = this.currentState.snapshot.ranches.selectedRanchId;
    if (selectedRanchId) {
      return this.financeService.getAllProfitLossesByRanch(selectedRanchId, true);
    }
    // if (selectedRanchId) {
    //   const url = this.PROFIT_LOSS_URL + `/ranch/${selectedRanchId}`;
    //   return this.http.get<ProfitLoss[]>(
    //     this.environment.backend + url,
    //     {headers: this.servicesCommon.getAuthHeaders()}
    //   );
    // }
    return of([]);
  }

  addNewProfitLossRecord(request: FinanceEntity) {
    return this.financeService.createProfitLoss(request);
    // const url = this.PROFIT_LOSS_URL;
    // return this.http.post<ProfitLoss>(
    //   this.environment.backend + url,
    //   request,
    //   {headers: this.servicesCommon.getAuthHeaders()}
    // );
  }

  updateProfitLossRecord(request: FinanceEntity) {
    return this.financeService.updateProfitLoss(request);
    // const url = this.PROFIT_LOSS_URL + `/${request.id}`;
    // return this.http.put<ProfitLoss>(
    //   this.environment.backend + url,
    //   request,
    //   {headers: this.servicesCommon.getAuthHeaders()}
    // );
  }

  deleteProfitLoss(plId) {
    return this.financeService.deleteProfitLoss(plId);
  }


  addNewSubscription(request: LivestockRequest) {
    const url = `/private/livestock/ranch/${request.ranchId}/user/${request.userId}/subscribe`;
    return this.http.post<any>(
      this.environment.backend + url,
      request,
      {headers: this.servicesCommon.getAuthHeaders()}
    );
  }

  getAvaialableBondariesToMove(ranchIds: string) {
    if (!ranchIds && !!this.currentState.snapshot.ranches?.ranchesOfUser) {
      ranchIds = '';
      this.currentState.snapshot.ranches.ranchesOfUser.map(rou => {
        const ranch = rou.ranch;
        return ranch.id;
      }).forEach(id => {
        ranchIds += id + ','
      });
    }
    if (ranchIds?.endsWith(',')) {
      ranchIds = ranchIds.slice(0, -1)
    }
    if (!ranchIds || ranchIds.length < 1 || ranchIds == 'null') {
      return of(new Map());
    }
    const url = this.ANIMAL_URL + `/ranches/${ranchIds}/move/boundaries/available`;
    return this.http.get<Map<number, Boundary[]>>(
      this.environment.backend + url,
      {headers: this.servicesCommon.getAuthHeaders()}
    );
  }

  getPastureTaskHistory(pastureId) {
    const url = `/private/tasks/history/pasture/${pastureId}`;
    return this.http.get<TaskEntity[]>(
      this.environment.backend + url,
      {headers: this.servicesCommon.getAuthHeaders()}
    );
  }

  public createLivestockReport(request: LivestockReportRequest) {
    const path = `/private/reports/livestock/user/${this.currentState.snapshot.user.id}`;
    const token = this.currentState.snapshot.user.token;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Authorization', token);
    const url = this.environment.backend + path;
    const options = {
      headers: headers
    };
    this.ga.trackServiceCall('createLivestockReport', CommonConstants.GA.LABEL.LIVESTOCK);
    return this.http.post(url, request, options);
  }

  public updateLivestockSettings(request: RanchRequest) {
    const url = this.LS_SETTINGS_URL;
    return this.http.put<Ranch[]>(
      this.environment.backend + url,
      request,
      {headers: this.servicesCommon.getAuthHeaders()}
    );
  }
}
