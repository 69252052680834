import { GaugesActionTypes as gaugesActionTypes, GaugesActions, GaugesActionTypes } from './gauges.actions';
import { ActionWithPayload } from "../types";
import { GaugeItem, WeatherInfoQuickLookSummary } from "../../model/GaugeModel";
import { CommonConstants } from "../../app.constants";


export interface GaugesState {
  stations: GaugeItem[];
  favouriteStations: GaugeItem[];
  selectedStations: { stationId: string, name: string }[];
  currentStationId: string | null;
  nonFavoriteStation: GaugeItem;
  starDate: Date;
  endDate: Date;
  todaySelected;
  dailyWeatherInfo;
  hourlyWeatherInfo;
  currentWeatherInfo;
  dataType: { type: string, mode: string };
  entryPage: any;
  quickLook7DaysWeatherInfo: WeatherInfoQuickLookSummary;
  quickLookYearWeatherInfo: WeatherInfoQuickLookSummary;
  quickLookMonthWeatherInfo: WeatherInfoQuickLookSummary;
  quickLookTodayWeatherInfo: WeatherInfoQuickLookSummary;
}


export const gaugesInitialState: GaugesState = {
  stations: [],
  favouriteStations: [],
  selectedStations: [],
  nonFavoriteStation: null,
  starDate: null,
  endDate: null,
  currentWeatherInfo: null,
  currentStationId: null,
  dailyWeatherInfo: null,
  todaySelected: false,
  hourlyWeatherInfo: null,
  dataType: { type: CommonConstants.GAUGE_CONSTANTS.dailyObservationDataType, mode: '0001' },
  entryPage: null,
  quickLook7DaysWeatherInfo: {
    startDate: null,
    endDate: null,
    weatherInfo: new Map(),
    loading: false,
    errorKey: null
  },
  quickLookYearWeatherInfo: {
    startDate: null,
    endDate: null,
    weatherInfo: new Map(),
    loading: false,
    errorKey: null
  },
  quickLookMonthWeatherInfo: {
    startDate: null,
    endDate: null,
    weatherInfo: new Map(),
    loading: false,
    errorKey: null
  },
  quickLookTodayWeatherInfo: {
    startDate: null,
    endDate: null,
    weatherInfo: new Map(),
    loading: false,
    errorKey: null
  },
};

function getDefailtCurrentStationId(prevCurrentStationId: string,
  selectedStations: { stationId: string, name: string }[]) {
  let currentStationId = prevCurrentStationId;
  if (!selectedStations.find(st => st.stationId === currentStationId)) {
    currentStationId = null;
  }
  if (!currentStationId && selectedStations.length > 0) {
    currentStationId = selectedStations[0].stationId;
  }
  return currentStationId;
}


export function reducer(state = gaugesInitialState, action: GaugesActions): GaugesState {
  switch (action.type) {
    case gaugesActionTypes.LOAD_ALL_GAUGES_SUCCESS:
      return {
        ...state,
        stations: (action as ActionWithPayload<any>).payload,
      };
    case gaugesActionTypes.LOAD_WEATHHER_INFO_SUCCESS:
      //weatherInfo, currentWeather
      const currentWeather = (action as ActionWithPayload<any>).payload.currentWeather;

      if (state.dataType.type == CommonConstants.GAUGE_CONSTANTS.dailyObservationDataType) {
        return {
          ...state,
          currentWeatherInfo: currentWeather,
          dailyWeatherInfo: (action as ActionWithPayload<any>).payload.weatherInfo,
        };
      } else {
        return {
          ...state,
          currentWeatherInfo: currentWeather,
          hourlyWeatherInfo: (action as ActionWithPayload<any>).payload.weatherInfo,
        };
      }
    case gaugesActionTypes.SELECT_GAUGE_STATIONS:
      let stations = (action as ActionWithPayload<any[]>).payload;
      if (stations && stations.length > 0) {
        stations = stations.map(station => {
          if (!station.name) {
            const st = state.stations.find(s => station.stationId === s.stationId);
            if (st) {
              station.name = st.name;
            }
          }
          return station;
        }).filter(station => !!station.name);
      }
      return {
        ...state,
        selectedStations: stations,
        currentStationId: getDefailtCurrentStationId(state.currentStationId, stations)

      };
    case GaugesActionTypes.SELECT_CURRENT_GAUGE_STATION: {
      return {
        ...state,
        currentStationId: (action as ActionWithPayload<string>).payload
      };
    }
    case gaugesActionTypes.SELECT_FAVOURITE_GAUGE_STATIONS: {
      const payload = (action as ActionWithPayload<{ stations: GaugeItem[], entry?: 'map' | 'detail' }>).payload;
      const entry = payload.entry || 'map';
      const nonFavoriteStation = null;
      let selectedStations = state.selectedStations.filter(st =>
        !!(payload.stations.find(fav => fav.stationId === st.stationId))
      );
      if (payload.stations.length === 1) {
        selectedStations = [payload.stations[0]];
      }
      return {
        ...state,
        favouriteStations: payload.stations,
        entryPage: entry,
        nonFavoriteStation,
        selectedStations,
        currentStationId: getDefailtCurrentStationId(state.currentStationId, selectedStations)
      };
    }
    case gaugesActionTypes.ADD_FAVOURITE_GAUGE_STATIONS:
      const stationOfPayload = (action as ActionWithPayload<any>).payload;
      const favouriteStations = state.favouriteStations;
      if (stationOfPayload) {
        favouriteStations.push(stationOfPayload);
      }
      return {
        ...state,
        favouriteStations
      };
    case gaugesActionTypes.ADD_NON_FAVOURITE_GAUGE_STATION: {
      const nonFavoriteStation = (action as ActionWithPayload<GaugeItem>).payload;
      let selectedStations = state.selectedStations;
      if (state.nonFavoriteStation) {
        selectedStations = state.selectedStations.filter(st => st.stationId !== state.nonFavoriteStation.stationId);
      }
      selectedStations.push({ stationId: nonFavoriteStation.stationId, name: nonFavoriteStation.name });
      return {
        ...state,
        nonFavoriteStation,
        currentStationId: nonFavoriteStation.stationId,
        selectedStations
      };
    }
    case gaugesActionTypes.SELECT_GAUGE_DATA_TYPE:
      return {
        ...state,
        dataType: (action as ActionWithPayload<any>).payload,
      };

    case gaugesActionTypes.LOAD_WEATHHER_INFO_QUICK_LOOK_7_DAYS:
      let ql7d = state.quickLook7DaysWeatherInfo;
      ql7d.loading = true;
      return {
        ...state,
        quickLook7DaysWeatherInfo: ql7d,
      };

    case gaugesActionTypes.LOAD_WEATHHER_INFO_QUICK_LOOK_MONTH:
      let qlm = state.quickLookMonthWeatherInfo;
      qlm.loading = true;
      return {
        ...state,
        quickLookMonthWeatherInfo: qlm,
      };
    case gaugesActionTypes.LOAD_WEATHHER_INFO_QUICK_LOOK_YEAR:
      let qly = state.quickLookYearWeatherInfo;
      qly.loading = true;
      return {
        ...state,
        quickLookYearWeatherInfo: qly,
      };
    case gaugesActionTypes.LOAD_WEATHHER_INFO_QUICK_LOOK_TODAY:
      let qlt = state.quickLookTodayWeatherInfo;
      qlt.loading = true;
      return {
        ...state,
        quickLookTodayWeatherInfo: qlt
      };
    case gaugesActionTypes.LOAD_WEATHHER_INFO_QUICK_LOOK_TODAY_SUCCESS:
    case gaugesActionTypes.LOAD_WEATHHER_INFO_QUICK_LOOK_TODAY_FAIL:
      let quickLookToday = (action as ActionWithPayload<any>).payload;
      quickLookToday.loading = false;
      return {
        ...state,
        quickLookTodayWeatherInfo: quickLookToday
      };
    case gaugesActionTypes.LOAD_WEATHHER_INFO_QUICK_LOOK_7_DAYS_SUCCESS:
    case gaugesActionTypes.LOAD_WEATHHER_INFO_QUICK_LOOK_7_DAYS_FAIL:
      let quickLook7Days = (action as ActionWithPayload<any>).payload;
      quickLook7Days.loading = false;
      return {
        ...state,
        quickLook7DaysWeatherInfo: quickLook7Days
      };
    case gaugesActionTypes.LOAD_WEATHHER_INFO_QUICK_LOOK_MONTH_SUCCESS:
    case gaugesActionTypes.LOAD_WEATHHER_INFO_QUICK_LOOK_MONTH_FAIL:
      let quickLookMonth = (action as ActionWithPayload<any>).payload;
      quickLookMonth.loading = false;
      return {
        ...state,
        quickLookMonthWeatherInfo: quickLookMonth,
      };
    case gaugesActionTypes.LOAD_WEATHHER_INFO_QUICK_LOOK_YEAR_SUCCESS:
    case gaugesActionTypes.LOAD_WEATHHER_INFO_QUICK_LOOK_YEAR_FAIL:

      let quickLookYear = (action as ActionWithPayload<any>).payload;
      quickLookYear.loading = false;
      return {
        ...state,
        quickLookYearWeatherInfo: quickLookYear,
      };
    case gaugesActionTypes.SELECT_GAUGE_DATES:
      let newStartDate = (action as ActionWithPayload<any>).payload.startDate;
      if (!newStartDate) {
        newStartDate = state.starDate
      }
      let newEndtDate = (action as ActionWithPayload<any>).payload.endDate;
      if (!newEndtDate) {
        newEndtDate = state.endDate
      }
      const todaySelected = (action as ActionWithPayload<any>).payload.todaySelected;
      return {
        ...state,
        starDate: newStartDate,
        endDate: newEndtDate,
        currentWeatherInfo: null,
        dailyWeatherInfo: null,
        todaySelected,
        hourlyWeatherInfo: null,
        quickLook7DaysWeatherInfo: { ...gaugesInitialState.quickLook7DaysWeatherInfo },
        quickLookYearWeatherInfo: { ...gaugesInitialState.quickLook7DaysWeatherInfo },
        quickLookMonthWeatherInfo: { ...gaugesInitialState.quickLook7DaysWeatherInfo },
        quickLookTodayWeatherInfo: { ...gaugesInitialState.quickLook7DaysWeatherInfo },
      };
    default:
      return state;
  }
}
