import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { of } from "rxjs";
import { ENVIRONMENT_TOKEN } from '../../environment/token-variables';
import { FinanceReportRequest } from "../model";
import { FinanceCategory, FinanceRequest, ItemEntity, ItemType } from "../model/FinanceModel";
import { CurrentState } from "../store/current-state";
import { CommonState } from "../store/reducer";
import { ServicesCommon } from "./services-common.service";


@Injectable()
export class FinanceService {


  private readonly FINANCE_URL = `/private/finance/`;
  private readonly ITEM_URL = `${this.FINANCE_URL}assets/`;
  private readonly PROFITLOSS_URL = `${this.FINANCE_URL}profitloss/`;

  private readonly RECURRING_URL = `${this.PROFITLOSS_URL}recurring/options`


  constructor(
    private currentState: CurrentState<CommonState>,
    protected http: HttpClient,
    private servicesCommon: ServicesCommon,
    @Inject(ENVIRONMENT_TOKEN) private readonly environment
  ) {
  }


  createItem(request: FinanceRequest) {
    const url = this.ITEM_URL;
    return this.http.post<ItemEntity>(
      this.environment.backend + url,
      request,
      {headers: this.servicesCommon.getAuthHeaders()}
    );
  }

  getAllItemsByRanch(ranchId = null) {
    let selectedRanchId = this.currentState.snapshot.ranches.selectedRanchId;
    if (!!ranchId) {
      selectedRanchId = ranchId;
    }
    if (selectedRanchId) {
      const url = this.ITEM_URL + `ranch/${selectedRanchId}`;
      return this.http.get<ItemEntity[]>(
        this.environment.backend + url,
        {headers: this.servicesCommon.getAuthHeaders()}
      );
    }
    return of([]);
  }

  updateItem(request: FinanceRequest) {
    const url = this.ITEM_URL + request.itemId;
    return this.http.put<ItemEntity>(
      this.environment.backend + url,
      request,
      {headers: this.servicesCommon.getAuthHeaders()}
    );
  }

  getItemById(itemId: number) {
    console.log(itemId)
    const url = this.ITEM_URL + itemId;
    return this.http.get<ItemEntity>(
      this.environment.backend + url,
      {headers: this.servicesCommon.getAuthHeaders()}
    );
  }

  getItemTypes() {
    const url = this.ITEM_URL + `types`;
    return this.http.get<ItemType[]>(
      this.environment.backend + url,
      {headers: this.servicesCommon.getAuthHeaders()}
    );
  }

  getAllProfitLossesByRanch(ranchId = null, isLSRequest = false) {
    let selectedRanchId = this.currentState.snapshot.ranches.selectedRanchId;
    if (!!ranchId) {
      selectedRanchId = ranchId;
    }
    if (selectedRanchId) {
      const url = this.PROFITLOSS_URL + `ranch/${selectedRanchId}?ls=${isLSRequest}`;
      return this.http.get<any[]>(
        this.environment.backend + url,
        {headers: this.servicesCommon.getAuthHeaders()}
      );
    }
    return of([]);
  }

  getProfitLossById(plId = null) {
    const url = this.PROFITLOSS_URL + plId;
    return this.http.get<any>(
      this.environment.backend + url,
      {headers: this.servicesCommon.getAuthHeaders()}
    );
  }

  updateProfitLoss(request: FinanceRequest) {
    const url = this.PROFITLOSS_URL + request.id;
    return this.http.put<any>(
      this.environment.backend + url,
      request,
      {headers: this.servicesCommon.getAuthHeaders()}
    );
  }

  createProfitLoss(request: FinanceRequest) {
    const url = this.PROFITLOSS_URL;
    return this.http.post<any>(
      this.environment.backend + url,
      request,
      {headers: this.servicesCommon.getAuthHeaders()}
    );
  }

  deleteItem(itemId: number) {
    const url = this.ITEM_URL + itemId;
    return this.http.delete<ItemEntity>(
      this.environment.backend + url,
      {headers: this.servicesCommon.getAuthHeaders()}
    );
  }

  expenseAttachment(expenseId, request) {
    const url = this.PROFITLOSS_URL + `expense/${expenseId}/attachment`;
    return this.http.post<any>(
      this.environment.backend + url,
      request,
      {headers: this.servicesCommon.getAuthHeaders()}
    );
  }


  getRecurringOptions() {
    const url = this.RECURRING_URL;
    return this.http.get<any>(
      this.environment.backend + url,
      {headers: this.servicesCommon.getAuthHeaders()}
    );
  }

  // POST ("/profitloss/recurring/generate")

  getReport(request: FinanceReportRequest) {
    const userId = this.currentState.snapshot.user.id;
    const url = "/private/reports/finance/user/";

    return this.http.post<any>(
      this.environment.backend + url + userId,
      request,
      {headers: this.servicesCommon.getAuthHeaders()}
    );

  }

  getCategories() {
    const url = this.PROFITLOSS_URL + `categories`;
    return this.http.get<FinanceCategory[]>(
      this.environment.backend + url,
      {headers: this.servicesCommon.getAuthHeaders()}
    );
  }


  deleteProfitLoss(plId) {
    const url = this.PROFITLOSS_URL + `${plId}`;
    return this.http.delete<number>(
      this.environment.backend + url,
      {headers: this.servicesCommon.getAuthHeaders()}
    );
  }
}
