import { NgModule } from "@angular/core";
import { MarkerSelectorComponent } from "./marker-selector/marker-selector.component";
import { ColorPickerComponent } from "./color-picker/color-picker.component";
import { CommonModule } from "@angular/common";


@NgModule({
    imports: [
        CommonModule,
    ],
    declarations: [
        MarkerSelectorComponent,
        ColorPickerComponent,
    ],
    exports: [
        MarkerSelectorComponent,
        ColorPickerComponent,
    ]
})
export class CommonComponentsModule {
}