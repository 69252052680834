import { Action } from '@ngrx/store';
import { Boundary, OfflineMap } from '../../../model/MappingsModel';
import { type } from '../../types';

export const OfflineMapActionTypes = {
    LOAD_USER_OFFLINE_MAPS: type('[app] [Offline Maps] Load User Offline Maps'),
    LOAD_USER_OFFLINE_MAPS_SUCCESS: type('[app] [Offline Maps] Load User Offline Maps Success'),
    LOAD_USER_OFFLINE_MAPS_FAILED: type('[app] [Offline Maps] Load User Offline Maps Failed'),
    ADD_OFFLINE_MAP: type('[app] [Offline Maps] Add Offline Map'),
    ADD_OFFLINE_MAP_SUCCESS: type('[app] [Offline Maps] Add Offline Map Success'),
    ADD_OFFLINE_MAP_FAILED: type('[app] [Offline Maps] Add Offline Map Failed'),
    DELETE_OFFLINE_MAP: type('[app] [Offline Maps] Delete Offline Map'),
    DELETE_OFFLINE_MAP_SUCCESS: type('[app] [Offline Maps] Delete Offline Map Success'),
    DELETE_OFFLINE_MAP_FAILED: type('[app] [Offline Maps] Delete Offline Map Failed'),
};

export class LoadUserOfflineMapsAction implements Action {
    readonly type = OfflineMapActionTypes.LOAD_USER_OFFLINE_MAPS;
}

export class LoadUserOfflineMapsSuccessAction implements Action {
    readonly type = OfflineMapActionTypes.LOAD_USER_OFFLINE_MAPS_SUCCESS;
    constructor(public payload: OfflineMap[]) {
    }
}

export class LoadUserOfflineMapsFailedAction implements Action {
    readonly type = OfflineMapActionTypes.LOAD_USER_OFFLINE_MAPS_FAILED;
    constructor(public payload: any) {
    }
}

export class AddOfflineMapAction implements Action {
    readonly type = OfflineMapActionTypes.ADD_OFFLINE_MAP;
    constructor(public payload: { offlineMap: OfflineMap }) {
    }
}

export class AddOfflineMapSuccessAction implements Action {
    readonly type = OfflineMapActionTypes.ADD_OFFLINE_MAP_SUCCESS;
    constructor(public payload: { offlineMap: OfflineMap }) {
    }
}

export class AddOfflineMapFailedAction implements Action {
    readonly type = OfflineMapActionTypes.ADD_OFFLINE_MAP_FAILED;
    constructor(public payload: any) {
    }
}

export class DeleteOfflineMapAction implements Action {
    readonly type = OfflineMapActionTypes.DELETE_OFFLINE_MAP;
    constructor(public payload: number) {
    }
}

export class DeleteOfflineMapSuccessAction implements Action {
    readonly type = OfflineMapActionTypes.DELETE_OFFLINE_MAP_SUCCESS;
    constructor(public payload: number) {
    }
}

export class DeleteOfflineMapFailedAction implements Action {
    readonly type = OfflineMapActionTypes.ADD_OFFLINE_MAP_FAILED;
    constructor(public payload: any) {
    }
}

export type OfflineMapActions = LoadUserOfflineMapsAction |
    LoadUserOfflineMapsSuccessAction | LoadUserOfflineMapsFailedAction |
    AddOfflineMapAction | AddOfflineMapFailedAction | AddOfflineMapSuccessAction |
    DeleteOfflineMapAction | DeleteOfflineMapSuccessAction | DeleteOfflineMapFailedAction;
