import { NgModule } from '@angular/core';
import { EffectsModule } from "@ngrx/effects";
import { GaugesEffects } from "./gauges/gauges.effects";
import { AuthenticationEffects } from "./authentication/authentication.effects";
import { UserEffects } from "./user/user.effects";
import { TasksEffects } from "./tasks/tasks.effects";
import { RanchesEffects } from "./ranches/ranches.effects";
import { AdminEffects } from "./admin/admin.effects";
import { PrfEffects } from "./prf/prf.effects";
import { EquipmentEffects } from './equipments/equipments.effects';
import { ImagesEffects } from './images/images.effects';
import { MarkerEffects } from "./mappings/markers/marker.effects";
import { BoundaryEffects } from './mappings/boundaries/boundaries.effects';
import { LivestockEffects } from './livestock/livestock.effects';
import { NotificationsEffects } from "./notifications/notifications.effects";
import { GpsEarTagsEffects } from "./gpseartags/gpseartags.effects";
import { FinanceEffects } from './finance/finance.effects';
import { ClimateSmartEffects } from "./climate-smart/climate_smart.effects";
import { LrpEffects } from "./lrp/lrp.effects";


@NgModule({
  imports: [
    EffectsModule.forFeature([
      GaugesEffects,
      AuthenticationEffects,
      AdminEffects,
      RanchesEffects,
      TasksEffects,
      PrfEffects,
      UserEffects,
      EquipmentEffects,
      ImagesEffects,
      MarkerEffects,
      BoundaryEffects,
      LivestockEffects,
      LrpEffects,
      NotificationsEffects,
      GpsEarTagsEffects,
      FinanceEffects,
      ClimateSmartEffects
    ])]
})
export class CommonFeEffectsModule {
}
