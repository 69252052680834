import {
  AnnualForageResponse,
  ClaimStatusResponse,
  GridDetailsForClaims,
  PremiumAndCoverageResponse,
  PremiumSummary
} from "../../model";
import {
  PrfActions,
  PRFActionTypes,
  AnnualForageActionTypes,
  AnnualForageActions,
  LoadAllYearsDashboardDataSuccessAction
} from "./prf.actions";
import { getAvailableInterval, getCurrentInterval } from "../../services/prf.service";
import { ActionWithPayload } from "../types";
import { UserActions, UserActionTypes } from "../user";


export interface PRFState {
  premiums: {
    hargrove: {
      premiumsMap: Map<string, PremiumAndCoverageResponse[]>;
    }
    aap: {
      premiumsMap: Map<string, PremiumAndCoverageResponse[]>;
    }
  },
  prfProducers: any[];
  selectedYear: string;
  selectedAssociationIds: string[];
  claims: {
    hargrove: {
      claimsForProducersByYears: Map<string, GridDetailsForClaims[]>;
    },
    aap: {
      claimsForProducersByYears: Map<string, GridDetailsForClaims[]>;
    },
    selectedCounties: string[],
    availableCounties: String[]
  }

  claimsForCounties: Map<string, ClaimStatusResponse[]>;
  noPoliciesForYear: boolean;
  dashboard: {
    premiumsSummary: any[],
    claimsSummary: any[],
    allYearsPremiums: object,
    allYearsClaims: object
  };
  coverage: {
    selectedCounties: string[],
    premiumsForCounties: Map<String, PremiumAndCoverageResponse[]>,
    availableCounties: String[]
  };
  rainfall: {
    availableIntervals: { id: number, label: string }[]
    selectedInterval: { id: number, label: string }
  };
  availableYears: string[];
  annualForage: {
    data: AnnualForageResponse[],
    selectedYear: string;
    selectedAssociationIds: string[];
    availableGrowingSeasons: string[];
    selectedGrowingSeason: number;
    availableCounties: string[],
    selectedCounties: string[],
    availableIntervals: { id: number, label: string }[]
    selectedInterval: { id: number, label: string }
  }
  latestNOAAUpdateDate: string
}

export const PRFInitialState: PRFState = {
  premiums: {
    hargrove: {
      premiumsMap: new Map(),
    },
    aap: {
      premiumsMap: new Map(),
    }
  },
  claimsForCounties: new Map(),
  selectedYear: getThisYear(),
  selectedAssociationIds: [],
  // premiums: [],
  noPoliciesForYear: null,
  claims: {
    hargrove: {
      claimsForProducersByYears: new Map(),
    },
    aap: {
      claimsForProducersByYears: new Map(),
    },
    selectedCounties: [],
    availableCounties: []
  },
  prfProducers: null,
  dashboard: {
    premiumsSummary: [],
    claimsSummary: [],
    allYearsPremiums: {},
    allYearsClaims: {}
  },
  coverage: {
    selectedCounties: [],
    premiumsForCounties: new Map(),
    availableCounties: []
  },
  rainfall: {
    availableIntervals: getAvailableInterval(),
    selectedInterval: getCurrentInterval()
  },
  availableYears: [],
  annualForage: {
    data: null,
    selectedYear: getThisYear(),
    selectedAssociationIds: [],
    availableGrowingSeasons: [
      "All Growing Seasons",
      "Growing Season 1",
      "Growing Season 2",
      "Growing Season 3",
      "Growing Season 4",
      "Growing Season 5",
      "Growing Season 6",
      "Growing Season 7",
      "Growing Season 8",
      "Growing Season 9",
      "Growing Season 10",
      "Growing Season 11",
      "Growing Season 12",
    ],
    selectedGrowingSeason: null,
    availableCounties: [],
    selectedCounties: [],
    availableIntervals: [],
    selectedInterval: null
  },
  latestNOAAUpdateDate: null
};

function getThisYear() {
  const nowDate = new Date();
  const endYear = nowDate.getFullYear();
  return endYear + '';
}

export function getYears(latestYear) {
  let years = [];
  const starYear = 2011;
  for (let i = latestYear; i >= starYear; i--) {
    years.push(i + '');
  }
  return years;
}

export function reducer(state = PRFInitialState, action: PrfActions | AnnualForageActions | UserActions): PRFState {
  let newDb = {...state.dashboard};
  let newCoverage = {...state.coverage};
  let newClaims = {...state.claims};
  let counties;
  let af = {...state.annualForage};
  let company;
  switch (action.type) {
    case PRFActionTypes.LOAD_DASHBOARD_PREMIUMS_SUCCESS:
    case PRFActionTypes.LOAD_COVERAGE_PREMIUMS_SUCCESS:
      let newPremiumsMap: Map<string, PremiumAndCoverageResponse[]> = new Map();
      let loadedPrs = (action as ActionWithPayload<any>).payload.premiums;
      company = (action as ActionWithPayload<any>).payload.company;
      let updatedPremiums = {...state.premiums};
      if (company == 'hargrove') {
        Array.from(state.premiums.hargrove.premiumsMap.keys()).forEach(key => {
          newPremiumsMap.set(key, state.premiums.hargrove.premiumsMap.get(key));
        });
        Array.from(loadedPrs.keys()).forEach((key: any) => {
          newPremiumsMap.set(key, loadedPrs.get(key));
        });
        updatedPremiums.hargrove.premiumsMap = newPremiumsMap;
        return {
          ...state,
          premiums: updatedPremiums
        }
      }
      if (company == 'aap') {
        Array.from(state.premiums.aap.premiumsMap.keys()).forEach(key => {
          newPremiumsMap.set(key, state.premiums.aap.premiumsMap.get(key));
        });
        Array.from(loadedPrs.keys()).forEach((key: any) => {
          newPremiumsMap.set(key, loadedPrs.get(key));
        });
        updatedPremiums.aap.premiumsMap = newPremiumsMap;
        return {
          ...state,
          premiums: updatedPremiums
        }
      }
      return {...state};
    case PRFActionTypes.LOAD_CLAIMS_FOR_PRODUCER_YEAR_SUCCESS:
      let newClaimsMap: Map<string, GridDetailsForClaims[]> = new Map();
      let loadedClaims = (action as ActionWithPayload<any>).payload.data;
      company = (action as ActionWithPayload<any>).payload.company;
      let updatedClaims = {...state.claims};
      if (company == 'hargrove') {
        Array.from(state.claims.hargrove.claimsForProducersByYears.keys()).forEach(key => {
          newClaimsMap.set(key, state.claims.hargrove.claimsForProducersByYears.get(key));
        });
        Array.from(loadedClaims.keys()).forEach((key: any) => {
          newClaimsMap.set(key, loadedClaims.get(key));
        });
        updatedClaims.hargrove.claimsForProducersByYears = newClaimsMap;
        return {
          ...state,
          claims: updatedClaims
        };
      }
      if (company == 'aap') {
        Array.from(state.claims.aap.claimsForProducersByYears.keys()).forEach(key => {
          newClaimsMap.set(key, state.claims.aap.claimsForProducersByYears.get(key));
        });
        Array.from(loadedClaims.keys()).forEach((key: any) => {
          newClaimsMap.set(key, loadedClaims.get(key));
        });
        updatedClaims.aap.claimsForProducersByYears = newClaimsMap;
        return {
          ...state,
          claims: updatedClaims
        };
      }
      return {...state};

    case PRFActionTypes.LOAD_CLAIMS_FOR_COUNTIES_SUCCESS:
      return {
        ...state,
        claimsForCounties: (action as ActionWithPayload<any>).payload
      };
    case PRFActionTypes.LOAD_PRF_PRODUCERS_SUCCESS:
      return {
        ...state,
        prfProducers: (action as ActionWithPayload<any>).payload,
      };
    case PRFActionTypes.RESET_PRF_PRODUCERS:
      return {
        ...state,
        prfProducers: null,
      };
    case PRFActionTypes.LOAD_DASHBOARD_PREMIUMS_SUMMARY_SUCCESS:
      let summaries: PremiumSummary[] = [];
      summaries = (action as ActionWithPayload<any>).payload.premiumSummaries;
      newDb.premiumsSummary = summaries;
      return {
        ...state,
        dashboard: newDb
      };
    case PRFActionTypes.LOAD_DASHBOARD_CLAIMS_SUMMARY_SUCCESS:
      let claimSummaries: [] = [];
      claimSummaries = (action as ActionWithPayload<any>).payload.data;
      newDb.claimsSummary = claimSummaries;
      return {
        ...state,
        dashboard: newDb
      };
    case PRFActionTypes.LOAD_COVERAGE_PREMIUMS_SUMMARY_SUCCESS:
      let premiumsForCounties: Map<String, PremiumAndCoverageResponse[]> = new Map();
      premiumsForCounties = (action as ActionWithPayload<any>).payload.premiumSummaries;
      newCoverage.premiumsForCounties = premiumsForCounties;
      return {
        ...state,
        coverage: newCoverage
      };
    case PRFActionTypes.SET_COVERAGE_AVAILABLE_COUNTIES:
      counties = (action as ActionWithPayload<any>).payload;
      newCoverage.availableCounties = counties;
      return {
        ...state,
        coverage: newCoverage
      };
    case PRFActionTypes.SET_COVERAGE_SELECTED_COUNTIES:
      newCoverage.selectedCounties = (action as ActionWithPayload<any>).payload;
      return {
        ...state,
        coverage: newCoverage
      };
    case PRFActionTypes.SET_CLAIMS_AVAILABLE_COUNTIES:
      counties = (action as ActionWithPayload<any>).payload;
      newClaims.availableCounties = counties;
      return {
        ...state,
        claims: newClaims
      };
    case PRFActionTypes.SET_SELECTED_YEAR:
      return {
        ...state,
        selectedYear: (action as ActionWithPayload<any>).payload
      };
    case PRFActionTypes.SET_SELECTED_ASSOCIATION_IDS:
      return {
        ...state,
        selectedAssociationIds: (action as ActionWithPayload<any>).payload
      };
    case UserActionTypes.LOAD_USER_SUCCESS:
      const userDetails = (action as ActionWithPayload<any>).payload;
      let associationIds = state.selectedAssociationIds;
      if (!userDetails.prfAssociations || userDetails.prfAssociations.length < 1) {
        associationIds = [];
      } else if (userDetails.prfAssociations && userDetails.prfAssociations.length == 1) {
        const prfAssoc = userDetails.prfAssociations;
        associationIds = [prfAssoc[0].id];
      }
      let yearsOfPRf = state.availableYears;
      if (userDetails.latestPRFYear && userDetails.latestPRFYear.length > 0) {
        const latestPRFYear = userDetails.latestPRFYear;
        yearsOfPRf = getYears(latestPRFYear);
      }
      return {
        ...state,
        selectedAssociationIds: associationIds,
        availableYears: yearsOfPRf
      };
    case PRFActionTypes.SET_CLAIMS_SELECTED_COUNTIES:
      newClaims.selectedCounties = (action as ActionWithPayload<any>).payload;
      return {
        ...state,
        claims: newClaims
      };
    case PRFActionTypes.SET_RAINFALL_SELECTED_INTERVAL:
      let newRainfall = {...state.rainfall};
      newRainfall.selectedInterval = (action as ActionWithPayload<any>).payload;
      return {
        ...state,
        rainfall: newRainfall
      };
    case PRFActionTypes.SET_NO_POLICY_FOR_YEAR:
      return {
        ...state,
        noPoliciesForYear: (action as ActionWithPayload<any>).payload
      };
    case PRFActionTypes.SET_LATEST_PRF_YEAR:
      const years = getYears((action as ActionWithPayload<any>).payload);
      return {
        ...state,
        availableYears: years
      };
    case PRFActionTypes.LOAD_ALL_YEARS_DASHBOARD_DATA_SUCCESS:
      console.log('LOAD_ALL_YEARS_DASHBOARD_DATA_SUCCESS reducer');
      console.log(action);
      let dashBoard = {...state.dashboard};
      const claimsObject = {};
      let newAssociationIds = [];
      const claimsFromAction = (action as LoadAllYearsDashboardDataSuccessAction).payload.allClaims;
      claimsFromAction.forEach(item => {
        newAssociationIds.push(item.associationId);
        claimsObject[item.associationId] = item.policies;
      });
      const premObject = {};
      const premiumsFromAction = (action as LoadAllYearsDashboardDataSuccessAction).payload.allPremiums;

      premiumsFromAction.forEach(item => {
        premObject[item.associationId] = item.policies;
      });

      if (state.selectedAssociationIds.length > newAssociationIds.length) {
        const keepTaxIds = state.selectedAssociationIds.filter(id => !newAssociationIds.includes(id));
        keepTaxIds.forEach(associationId => {
          claimsObject[associationId] = dashBoard.allYearsClaims[associationId];
          premObject[associationId] = dashBoard.allYearsPremiums[associationId];
        });
      }

      dashBoard.allYearsClaims = claimsObject;
      dashBoard.allYearsPremiums = premObject;
      return {
        ...state, dashboard: dashBoard
      }

    // case PRFActionTypes.LOAD_ALL_YEARS_CLAIMS_SUCCESS:
    //   const claimsObj = {};
    //   const claims = (action as LoadAllYearsClaimsSuccessAction).payload;
    //   claims.forEach(item => {
    //     claimsObj[item.associationIds] = item.policies;
    //   });
    //   return { ...state, dashboard: { ...state.dashboard, allYearsClaims: { ...state.dashboard.allYearsClaims, ...claimsObj } } };
    // case PRFActionTypes.LOAD_ALL_YEARS_PREMIUMS_SUCCESS:
    //   const premiumsObj = {};
    //   const premiums = (action as LoadAllYearsPremiumsSuccessAction).payload;
    //   premiums.forEach(item => {
    //     premiumsObj[item.associationIds] = item.policies;
    //   });
    //   return { ...state, dashboard: { ...state.dashboard, allYearsPremiums: { ...state.dashboard.allYearsPremiums, ...premiumsObj } } };
    case PRFActionTypes.SET_DEAFULT_SELECTED_YEAR:
      return {...state, selectedYear: getThisYear()};
    case AnnualForageActionTypes.AF_LOAD_DATA_SUCCESS:
      af.data = (action as ActionWithPayload<AnnualForageResponse[]>).payload;
      return {
        ...state,
        annualForage: af
      };
    case AnnualForageActionTypes.SET_SELECTED_YEAR:
      af.selectedYear = (action as ActionWithPayload<string>).payload
      return {
        ...state,
        annualForage: af
      };
    case AnnualForageActionTypes.SET_SELECTED_ASSOCIATION_IDS:
      af.selectedAssociationIds = (action as ActionWithPayload<any>).payload
      return {
        ...state,
        annualForage: af
      };
    case AnnualForageActionTypes.SET_SELECTED_GROWING_SEASON:
      af.selectedGrowingSeason = (action as ActionWithPayload<number>).payload
      return {
        ...state,
        annualForage: af
      };
    case AnnualForageActionTypes.SET_AVAILABLE_COUNTIES:
      af.availableCounties = (action as ActionWithPayload<any[]>).payload
      return {
        ...state,
        annualForage: af
      };
    case AnnualForageActionTypes.SET_SELECTED_COUNTIES:
      af.selectedCounties = (action as ActionWithPayload<any[]>).payload
      return {
        ...state,
        annualForage: af
      };
    case AnnualForageActionTypes.SET_AVAILABLE_INTERVALS:
      af.availableIntervals = (action as ActionWithPayload<{ id: number, label: string }[]>).payload
      if (!(af.availableIntervals.includes(af.selectedInterval))) {
        af.selectedInterval = null;
      }
      return {
        ...state,
        annualForage: af
      };
    case AnnualForageActionTypes.SET_SELECTED_INTERVAL:
      af.selectedInterval = (action as ActionWithPayload<{ id: number, label: string }>).payload
      return {
        ...state,
        annualForage: af
      };
    case PRFActionTypes.SET_LATEST_NOAAA_UPDATE_DATE:
      return {
        ...state,
        latestNOAAUpdateDate: (action as ActionWithPayload<string>).payload
      };
    default:
      return state;
  }
}
