<section>
  <mat-form-field color="primary" appearance="standard">
    <mat-label>Enter name</mat-label>
    <input matInput [(ngModel)]="userCredential" (change)="validateSearchInput()">
  </mat-form-field>
  
  <button mat-flat-button color="primary" (click)="searchUsers()" [disabled]="!userCredentialValid">Search</button>
</section>

<section>
  <mat-checkbox color="primary" [(ngModel)]="ranchManager">Foreman</mat-checkbox>
</section>

<section *ngIf="noUserFound">
  Could not find any user.
</section>

<section *ngIf="!noUserFound">
  <mat-list>
    <ng-container *ngFor="let user of (userSearchObs | async)">
        <mat-list-item>
                <p mat-line>{{ user.firstName}} {{ user.lastName }}</p>
                <p mat-line>{{ user.userName }}</p>
                <p mat-line>{{ user.email }}</p>
                <p mat-line *ngIf="user.ranchManager">Foreman</p>
          
                <button mat-flat-button color="primary" (click)="userSelected(user)">Add User</button>
        </mat-list-item>
        <mat-divider></mat-divider>
    </ng-container>
  </mat-list>
</section>
