import { Injectable } from '@angular/core';
import { Boundary, MappingRequest } from '../../model';
import {Feature} from 'ol';
import { MapOperationService } from './map-operation.service';
import { WKTGeometryConverter } from './wkt-converter.service';
import { CommonState } from '../../store/reducer';
import { Store } from '@ngrx/store';
import { MergePolygonsAction, UnMergeBoundaryAction } from '../../store/mappings';

@Injectable({
  providedIn: 'root'
})
export class BoundaryMergeService {

  constructor(
    private store: Store<CommonState>,
    private wktGeometryConverter: WKTGeometryConverter,
    private mapOperationsService: MapOperationService,
  ) {
  }

  /**
   * Calculates the union selected features by triggering a new MergePolygonsAction action with mappingRequests created from param
   * @param selectedFeatures - Array of selected features
   */
  public merge(selectedFeatures: Feature[], title: string) {
    this.mapOperationsService.showLoading(true);
    try {
      const mappingRequests = selectedFeatures.map((f: Feature) => {
        let fData = f.getProperties();
        let wkt = '';
        wkt = this.wktGeometryConverter.featureToWKT(f);
        let req = <MappingRequest>{
          geometry: wkt,
          boundaryId: !!fData.featureData && !!fData.featureData.id ? fData.featureData.id : null,
          mergeTitle: title,
        }
        return req;
      });
      this.store.dispatch(new MergePolygonsAction(mappingRequests));
    } catch (ex) {
      console.error('Error meging boundaries', ex);
      this.mapOperationsService.fireEvent('merge-boundaries-failed', {
        layerName: 'map',
        error: ex,
        featureData: {added: [], removed: [], partial: false, canMerge: false, parcels: []}
      });
    }
  }

  public unMerge(data: Boundary) {
    const request = <MappingRequest>{
      boundaryId: data.id
    };
    this.store.dispatch(new UnMergeBoundaryAction(request));
  }
}



