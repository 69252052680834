import { TasksActionTypes } from './tasks.actions';
import { AttachmentActionTypes } from '../attachments/attachments.actions';
import { CommonConstants } from "../../app.constants";
import { TaskAction, TaskCategory, TaskEntity, TaskStatus } from "../../model";
import { ActionWithPayload } from "../types";
import { Action } from "@ngrx/store";


export interface TasksState {
  allTasks: {
    tasks: TaskEntity[],
    currentPage: number | string,
    pageSize: number | string,
    hasNextPage: boolean
  },
  upcomingTasks: {
    tasks: TaskEntity[],
    currentPage: number | string,
    pageSize: number | string,
    hasNextPage: boolean
  },
  selectedTask: TaskEntity
  allCategories: TaskCategory[]
  allActions: TaskAction[]
  allStatuses: TaskStatus[]
  filter: {
    categories: any[],
    statuses: any[],
    assignees: any[],
    locations: any[],
    ranches: any[],
    ordering: any
  }
  orderings: any[],
  availableAssignees: {
    id;
    userName: string,
    firstName: string,
    lastName: string,
    email: string
  }[]
}


function getOrderings() {
  return CommonConstants.TASK_CONSTANTS.orderings;
}

function getDefaultOrdering() {
  return CommonConstants.TASK_CONSTANTS.defaultOrdering;
}

export const tasksInitialState: TasksState = {
  allTasks: {
    tasks: null,
    currentPage: 1,
    pageSize: 200,
    hasNextPage: true
  },
  upcomingTasks: {
    tasks: null,
    currentPage: 1,
    pageSize: 200,
    hasNextPage: true
  },
  selectedTask: null,
  allCategories: null,
  allActions: null,
  allStatuses: null,
  orderings: getOrderings(),
  filter: {
    categories: [],
    statuses: [],
    assignees: [],
    locations: [],
    ranches: [],
    ordering: getDefaultOrdering()
  },
  availableAssignees: []
};

export function reducer(state = tasksInitialState, action: Action): TasksState {
  switch (action.type) {
    case TasksActionTypes.RESET_SELECTED_TASK:
      return {
        ...state,
        selectedTask: null,
      };
    case TasksActionTypes.LOAD_ALL_TASK_CATEGORIES_SUCCESS:
      return {
        ...state,
        allCategories: (action as ActionWithPayload<any>).payload
      };
    case TasksActionTypes.LOAD_ALL_TASK_ACTIONS_SUCCESS:
      return {
        ...state,
        allActions: (action as ActionWithPayload<any>).payload
      };
    case TasksActionTypes.LOAD_ALL_TASK_STATUSES_SUCCESS:
      return {
        ...state,
        allStatuses: (action as ActionWithPayload<any>).payload
      };
    case TasksActionTypes.DELETE_TASK_SUCCESS:
      const deleteTaskId = (action as ActionWithPayload<any>).payload;
      let updatedUTasks = null;
      if (state.upcomingTasks.tasks) {
        updatedUTasks = state.upcomingTasks.tasks.filter(task => {
          return task.id != deleteTaskId;
        });
      }
      let updatedAllTasks = null;
      if (state.allTasks.tasks) {
        updatedAllTasks = state.allTasks.tasks.filter(task => {
          return task.id != deleteTaskId;
        });
      }
      return {
        ...state,
        allTasks: {...state.allTasks, tasks: updatedAllTasks},
        upcomingTasks: {...state.upcomingTasks, tasks: updatedUTasks},
      };

    case TasksActionTypes.LOAD_TASK_DETAIL_SUCCESS:
      const taskDetail = (action as ActionWithPayload<any>).payload;
      if (!taskDetail) {
        return state;
      }
      if (!taskDetail.id) {
        return {
          ...state,
          selectedTask: taskDetail,
        };
      }
      let updatedTasksOfAllTasks = (state.allTasks && state.allTasks.tasks) ? state.allTasks.tasks.map(task => {
        if (task.id == taskDetail.id) {
          task = taskDetail;
        }
        return task;
      }) : null;

      let updatedTasksOfUpcomingTasks = (state.upcomingTasks && state.upcomingTasks.tasks) ? state.upcomingTasks.tasks.map(task => {
        if (task.id == taskDetail.id) {
          task = taskDetail;
        }
        return task;
      }) : null;

      if (updatedTasksOfAllTasks && !(updatedTasksOfAllTasks.find(t => t.id == taskDetail.id))) {
        updatedTasksOfAllTasks.push(taskDetail);
      }

      let allTasksUpdated = {...state.allTasks};
      let upcoming = {...state.upcomingTasks};
      allTasksUpdated.tasks = updatedTasksOfAllTasks;
      upcoming.tasks = updatedTasksOfUpcomingTasks;
      let selectedTask = {...state.selectedTask};
      if (!!selectedTask && selectedTask.id == taskDetail.id) {
        selectedTask = taskDetail;
      }
      return {
        ...state,
        upcomingTasks: upcoming,
        allTasks: allTasksUpdated,
        selectedTask: selectedTask
      };
    case AttachmentActionTypes.DELETE_ATTACHMENT_SUCCESS:
      let newSelectedTask = {...state.selectedTask};
      const payload = (action as ActionWithPayload<any>).payload;
      const attachmentId = payload.attachmentId;
      const type = payload.type;
      if (type == 'task' && !!state.selectedTask?.id) {
        let updatedAttachments = state.selectedTask.attachments.filter(att => att.id != attachmentId);
        newSelectedTask.attachments = updatedAttachments;
      }
      return {
        ...state,
        selectedTask: newSelectedTask,
      };
    case TasksActionTypes.LOAD_SELECT_TASK_SUCCESS:
      return {
        ...state,
        selectedTask: (action as ActionWithPayload<any>).payload
      };
    case TasksActionTypes.LOAD_SELECT_TASK_FAILED:
      let taskId = (action as ActionWithPayload<any>).payload.taskId;
      let foundTasks = [];
      if (!!state.allTasks.tasks) {
        foundTasks = state.allTasks.tasks.filter(task => task.id == taskId);
      }
      if ((!foundTasks || foundTasks.length < 1) && !!state.upcomingTasks.tasks) {
        foundTasks = state.upcomingTasks.tasks.filter(task => task.id == taskId);
      }
      let foundTask: TaskEntity = null;
      if (foundTasks && foundTasks.length > 0) {
        foundTask = foundTasks[0];
      }
      return {
        ...state,
        selectedTask: foundTask
      };
    case TasksActionTypes.LOAD_ALL_TASKS_NEXT_PAGE:
      console.log('task reducer, increasing the currentpage of all tasks');
      let allTasks3 = {...state.allTasks};
      allTasks3.currentPage = +allTasks3.currentPage + 1;
      return {
        ...state,
        allTasks: allTasks3
      };
    case TasksActionTypes.LOAD_ALL_TASKS:
      let allTasks2 = {...state.allTasks};
      allTasks2.currentPage = 1;
      allTasks2.tasks = [];
      allTasks2.hasNextPage = true;
      return {
        ...state,
        allTasks: allTasks2
      };
    case TasksActionTypes.LOAD_ALL_TASKS_SUCCESS:
    case TasksActionTypes.SET_ALL_TASKS:
      let allTasks = {...state.allTasks};
      allTasks.tasks = (action as ActionWithPayload<any>).payload;
      return {
        ...state,
        allTasks: allTasks
      };
    case TasksActionTypes.LOAD_UPCOMING_TASKS:
      let upcomingTasks1 = {...state.upcomingTasks};
      upcomingTasks1.currentPage = 1;
      upcomingTasks1.tasks = [];
      upcomingTasks1.hasNextPage = true;
      return {
        ...state,
        upcomingTasks: upcomingTasks1
      };
    case TasksActionTypes.LOAD_UPCOMING_TASKS_NEXT_PAGE:
      let upcomingTasks3 = {...state.upcomingTasks};
      upcomingTasks3.currentPage = +upcomingTasks3.currentPage + 1;
      return {
        ...state,
        upcomingTasks: upcomingTasks3
      };
    case TasksActionTypes.LOAD_UPCOMING_TASKS_SUCCESS:
      let upcomingTasks2 = {...state.upcomingTasks};
      upcomingTasks2.tasks = (action as ActionWithPayload<any>).payload;
      return {
        ...state,
        upcomingTasks: upcomingTasks2
      };
    case TasksActionTypes.SET_FILTER:
      let at2 = {...state.allTasks};
      at2.currentPage = 1;
      at2.hasNextPage = true;
      at2.tasks = [];
      return {
        ...state,
        allTasks: at2,
        filter: (action as ActionWithPayload<any>).payload
      };
    case TasksActionTypes.SET_AVAILABLE_ASSIGNEES:
      return {
        ...state,
        availableAssignees: (action as ActionWithPayload<any>).payload
      };
    case TasksActionTypes.UPDATE_ALL_TASK_PAGE:
      const at = {...state.allTasks};
      at.hasNextPage = (action as ActionWithPayload<any>).payload;
      return {
        ...state,
        allTasks: at
      };
    case TasksActionTypes.UPDATE_UPCOMING_TASK_PAGE:
      const ut = {...state.upcomingTasks};
      ut.hasNextPage = (action as ActionWithPayload<any>).payload;
      return {
        ...state,
        upcomingTasks: ut
      };
    case TasksActionTypes.RESET_CURRENT_PAGE:
      let at3 = {...state.allTasks};
      at3.currentPage = 1;
      at3.hasNextPage = true;
      return {
        ...state,
        allTasks: at3,
      };
    default:
      return state;
  }
}
