import { ImageActionTypes, ImageActions } from './images.actions';
import * as img from '../../model/Images';
import { ActionWithPayload } from '../types';
import { EquipmentDto } from '../../model';


export interface ImagesState {
  quickLookImages: img.Image[];
  selectedCameras: number[];
  cameraImages: img.Image[] | null;
  userCameras: EquipmentDto[];
  selectedDate: string;
  favoriteImages: img.Image[];
};

function getDateString() {
  const now = new Date();
  let monthNumber = (now.getMonth() + 1) + '';
  if (monthNumber.length < 2) {
    monthNumber = '0' + monthNumber;
  }
  let day = now.getDate() + '';
  if (day.length < 2) {
    day = '0' + day;
  }
  const stringDate = now.getFullYear() + "-" + monthNumber + "-" + day;
  return stringDate;
}

const imagesInitialState: ImagesState = {
  quickLookImages: [],
  cameraImages: null,
  userCameras: null,
  selectedCameras: [],
  selectedDate: getDateString(),
  favoriteImages: []
};


export function reducer(state = imagesInitialState, action: ImageActions & ActionWithPayload<any>): ImagesState {
  switch (action.type) {
    case ImageActionTypes.LOAD_QUICK_LOOK_SUCCESS: {
      if (action.payload && Array.isArray(action.payload)) {
        return {...state, quickLookImages: action.payload};
      }
      break;
    }
    case ImageActionTypes.SET_SELECTED_CAMERAS: {
      let selectedCameras = action.payload;
      if ((!selectedCameras || selectedCameras.length === 0) && state.userCameras) {
        selectedCameras = state.userCameras.map(cam => cam.id);
      }
      return {...state, cameraImages: null, selectedCameras};
    }
    case ImageActionTypes.SET_SELECTED_DATE: {
      return {...state, cameraImages: null, selectedDate: action.payload};
    }
    case ImageActionTypes.LOAD_USER_CAMERAS_SUCCESS: {
      if (action.payload && Array.isArray(action.payload)) {
        return {
          ...state,
          cameraImages: null,
          userCameras: action.payload,
          selectedCameras: action.payload.map(cam => cam.id)
        };
      }
      break;
    }
    case ImageActionTypes.LOAD_CAMERA_IMAGES_SUCCESS: {
      if (action.payload && Array.isArray(action.payload)) {
        let images: img.Image[] = action.payload;
        if (state.cameraImages) {
          images = action.payload.filter(image =>
            !state.cameraImages.find(i => i.imageId === image.imageId));
        }
        return {...state, cameraImages: [...(state.cameraImages || []), ...images]};
      }
      break;
    }
    case ImageActionTypes.LOAD_FAVORITE_IMAGES_SUCCESS: {
      let images: img.Image[] = action.payload;
      return {...state, favoriteImages: images};
      break;
    }
    case ImageActionTypes.ADD_FAVORITE_IMAGES_SUCCESS: {
      const newFavoriteImageId = action.payload;
      let updatedFavorites = [];
      let updatedImages = [];
      let updatedQLImages = [];
      let selectedImage = null;
      if (state.cameraImages) {
        updatedImages = state.cameraImages.map(image => {
          if (image.imageId == newFavoriteImageId) {
            image.favoriteImage = true;
            selectedImage = image;
          }
          return image;
        });
      }
      if (state.quickLookImages) {
        updatedQLImages = state.quickLookImages.map(image => {
          if (image.imageId == newFavoriteImageId) {
            image.favoriteImage = true;
            if (!selectedImage) {
              selectedImage = image;
            }
          }
          return image;
        });
      }
      if (!!selectedImage) {
        if (!state.favoriteImages) {
          updatedFavorites = [];
        }
        updatedFavorites.push(...state.favoriteImages);
        updatedFavorites.push(selectedImage)
      }
      return {
        ...state,
        favoriteImages: updatedFavorites,
        cameraImages: updatedImages,
        quickLookImages: updatedQLImages,
      };
    }
    case ImageActionTypes.REMOVE_FAVORITE_IMAGES_SUCCESS: {
      const favoriteImageId = action.payload;
      let updatedFavorites = [];
      let updatedImages = [];
      let updatedQLImages = [];
      if (state.favoriteImages) {
        updatedFavorites = state.favoriteImages.filter(image => {
          return favoriteImageId != image.imageId;
        })
      }
      if (state.cameraImages) {
        updatedImages = state.cameraImages.map(image => {
          if (image.imageId == favoriteImageId) {
            image.favoriteImage = false;
          }
          return image;
        });
      }
      if (state.quickLookImages) {
        updatedQLImages = state.quickLookImages.map(image => {
          if (image.imageId == favoriteImageId) {
            image.favoriteImage = false;
          }
          return image;
        });
      }
      return {
        ...state,
        favoriteImages: updatedFavorites,
        cameraImages: updatedImages,
        quickLookImages: updatedQLImages,
      };
    }
    case ImageActionTypes.LOAD_MULTIPLE_CAMERA_IMAGES_SUCCESS: {
      if (action.payload && Array.isArray(action.payload)) {
        let payloadImages: img.Image[] = action.payload.reduce((prev, curr) => prev.concat(curr), []);
        if (state.cameraImages) {
          payloadImages = payloadImages.filter(image =>
            !state.cameraImages.find(i => i.imageId === image.imageId));
        }
        const updatedImages = [...(state.cameraImages || []), ...payloadImages];
        return {...state, cameraImages: updatedImages};
      }
      return state;
    }
    default: {
      return state;
    }
  }
}
