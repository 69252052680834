import { Action } from '@ngrx/store';
import { EntityWithImage } from "../../model";
import { LivestockActionTypes } from '../livestock/livestock.actions';
import { TasksActionTypes } from '../tasks/tasks.actions';
import { ActionWithPayload } from "../types";
import { AttachmentActionTypes } from "./attachments.actions";

export interface AttachmentsState {
  attachments: EntityWithImage[]
  selectedAttachment: EntityWithImage,
  type: 'livestock/profitloss/expense' | 'livestock/animal' | 'task',
  id: number
}

export const ranchesInitialState: AttachmentsState = {
  attachments: null,
  selectedAttachment: null,
  type: null,
  id: null
};


export function attachmentsReducer(state = ranchesInitialState, action: Action): AttachmentsState {
  let payload = (action as ActionWithPayload<any>).payload;
  switch (action.type) {
    case AttachmentActionTypes.RESET_ATTACHMENTS:
      return ranchesInitialState;
    case AttachmentActionTypes.SET_ATTACHMENTS:
      return {
        ...state,
        attachments: payload.attachments,
        id: payload.id,
        type: payload.type
      };
    case AttachmentActionTypes.UPDATE_ATTACHMENTS:
      return {
        ...state,
        attachments: payload,
      };
    case AttachmentActionTypes.SET_SELECTED_ATTACHMENT:
      let attachment = (action as ActionWithPayload<any>).payload;
      return {
        ...state,
        selectedAttachment: attachment,
      }
    case TasksActionTypes.LOAD_TASK_DETAIL_SUCCESS:
      const taskDetail = (action as ActionWithPayload<any>).payload;
      if (state.type == 'task' && state.id == taskDetail.id) {
        return {
          ...state,
          attachments: taskDetail.attachments,
        }
      }
    case AttachmentActionTypes.UPLOAD_ATTACHMENT_SUCCESS:
      const att = (action as ActionWithPayload<any>).payload;
      return {
        ...state,
        attachments: !!state.attachments ? state.attachments.concat([att]) : [att],
      }
    case LivestockActionTypes.UPDATE_ANIMAL_SUCCESS:
      const animalDetail = (action as ActionWithPayload<any>).payload.animal;
      if (state.type == 'livestock/animal' && state.id == animalDetail.id) {
        return {
          ...state,
          attachments: animalDetail.attachments,
        }
      }
    case AttachmentActionTypes.UPDATE_ATTACHMENT:
      const updatedAttachment = (action as ActionWithPayload<any>).payload;
      if (!state.attachments || state.attachments.length < 1 || !updatedAttachment) {
        return state;
      }
      let updatedAttachments = state.attachments.map(att => {
        if (att.id == updatedAttachment.id) {
          return updatedAttachment;
        }
        return att;
      })
      return {
        ...state,
        attachments: updatedAttachments,
      }
    default:
      return state;
  }
}

